import React, {Component} from 'react';

class NotFound extends Component{
    render(){
        return(
            <div className="not-found">
                <div className="center-block">
                    <h1>404</h1>
                    <h2>Not Found</h2>
                </div>
            </div>
        )
    }
}

export default NotFound;