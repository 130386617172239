import React, { Component } from 'react';
import '../Styles/main.scss';
import i18n from '../i18n';
import LanguagePicker from '../Components/LanguagePicker';
import { authenticate } from '../Actions/Auth';
import { connect } from 'react-redux';
import { Redirect } from 'react-router';
import Alert from 'react-bootstrap/Alert';
import { APP_VERSION } from '../Utils/constants';


class Login extends Component {
	constructor(props) {
		super(props);

		this.state = {
			formValues: {
				email: '',
				password: '',
			},
			formErrors: {
				email: '',
				password: '',
			},
			formValidity: {
				email: false,
				password: false,
			},
			login: props.login,
			isSubmitting: false,
			message: null,
			isError: true,
			isForgetPwd: false,
		};
		this.handleSubmit = this.handleSubmit.bind(this);
		this.handleChange = this.handleChange.bind(this);
		this.showForgetPwd = this.showForgetPwd.bind(this);
	}

	handleChange = ({ target }) => {
		const { formValues } = this.state;
		formValues[target.name] = target.value;
		this.setState({ formValues });
		this.handleValidation(target);
	};

	handleValidation = (target) => {
		const { name, value } = target;
		const fieldValidationErrors = this.state.formErrors;
		const validity = this.state.formValidity;
		const isEmail = name === 'email';
		const isPassword = name === 'password';
		const emailTest = /^[^\s@]+@[^\s@]+\.[^\s@]{2,}$/i;
		validity[name] = value.length > 0;

		switch (name) {
			case 'password':
				fieldValidationErrors[name] = validity[name] ? '' : i18n.t('password_is_required');
				break;
			case 'email':
				fieldValidationErrors[name] = validity[name] ? '' : i18n.t('user_name_is_required');
				break;
			default:
				validity[name] = true;
				break;
		}
		if (validity[name]) {
			if (isEmail) {
				validity[name] = emailTest.test(value);
				fieldValidationErrors[name] = validity[name]
					? ''
					: i18n.t('username_should_be_a_valid_email_address');
			}
			if (isPassword) {
				validity[name] = value.length >= 4;
				fieldValidationErrors[name] = validity[name]
					? ''
					: i18n.t('minimum_password_characters');
			}
		}

		this.setState({
			formErrors: fieldValidationErrors,
			formValidity: validity,
		});
	};

	static getDerivedStateFromProps(props, state) {
		if (props.login && props.login !== state.login) {
			if (props.login.status && props.login.status === 401) {
				return {
					msgContent: props.login.message,
					msgType: 'alert error',
					login: props.login,
					loading: false,
				};
			} else {
				if (props.login.token !== null && props.login.token !== undefined) {
					props.history.replace('/pt/customers');
				} else {
					return {
						msgContent: 'props.login.message',
						msgType: 'alert error',
						login: props.login,
						loading: false,
						message: '',
					};
				}
			}
		} else {
			return {
				msgContent: '',
				msgType: 'alert error',
				login: props.login,
				loading: false,
				message:
					props.error && props.error.indexOf('Auth error:') > -1
						? 'Invalid credentials. Please try again'
						: props.error,
			};
		}
		return null;
	}

	handleSubmit(event) {
		event.preventDefault();
		this.setState({ isSubmitting: true, isError: true });
		const { formValues, formValidity } = this.state;
		if (Object.values(formValidity).every(Boolean)) {
			if (formValues.email !== 0) {
				this.props.doLogin(formValues.email, formValues.password);
			} else {
				this.setState({ msgContent: 'Please provide user name', msgType: 'alert error' });
			}
		} else {
			for (let key in formValues) {
				let target = {
					name: key,
					value: formValues[key],
				};
				if (Object.keys(formValidity).includes(key)) {
					this.handleValidation(target);
				}
			}
			this.setState({ isSubmitting: false });
		}
	}

	showForgetPwd() {
		this.setState({ isForgetPwd: true });
	}

	changeLanguage = (lng) => {
		i18n.changeLanguage(lng);
	};

	render() {
		const { t, isLoading, token, error } = this.props;
		const { formValues, formErrors, isError, message, isForgetPwd } = this.state;
		return token ? (
			<Redirect to="/pt/customers" />
		) : (
			<div className="signin-body-dark">
				<div className="container">
					<div className="wr-log" style={{ marginBottom: '50px' }}>
						<div className="form-signin effect form-group">
							<div className="row">
								<div className="col-lg-12 text-center">
									<LanguagePicker></LanguagePicker>
								</div>
							</div>
							<div className="row">
								<div className="col-lg-12 text-center">
									<h3 className="mt-4">{t('login')}</h3>
								</div>
							</div>
							<div className="row">
								<div className="col-lg-12">
									<form onSubmit={this.handleSubmit} noValidate>
										<div className="form-group">
											<label>{t('username')}</label>
											<input
												type="email"
												name="email"
												className={`form-control form-control-lg ${
													formErrors.email ? 'is-invalid' : ''
												}`}
												onChange={this.handleChange}
												value={formValues.email}
											/>
											<div className="invalid-feedback">
												{formErrors.email}
											</div>
										</div>
										<div className="form-group">
											<label>{t('password')}</label>
											<input
												type="password"
												name="password"
												className={`form-control form-control-lg ${
													formErrors.password ? 'is-invalid' : ''
												}`}
												onChange={this.handleChange}
												value={formValues.password}
											/>
											<div className="invalid-feedback">
												{formErrors.password}
											</div>
										</div>
										{isForgetPwd ? (
											<button
												type="button"
												className="btn btn-primary btn-block"
												onClick={this.showForgetPwd}
											>
												Forget password ?
											</button>
										) : (
											<button
												type="submit"
												className="btn btn-primary btn-block"
												disabled={isLoading}
											>
												{isLoading ? 'Please wait...' : t('submit')}
											</button>
										)}
									</form>
									<div className="invalid-feedback">{error}</div>
								</div>
							</div>
							<br></br>
							<div className="row">
								<div className="col-lg-12 text-center">
									{error && isError && (
										<Alert
											variant={error ? 'danger' : 'success'}
											dismissible
											onClose={() =>
												this.setState({ isError: false, msgContent: '' })
											}
										>
											{message}
										</Alert>
									)}
								</div>
							</div>

							<div className="row">
								<div className="col-lg-12 text-center"></div>
							</div>
						</div>
					</div>
				</div>
				<div>
					<div id="versionBar">
						<div className="copyright">
							{APP_VERSION}
						</div>
					</div>
				</div>
			</div>
		);
	}
}
function mapStateToProps(globalState) {
	return {
		error: globalState.auth.error,
		token: globalState.auth.token,
		isLoading: globalState.auth.loading,
		user: globalState.auth.user,
	};
}

function mapDispatchToProps(dispatch) {
	return {
		doLogin: (username, password) => dispatch(authenticate({ username, password })),
	};
}

export default connect(mapStateToProps, mapDispatchToProps)(Login);
