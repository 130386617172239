import { put, call, takeLatest } from 'redux-saga/effects';
import { CUSTOMER } from '../Utils/constants';
import api from '../Api/customer.api';
import {
	createOrUpdateFailed,
	createOrUpdateSuccess,
	getCustomersFailed,
	getCustomersSuccess,
	getCustomerSuccess,
	getCustomerFailed,
	getOutstandingTimesheetsExpensesSummarySuccess,
	getOutstandingTimesheetsExpensesSummaryFailed

} from '../Actions/Customer';
import i18n from '../i18n';

export function* createCustomer({ data }) {
	const resp = yield call(api.createCustomer, data);
	if (resp instanceof Object) {
		yield put(createOrUpdateSuccess(i18n.t('successfuly_created')));
	} else {
		yield put(createOrUpdateFailed(resp));
	}
}

export function* updateCustomer({ data: obj }) {
	const data = yield call(api.updateCustomer, obj);
	if (data instanceof Object) {
		yield put(createOrUpdateSuccess(i18n.t('successfuly_updated')));
	} else {
		yield put(createOrUpdateFailed(data));
	}
}

export function* deleteCustomer({ data: { id } }) {
	const data = yield call(api.deleteCustomer, id);
	if (data instanceof Object) {
		yield put(createOrUpdateSuccess(i18n.t('successfuly_deleted')));
	} else {
		yield put(createOrUpdateFailed(data));
	}
}

export function* getCustomers() {
	const data = yield call(api.getCustomers);
	if (data instanceof Object) {
		yield put(getCustomersSuccess(data));
	} else {
		yield put(getCustomersFailed(data));
	}
}

export function* getCustomer(d) {
	const data = yield call(api.getCustomer, d.id);
	if (data instanceof Object) {
		yield put(getCustomerSuccess(data));
	} else {
		yield put(getCustomerFailed(data));
	}
}

export function* getTimesheetsExpensesOutstanding(d) {
	const data = yield call(api.getTimesheetAndExpenseOutstanding, d.data);
	if (data instanceof Object) {
		yield put(getOutstandingTimesheetsExpensesSummarySuccess(data));
	} else {
		yield put(getOutstandingTimesheetsExpensesSummaryFailed(data));
	}
}

export default function* CustomerSagaWatcher() {
	yield takeLatest(CUSTOMER.CREATE_CUSTOMER, createCustomer);
	yield takeLatest(CUSTOMER.UPDATE_CUSTOMER, updateCustomer);
	yield takeLatest(CUSTOMER.GET_CUSTOMERS, getCustomers);
	yield takeLatest(CUSTOMER.GET_CUSTOMER, getCustomer);
	yield takeLatest(CUSTOMER.DELETE_CUSTOMER, deleteCustomer);
	yield takeLatest(CUSTOMER.GET_OUTSTANDING_TIMESHEETS_EXPENSES_SUMMERY, getTimesheetsExpensesOutstanding);

}
