import React, { Component, Suspense } from 'react';
import { BrowserRouter as Router, Route } from 'react-router-dom';
import { Provider } from 'react-redux';
// Pages
import Home from './Pages/Home';
import Login from './Pages/Login';
import SetPassword from './Pages/SetPassword';
import { withTranslation } from 'react-i18next';
import store from './store';
import LoggedInRedirectRoute from './Utils/loggedInRedirectRoute';
import AuthRoute from './Utils/authRoute';

class App extends Component {
	render() {
		return (
			<Provider store={store}>
				<Router>
					<Suspense
						fallback={
							<div className="App">
								<div>loading...</div>
							</div>
						}
					>
						<div>
							<Route exact sensitive path="/" component={withTranslation()(Login)} />
							<AuthRoute
								parent
								sensitive
								path="/pt"
								component={withTranslation()(Home)}
							/>
							<Route
								exact
								sensitive
								path="/setpassword/:token"
								component={withTranslation()(SetPassword)}
							/>
						</div>
					</Suspense>
				</Router>
			</Provider>
		);
	}
}

export default App;
// export default connect(mapStateToProps)(App);
