import React, { Component } from 'react';
import { Navbar, NavDropdown } from 'react-bootstrap';
import i18n from '../i18n';
import LanguagePicker from './LanguagePicker';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { logout } from '../Actions/Auth';

class AppHeader extends Component {

	changeLanguage = (lng) => {
		i18n.changeLanguage(lng);
	};

	render() {
		const { t, doLogout, user } = this.props;
		return (
			<header style={{ position: 'inherit', padding: 0, zIndex: 10 }}>
				<Navbar bg="dark" variant="dark" className={'h-100'}>
					<Navbar.Collapse className="justify-content-end">
						<LanguagePicker></LanguagePicker>
						<Navbar.Text className="mlft">{t('signed_as')}</Navbar.Text>
					</Navbar.Collapse>
					<NavDropdown
						className="mlft"
						title={user.name}
						id="basic-nav-dropdown"
						alignRight
					>
						<NavDropdown.Item onClick={doLogout}>
							{t('log_out')}
						</NavDropdown.Item>
					</NavDropdown>
				</Navbar>
			</header>
		);
	}
}

function mapStateToProps({ auth }) {
	return {
		user: auth.user,
	};
}

function mapDispatchToProps(dispatch) {
	return {
		doLogout: () => dispatch(logout(null)),
	};
}
export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(AppHeader));
