import Alert from 'react-bootstrap/Alert';
import React, { Component, Fragment } from 'react';
import '../Styles/main.scss';
import i18n from '../i18n';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';
import { getServices, createService, deleteService, updateService } from '../Actions/Service';
import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory from 'react-bootstrap-table2-paginator';
import Moment from 'moment';
import { DATE_FORMAT } from '../Utils/constants';

class Serivces extends Component {
	constructor(props) {
		super(props);
		this.state = {
			message: null,
			isError: false,
			formValues: {
				name: '',
				serviceID: 0,
			},
			formErrors: {
				name: '',
			},
			formValidity: {
				name: false,
			},
			isSubmitting: false,
			services: props.services,
			loading: false,
			msgContent: null,
			msgType: null,
		};
		this.resetForm = this.resetForm.bind(this);
		this.editService = this.editService.bind(this);
		this.handleDelete = this.handleDelete.bind(this);
		this.hideAlert = this.hideAlert.bind(this);

		this.columns = [
			{
				dataField: 'serviceID',
				text: i18n.t('id'),
				sort: true,
				hidden: true
			},
			{
				dataField: 'name',
				text: i18n.t('service'),
				sort: true,
			},
			{
				dataField: 'created_timestamp',
				text: i18n.t('create_at'),
				formatter: (cell) => { return Moment(cell).format(DATE_FORMAT) },
				sort: true,
			},
			{
				dataField: 'actions',
				text: i18n.t('actions'),
				isDummyField: true,
				csvExport: false,
				sort: false,
				formatter: (cell, row) => {
					return (
						<Fragment>
							<button
								type="button"
								className="btn btn-primary"
								onClick={() => this.editService(row)}
							>
								<i className="fa fa-edit"></i>
							</button>
							<button
								className="btn btn-danger"
								onClick={() => {
									if (window.confirm('Are you sure to delete this service ?'))
										this.handleDelete(row.serviceID);
								}}
							>
								<i className="fa fa-trash"></i>
							</button>
						</Fragment>
					);
				},
			},
		];
	}

	resetForm() {
		this.setState({
			formValues: {
				name: '',
				serviceID: 0,
			},
			formErrors: {
				name: '',
			},
			formValidity: {
				name: false,
			},
		});
	}

	componentDidUpdate(props) {
		if (this.props.message || this.props.error) {
			this.props.getServices();
			this.setState({
				isError: !!this.props.error,
				message: this.props.message || this.props.error,
			});
			this.resetForm();
			var that = this;
			setTimeout(function () {
				that.setState({ isError: false, message: null });
			}, 3000);
		}
	}

	hideAlert() {
		debugger
		console.log("");

	}

	handleChange = ({ target }) => {
		const { formValues } = this.state;
		formValues[target.name] = target.value;
		this.setState({ formValues });
		this.handleValidation(target);
	};

	handleValidation = (target) => {
		const { name, value } = target;
		const fieldValidationErrors = this.state.formErrors;
		const validity = this.state.formValidity;

		validity[name] = value.length > 0;
		fieldValidationErrors[name] = validity[name] ? '' : i18n.t('service_is_required');

		this.setState({
			formErrors: fieldValidationErrors,
			formValidity: validity,
		});
	};

	handleSubmit = (event) => {
		event.preventDefault();
		const { formValues, formValidity } = this.state;
		if (Object.values(formValidity).every(Boolean)) {
			if (formValues.serviceID !== 0) {
				this.props.updateService(formValues.name, formValues.serviceID);
			} else {
				this.props.createService(formValues.name);
			}
		} else {
			for (let key in formValues) {
				let target = {
					name: key,
					value: formValues[key],
				};
				this.handleValidation(target);
			}
			this.setState({ isSubmitting: false });
		}
	};

	componentDidMount() {
		this.props.getServices();
	}

	editService(service) {
		const newValues = {};
		newValues.name = service.name;
		newValues.serviceID = service.serviceID;
		this.setState({ formValues: newValues });
		window.scrollTo(0, 0);
	}

	handleDelete(serviceID) {
		this.props.deleteService(serviceID);
	}

	render() {
		const { t } = this.props;
		const { formValues, formErrors, message, isError } = this.state;
		return (
			<div>
				<h1>{t('services')}</h1>
				{message && (
					<Alert
						variant={isError ? 'danger' : 'success'}
						dismissible
						onClose={() => this.setState({ isError: false, message: null })}
					>
						{message}
					</Alert>
				)}
				<div className="">
					<div className="card">
						<form className="form-horizontal" onSubmit={this.handleSubmit}>
							<div className="card-header">
								<i className="fa fa-edit"></i>
								{t('create_service')}
							</div>
							<div className="card-body">
								<div className="row">
									<div className="col-lg-8">
										<div className="form-group">
											<label>{t('service')} </label>{' '}
											<span className="required-star">*</span>
											<input
												type="text"
												name="name"
												className={`form-control form-control-lg ${
													formErrors.name ? 'is-invalid' : ''
													}`}
												onChange={this.handleChange}
												value={formValues.name}
											/>
											<div className="invalid-feedback">
												{formErrors.name}
											</div>
										</div>
									</div>
								</div>
							</div>
							<div className="card-footer">
								{this.props.loading ? (
									<button
										type="button"
										className="btn btn-success loading"
										disabled
									>
										<i className="fas fa-sync-alt fa-spin"></i>
									</button>
								) : (
										<span>
											<button
												type="submit"
												className="btn btn-success"
												disabled={this.props.loading}
											>
												<i className="far fa-dot-circle"></i>{' '}
												{this.props.loading ? t('please_wait') : t('save')}
											</button>
											<button
												type="button"
												onClick={this.resetForm}
												className="btn btn-info"
											>
												<i className="fa fa-times"></i> {t('clear')}
											</button>
										</span>
									)}
							</div>
						</form>
					</div>
				</div>
				<div>
					<br></br>
					<BootstrapTable
						keyField="serviceID"
						data={this.props.services ? this.props.services.result : []}
						columns={this.columns}
						pagination={paginationFactory()}
						striped
						hover
						condensed
					/>
				</div>
				{/* </div> */}
			</div>
		);
	}
}
function mapStateToProps(globalState) {
	return {
		services: globalState.services.services,
		error: globalState.services.error,
		loading: globalState.services.loading,
		message: globalState.services.message
	};
}

function mapDispatchToProps(dispatch) {
	return {
		createService: (serviceName) => dispatch(createService({ serviceName })),
		updateService: (serviceName, id) => dispatch(updateService({ serviceName, id })),
		deleteService: (id) => dispatch(deleteService({ id })),
		getServices: () => dispatch(getServices()),
	};
}

export default withTranslation('translation', { withRef: true })(
	connect(mapStateToProps, mapDispatchToProps)(Serivces)
);
