import { EXPENSE } from '../Utils/constants';

const createExpense = (data) => ({
	type: EXPENSE.CREATE_EXPENSE,
	data,
});

const updateExpense = (data) => ({
	type: EXPENSE.UPDATE_EXPENSE,
	data,
});

const deleteExpense = (id) => ({
	type: EXPENSE.DELETE_EXPENSE,
	id,
});

const createOrUpdateFailed = (error) => ({
	type: EXPENSE.UPDATE_EXPENSE_FAILED,
	error,
});

const createOrUpdateSuccess = (message, data) => ({
	type: EXPENSE.UPDATE_EXPENSE_SUCCESS,
	message,
	data
});

const getExpenses = (data) => ({
	type: EXPENSE.GET_EXPENSES,
	data 
});

const getExpensesFailed = (error) => ({
	type: EXPENSE.GET_EXPENSES_FAILED,
	error,
});

const getExpensesSuccess = (expenses) => ({
	type: EXPENSE.GET_EXPENSES_SUCCESS,
	expenses,
});

const getSummerizeExpenses = (data) => ({
	type: EXPENSE.GET_SUMMERIZE_EXPENSES,
	data 
});

const getSummerizeExpensesFailed = (error) => ({
	type: EXPENSE.GET_SUMMERIZE_EXPENSES_FAILED,
	error,
});

const getSummerizeExpensesSuccess = (summerizeExpenses) => ({
	type: EXPENSE.GET_SUMMERIZE_EXPENSES_SUCCESS,
	summerizeExpenses,
});

export {
	createExpense,
	updateExpense,
	deleteExpense,
	createOrUpdateFailed,
	createOrUpdateSuccess,
	getExpenses,
	getExpensesFailed,
	getExpensesSuccess,
	getSummerizeExpenses,
	getSummerizeExpensesFailed,
	getSummerizeExpensesSuccess
};
