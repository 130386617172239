import React, { Component } from 'react';
import Tabs from 'react-bootstrap/Tabs';
import Tab from 'react-bootstrap/Tab';
import TimeReportingModel from '../Components/TimeReportingModel';
import AssignServicesModal from '../Components/AssignServicesModal';
import ExpencesModal from '../Components/ExpencesModal';
import { connect } from "react-redux";
import Alert from 'react-bootstrap/Alert';
import { getCustomer } from '../Actions/Customer';

class CustomerDetails extends Component {
    constructor(props) {
        super(props);
        this.state = {
            customerId: "",
            customerName: "",
            customer: props.customer,
            message: null,
            isError: false
        };
        this.refreshTables = this.refreshTables.bind(this);
        this.fetchCustomerById = this.fetchCustomerById.bind(this);
    }

    // Life cycle method
    componentDidMount() {
        this.fetchCustomerById();
        this.refreshTables();
    }

    fetchCustomerById() {
        this.props.getCustomer(this.props.match.params.customerid);
    }

    refreshTables() {
        if (this.props.match.params) {
            // set customer id to state
            this.setState({ customerId: this.props.match.params.customerid });

            // Refresh time logs table in TimeReportingModel component
            // this.refs.child.fetchReportedTime();
            // this.refs.childExpence.fetchReportedExpences();
            // this.refs.assignServicesChild.fetchAssignedServicesList();
        }
        else {
            // return to customers list page by showing error msg
        }
    }

    renderName() {
        if (this.props.customer) {
            return this.props.customer.name
        }
        return "";
    }

    renderTimeReportingModal() {
        const customerId = this.props.match.params.customerid;
        return (
            <>
            <TimeReportingModel customerId={customerId} ></TimeReportingModel>
            </>
        )
    }

    renderExpencesModal() {
        const customerId = this.props.match.params.customerid;
        return (
            <>
            <ExpencesModal customerId={customerId}></ExpencesModal>
            </>
        )
    }

    renderAssignServices() {
        const customerId = this.props.match.params.customerid;
        return (
            <AssignServicesModal customerId={customerId} ref="assignServicesChild"></AssignServicesModal>
        )
    }

    render() {
        const { t } = this.props;
        const { message, isError } = this.state;

        return (
            <div>
                <h1>{this.renderName()}</h1>
                {message && (
					<Alert
						variant={isError ? 'danger' : 'success'}
						dismissible
						onClose={() => this.setState({ isError: false, message: null })}
					>
						{message}
					</Alert>
				)}
                <Tabs defaultActiveKey="timeReporting" id="uncontrolled-tab-example">
                    <Tab eventKey="timeReporting" title={t('time_reporting')}>
                        <br></br>
                        {this.renderTimeReportingModal()}
                    </Tab>
                    <Tab eventKey="expences" title={t('expences')}>
                        <br></br>
                        {this.renderExpencesModal()}
                    </Tab>
                    <Tab eventKey="asiignServices" title={t('assign_services')}>
                        <br></br>
                        {this.renderAssignServices()}
                    </Tab>

                </Tabs>
            </div>
        );
    }
}


function mapStateToProps(globalState) {
    return {
        customer : globalState.customers.customer,
        error: globalState.customers.error,
		loading: globalState.customers.loading,
		message: globalState.customers.message,
    };
}
function mapDispatchToProps(dispatch) {
    return {
        getCustomer: (id) => dispatch(getCustomer(id)),
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(CustomerDetails);

