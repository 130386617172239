import axios from './clientInstance';
import { BASE_URL } from '../Utils/constants';

const createConsultant = async (data) => {
	try {
		const response = await axios({
			method: 'post',
			url: `${BASE_URL}/users`,
			data,
		});

		if (response.status >= 400) {
			throw new Error(response.statusText);
		}
		return response.data;
	} catch (error) {
		return error.message;
	}
};

const updateConsultant = async (data) => {
	try {
		const response = await axios({
			method: 'put',
			url: `${BASE_URL}/users/${data.data.userID}`,
			data: data.data,
		});

		if (response.status >= 400) {
			throw new Error(response.statusText);

		}

		return response.data;
	} catch (error) {
		return error.message;
	}
};

const deleteConsultant = async (id) => {
	try {
		const response = await axios({
			method: 'delete',
			url: `${BASE_URL}/users/${id}`,
		});

		if (response.status >= 400) {
			throw new Error(response.statusText);
		}
		return response.data;
	} catch (error) {
		return error.message;
	}
};

const getConsultants = async () => {
	try {
		const response = await axios({
			method: 'get',
			url: `${BASE_URL}/users`,
		});

		if (response.status >= 400) {
			throw new Error(response.statusText);
		}
		return response.data;
	} catch (error) {
		return error.message;
	}
};

export default {
	createConsultant,
	updateConsultant,
	deleteConsultant,
	getConsultants,
};
