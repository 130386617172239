import { CONSULTANT } from '../Utils/constants';

const createConsultant = (data) => ({
	type: CONSULTANT.CREATE_CONSULTANT,
	data,
});

const updateConsultant = (data) => ({
	type: CONSULTANT.UPDATE_CONSULTANT,
	data,
});

const deleteConsultant = (data) => ({
	type: CONSULTANT.DELETE_CONSULTANT,
	data,
});

const createOrUpdateFailed = (error) =>
({
	type: CONSULTANT.UPDATE_CONSULTANT_FAILED,
	error,
});

const createOrUpdateSuccess = (message) => ({
	type: CONSULTANT.UPDATE_CONSULTANT_SUCCESS,
	message,
});

const getConsultants = () => ({
	type: CONSULTANT.GET_CONSULTANTS,
});

const getConsultantsFailed = (error) => ({
	type: CONSULTANT.GET_CONSULTANTS_FAILED,
	error,
});

const getConsultantsSuccess = (consultants) => (
	{
	type: CONSULTANT.GET_CONSULTANTS_SUCCESS,
	consultants,
});

export {
	createConsultant,
	updateConsultant,
	deleteConsultant,
	createOrUpdateFailed,
	createOrUpdateSuccess,
	getConsultants,
	getConsultantsFailed,
	getConsultantsSuccess,
};
