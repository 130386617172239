import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import SubMenu from "antd/es/menu/SubMenu";
import { Icon, Layout, Menu } from 'antd';
import Logo from '../images/crowderia-logo.png';
import { withRouter } from "react-router";
import { withTranslation } from 'react-i18next';

const { Sider } = Layout;

class Left extends Component {
    constructor(props) {
        super(props);
        this.state = {
            collapsed: false,
        }
    }
    onCollapse = () => {
        this.setState({
            collapsed: !this.state.collapsed,
        });
    };

    render() {
        const { t } = this.props;

        return (
            <Sider collapsible collapsed={this.state.collapsed} onCollapse={this.onCollapse}>
                <div className="logo">
                    <img className="brand" alt="logotype" src={process.env.PUBLIC_URL + Logo} />
                </div>
                <Menu theme="dark" activeKey={this.props.location.pathname} selectedKeys={this.props.location.pathname} mode="inline" >
                    <Menu.Item key="customers">
                        <Link to="/pt/customers">
                            <Icon type="user" />
                            <span>{t('customers')}</span>
                        </Link>
                    </Menu.Item>
                    <Menu.Item key="invoices">
                        <Link to="/pt/invoices">
                            <Icon type="wallet" />
                            <span>{t('invoices')}</span>
                        </Link>
                    </Menu.Item>
                    <SubMenu key="settings"
                        title={
                            <span>
                                <Icon type="setting" />
                                <span>{t('settings')}</span>
                            </span>
                        }>
                        <Menu.Item key="services">
                            <Link to="/pt/services">
                                <Icon type="menu-unfold" />
                                <span>{t('services')}</span>
                            </Link>
                        </Menu.Item>
                        <Menu.Item key="consultants">
                            <Link to="/pt/consultants">
                                <Icon type="menu-unfold" />
                                <span>{t('consultants')}</span>
                            </Link>
                        </Menu.Item>
                        <Menu.Item key="resetpassword">
                            <Link to="/pt/resetpassword">
                                <Icon type="key" />
                                <span>{t('reset_password')}</span>
                            </Link>
                        </Menu.Item>
                    </SubMenu>
                </Menu>
            </Sider>
        )
    }
}
export default withRouter(withTranslation()(Left));
