import { INVOICE } from '../Utils/constants';

const createInvoice = (data) => ({
	type: INVOICE.CREATE_INVOICE,
	data,
});

const updateInvoice = (data) => ({
	type: INVOICE.UPDATE_INVOICE,
	data,
});

const deleteInvoice = (data) => ({
	type: INVOICE.DELETE_INVOICE,
	data,
});

const createOrUpdateFailed = (error) => ({
	type: INVOICE.UPDATE_INVOICE_FAILED,
	error,
});

const createOrUpdateSuccess = (message, data) => ({
	type: INVOICE.UPDATE_INVOICE_SUCCESS,
	message,
	data
});

const getInvoices = (data) => ({
	type: INVOICE.GET_INVOICES,
	data
});

const getInvoicesFailed = (error) => ({
	type: INVOICE.GET_INVOICES_FAILED,
	error,
});

const getInvoicesSuccess = (invoices) => ({
	type: INVOICE.GET_INVOICES_SUCCESS,
	invoices,
});

const getInvoice = (data) => ({
	type: INVOICE.GET_INVOICES,
	data
});

const getInvoiceFailed = (error) => ({
	type: INVOICE.GET_INVOICES_FAILED,
	error,
});

const getInvoiceSuccess = (invoices) => ({
	type: INVOICE.GET_INVOICES_SUCCESS,
	invoices,
});

const sendInvoice = (data) => ({
	type: INVOICE.SEND_INVOICE,
	data
});

const sendInvoiceFailed = (error) => ({
	type: INVOICE.SEND_INVOICE_FAILED,
	error,
});

const sendInvoiceSuccess = (data) => ({
	type: INVOICE.SEND_INVOICE_SUCCESS,
	data,
});

const getOutstandingSummary = (data) => ({
	type: INVOICE.GET_OUTSTANDING_SUMMERY,
	data
});

const getOutstandingSummaryFailed = (error) => ({
	type: INVOICE.GET_OUTSTANDING_SUMMERY_FAILED,
	error,
});

const getOutstandingSummarySuccess = (data) => ({
	type: INVOICE.GET_OUTSTANDING_SUMMERY_SUCCESS,
	data,
});

export {
	createInvoice,
	updateInvoice,
	deleteInvoice,
	createOrUpdateFailed,
	createOrUpdateSuccess,
	getInvoices,
	getInvoicesFailed,
	getInvoicesSuccess,
	getInvoice,
	getInvoiceFailed,
	getInvoiceSuccess,
	sendInvoice,
	sendInvoiceFailed,
	sendInvoiceSuccess,
	getOutstandingSummary,
	getOutstandingSummaryFailed,
	getOutstandingSummarySuccess
};
