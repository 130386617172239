import { put, call, takeLatest } from 'redux-saga/effects';
import { INVOICE } from '../Utils/constants';
import api from '../Api/invoice.api';
import {
	createOrUpdateFailed,
	createOrUpdateSuccess,
	getInvoicesFailed,
	getInvoicesSuccess,
	sendInvoiceFailed,
	sendInvoiceSuccess,
	getOutstandingSummarySuccess,
	getOutstandingSummaryFailed

} from '../Actions/Invoice';
import i18n from '../i18n';

export function* createInvoice(data) {
	const resp = yield call(api.createInvoice, data);
	if (resp instanceof Object) {
		yield put(createOrUpdateSuccess(i18n.t('successfuly_created'), resp.data));
	} else {
		yield put(createOrUpdateFailed(resp));
	}
}

export function* updateInvoice(d, id) {
	const data = yield call(api.updateInvoice, d, id);
	if (data instanceof Object) {
		if(data.record_status === 0){
			yield put(createOrUpdateSuccess('Successfully canceled. Time logs and expences reversed.', data));
		}
		else{
			yield put(createOrUpdateSuccess(i18n.t('sucessfully_confirmed'), data));
		}
	} else {
		yield put(createOrUpdateFailed(data));
	}
}

export function* deleteInvoice(data) {
	const resp = yield call(api.deleteInvoice, data.data);
	if (resp instanceof Object) {
		yield put(createOrUpdateSuccess(i18n.t('successfuly_deleted')));
	} else {
		yield put(createOrUpdateFailed(resp));
	}
}

export function* getInvoices(d) {
	const data = yield call(api.getInvoices, d.data);
	if (data instanceof Object) {
		yield put(getInvoicesSuccess(data));
	} else {
		yield put(getInvoicesFailed(data));
	}
}

export function* getInvoice(d) {
	const data = yield call(api.getInvoice, d.data);
	if (data instanceof Object) {
		yield put(getInvoicesSuccess(data));
	} else {
		yield put(getInvoicesFailed(data));
	}
}

export function* sendInvoice(d) {
	const data = yield call(api.sendInvoice, d.data);
	if (data instanceof Object) {
		yield put(sendInvoiceSuccess("Successfuly sent"));
	} else {
		yield put(sendInvoiceFailed("Invoice was not sucessfully sent"));
	}
}

export function* getOutstanding(d) {
	const data = yield call(api.getOutstanding, d.data);
	if (data instanceof Object) {
		yield put(getOutstandingSummarySuccess(data));
	} else {
		yield put(getOutstandingSummaryFailed(data));
	}
}

export default function* InvoiceSagaWatcher() {
	yield takeLatest(INVOICE.CREATE_INVOICE, createInvoice);
	yield takeLatest(INVOICE.SEND_INVOICE, sendInvoice);
	yield takeLatest(INVOICE.UPDATE_INVOICE, updateInvoice);
	yield takeLatest(INVOICE.GET_INVOICES, getInvoices);
	yield takeLatest(INVOICE.DELETE_INVOICE, deleteInvoice);
	yield takeLatest(INVOICE.GET_OUTSTANDING_SUMMERY, getOutstanding);

}
