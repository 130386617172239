import React, { Component } from 'react';
import Sweden from '../images/swedenIcon.png';
import Uk from '../images/ukIcon.png';
import i18n from '../i18n';

class LanguagePicker extends Component {
    constructor(props) {
        super(props);
        this.state = {
            activeLang : i18n.language
        }
        
    }
    
    changeLanguage = lng => {
        i18n.changeLanguage(lng);
        this.setState({activeLang : lng});
    };

    render() {
        return (
            <>
                <img className={`flag ${this.state.activeLang ==="se" ? "active" : ""}`} onClick={() => this.changeLanguage('se')} src={process.env.PUBLIC_URL + Sweden} alt="Swedish flag"></img>
                <img className={`mlft flag ${this.state.activeLang ==="en" ? "active" : ""}`} onClick={() => this.changeLanguage('en')} src={process.env.PUBLIC_URL + Uk} alt="UK flag"></img>

            </>
        )
    }

}

export default LanguagePicker;