import axios from './clientInstance';
import { BASE_URL } from '../Utils/constants';

const createInvoice = async (data) => {
	try {
		const response = await axios({
			method: 'post',
			url: `${BASE_URL}/invoices`,
			data: data.data,
		});
		if (response.status >= 400) {
			throw new Error(response.statusText);
		}
		return response.data;
	} catch (error) {
		return error.message;
	}
};

const updateInvoice = async (data) => {
	try {
		const response = await axios({
			method: 'put',
			url: `${BASE_URL}/invoices/${data.data.invoiceID}`,
			data: data.data,
		});

		if (response.status >= 400) {
			throw new Error(response.statusText);
		}
		return response.data;
	} catch (error) {
		return error.message;
	}
};

const deleteInvoice = async (id) => {
	try {
		const response = await axios({
			method: 'delete',
			url: `${BASE_URL}/invoices/${id}`,
		});

		if (response.status >= 400) {
			throw new Error(response.statusText);
		}
		return response.data;
	} catch (error) {
		return error.message;
	}
};

const getInvoices = async (id) => {
	try {
		const response = await axios({
			method: 'get',
			url: `${BASE_URL}/invoices?record_status=1,2&customerID=${id}`,
		});

		if (response.status >= 400) {
			throw new Error(response.statusText);
		}
		return response.data;
	} catch (error) {
		return error.message;
	}
};

const getInvoice = async (id) => {
	try {
		const response = await axios({
			method: 'get',
			url: `${BASE_URL}/invoices/${id}`,
		});

		if (response.status >= 400) {
			throw new Error(response.statusText);
		}
		return response.data;
	} catch (error) {
		return error.message;
	}
};

const sendInvoice = async (id) => {
	try {
		const response = await axios({
			method: 'post',
			url: `${BASE_URL}/invoices/email/${id}`,
		});
		if (response.status >= 400) {
			throw new Error(response.statusText);
		}
		return response.data;
	} catch (error) {
		return error.message;
	}
};

const getOutstanding = async (data) => {
	try {
		const response = await axios({
			method: 'get',
			url: `${BASE_URL}/invoices/outstanding?`+ data,
		});

		if (response.status >= 400) {
			throw new Error(response.statusText);
		}
		return response.data;
	} catch (error) {
		return error.message;
	}
};

export default {
	createInvoice,
	updateInvoice,
	deleteInvoice,
	getInvoices,
	getInvoice,
	sendInvoice,
	getOutstanding
};
