import { TIMESHEET } from '../Utils/constants';

const createTimesheet = (data) => ({
	type: TIMESHEET.CREATE_TIMESHEET,
	data,
});

const updateTimesheet = (data) => ({
	type: TIMESHEET.UPDATE_TIMESHEET,
	data,
});

const deleteTimesheet = (id) => ({
	type: TIMESHEET.DELETE_TIMESHEET,
	id,
});

const createOrUpdateFailed = (error) => ({
	type: TIMESHEET.UPDATE_TIMESHEET_FAILED,
	error,
});

const createOrUpdateSuccess = (message) => ({
	type: TIMESHEET.UPDATE_TIMESHEET_SUCCESS,
	message,
});

const getTimesheets = (data) => ({
	type: TIMESHEET.GET_TIMESHEETS,
	data
});

const getTimesheetsFailed = (error) => ({
	type: TIMESHEET.GET_TIMESHEETS_FAILED,
	error,
});

const getTimesheetsSuccess = (timesheets) => ({
	type: TIMESHEET.GET_TIMESHEETS_SUCCESS,
	timesheets,
});

const getSummerizeTimesheets = (data) => ({
	type: TIMESHEET.GET_SUMMERIZE_TIMESHEETS,
	data
});

const getSummerizeTimesheetsFailed = (error) => ({
	type: TIMESHEET.GET_SUMMERIZE_TIMESHEETS_FAILED,
	error,
});

const getSummerizeTimesheetsSuccess = (summerizeTimeSheets) => ({
	type: TIMESHEET.GET_SUMMERIZE_TIMESHEETS_SUCCESS,
	summerizeTimeSheets,
});
export {
	createTimesheet,
	updateTimesheet,
	deleteTimesheet,
	createOrUpdateFailed,
	createOrUpdateSuccess,
	getTimesheets,
	getTimesheetsFailed,
	getTimesheetsSuccess,
	getSummerizeTimesheets,
	getSummerizeTimesheetsFailed,
	getSummerizeTimesheetsSuccess
};
