import React, { Component } from 'react';
import CustomerModal from '../Components/CustomerModal';
import CustomersList from '../Components/CustomersList';
import Alert from 'react-bootstrap/Alert';
import { connect } from "react-redux";
import { getCustomers } from '../Actions/Customer';

class Customers extends Component {

    constructor(props) {
        super(props);
        this.state = {
            opened: false,
            customer: { vatnumber: '' },
            message: null,
            isError: false
        };
        this.toggleCustomerForm = this.toggleCustomerForm.bind(this);
        this.toggleCustomerClose = this.toggleCustomerClose.bind(this);
        this.addNewCustomer = this.addNewCustomer.bind(this);
    }

    toggleCustomerForm(customer) {
        if (customer == null) {
            this.setState({
                opened: true,
                customer: null,
            });
        } else {
            this.setState({
                opened: true,
                customer: customer,
            });
        }
		window.scrollTo(0, 0);
    }

    componentDidUpdate() {
        if (this.state.customer && this.childCustomerModel) {
            this.childCustomerModel.editUser(this.state.customer);
        }

        if (this.props.message || this.props.error) {
            this.props.getCustomers();
            this.setState({
                isError: !!this.props.error,
                message: this.props.message || this.props.error,
            });
            var that = this;
			setTimeout(function () {
				that.setState({ isError: false, message: null });
			}, 3000);
        }
    }

    toggleCustomerClose() {
        this.setState({
            opened: false,
            fetch: true
        });
    }

    addNewCustomer(e) {
        this.toggleCustomerForm(null);
    }

    renderCustomerForm() {
        if (this.state.customer == null) {
            return (
                this.state.opened ?
                    <CustomerModal childRef={ref => (this.childCustomerModel = ref)} toggleCustomerForm={this.toggleCustomerForm} toggleCustomerClose={this.toggleCustomerClose} ></CustomerModal> : ""
            )
        }
        else {
            return (
                this.state.opened ?
                    <CustomerModal customerID={this.state.customer.customerID || ''} customerData={this.state.customer} childRef={ref => (this.childCustomerModel = ref)} toggleCustomerForm={this.toggleCustomerForm} toggleCustomerClose={this.toggleCustomerClose} refreshTable={this.refreshTable}></CustomerModal> : ""
            )
        }
    }

    renderCustomerList(e) {
        return (
            // then pass a ref in your render method like
            <CustomersList toggleCustomerForm={this.toggleCustomerForm} childRef={ref => (this.child = ref)} ></CustomersList>
        )
    }

    render() {
        const { t } = this.props;
        const { message, isError } = this.state;

        return (
            <div>

                <div className="row">
                    <div className="col-lg-10">
                        <h1>{t('customers')}</h1>
                    </div>
                    <div className="col-lg-2 new-cus-btn">
                        <button type="button" onClick={this.addNewCustomer} className="btn btn-success" disabled={this.state.opened} ><i className="far fa-plus-square"></i> {t('add_new_customer')} </button>
                    </div>
                </div>
                {message && (
                    <Alert
                        variant={isError ? 'danger' : 'success'}
                        dismissible
                        onClose={() => this.setState({ isError: false, message: null })}
                    >
                        {message}
                    </Alert>
                )}
                {/* {this.renderCustomerForm()} */}
                {this.state.opened ? <CustomerModal customerID={this.state.customer ? this.state.customer.customerID : ''} customerData={this.state.customer} childRef={ref => (this.childCustomerModel = ref)} toggleCustomerForm={this.toggleCustomerForm} toggleCustomerClose={this.toggleCustomerClose} refreshTable={this.refreshTable}></CustomerModal>
                    : ""}
                {this.renderCustomerList()}

            </div>
        );
    }
}
function mapStateToProps(globalState) {
    return {
        customers: globalState.customers.customers,
        error: globalState.customers.error,
        loading: globalState.customers.loading,
        message: globalState.customers.message,
    };
}
function mapDispatchToProps(dispatch) {
    return {
        getCustomers: () => dispatch(getCustomers()),
    };
}
export default connect(mapStateToProps, mapDispatchToProps)(Customers);
