import axios from './clientInstance';
import { BASE_URL } from '../Utils/constants';

const createCustomer = async (data) => {
	try {
		const response = await axios({
			method: 'post',
			url: `${BASE_URL}/customers`,
			data,
		});

		if (response.status >= 400) {
			throw new Error(response.statusText);
		}
		return response.data;
	} catch (error) {
		return error.message;
	}
};

const updateCustomer = async (data) => {
	try {
		const response = await axios({
			method: 'put',
			url: `${BASE_URL}/customers/${data.customerID}`,
			data,
		});

		if (response.status >= 400) {
			throw new Error(response.statusText);
		}
		return response.data;
	} catch (error) {
		return error.message;
	}
};

const deleteCustomer = async (id) => {
	try {
		const response = await axios({
			method: 'delete',
			url: `${BASE_URL}/customers/${id}`,
		});

		if (response.status >= 400) {
			throw new Error(response.statusText);
		}
		return response.data;
	} catch (error) {
		return error.message;
	}
};

const getCustomer = async (id) => {
	try {
		const response = await axios({
			method: 'get',
			url: `${BASE_URL}/customers/${id}`,
		});

		if (response.status >= 400) {
			throw new Error(response.statusText);
		}
		return response.data;
	} catch (error) {
		return error.message;
	}
};

const getCustomers = async () => {
	try {
		const response = await axios({
			method: 'get',
			url: `${BASE_URL}/customers`,
		});

		if (response.status >= 400) {
			throw new Error(response.statusText);
		}
		return response.data;
	} catch (error) {
		return error.message;
	}
};

const getTimesheetAndExpenseOutstanding = async (data) => {
	try {
		const response = await axios({
			method: 'get',
			url: `${BASE_URL}/customers/timesheets/expenses/outstanding?`+ data,
		});

		if (response.status >= 400) {
			throw new Error(response.statusText);
		}
		return response.data;
	} catch (error) {
		return error.message;
	}
};

export default {
	getCustomers,
	createCustomer,
	updateCustomer,
	deleteCustomer,
	getCustomer,
	getTimesheetAndExpenseOutstanding
};
