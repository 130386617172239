import { SERVICE } from '../Utils/constants';

const createService = (data) => ({
	type: SERVICE.CREATE_SERVICE,
	data,
});

const updateService = (data) => ({
	type: SERVICE.UPDATE_SERVICE,
	data,
});

const deleteService = (data) => ({
	type: SERVICE.DELETE_SERVICE,
	data,
});

const createOrUpdateFailed = (error) => ({
	type: SERVICE.UPDATE_SERVICE_FAILED,
	error,
});

const createOrUpdateSuccess = (message) => ({
	type: SERVICE.UPDATE_SERVICE_SUCCESS,
	message,
});

const getServices = () => ({
	type: SERVICE.GET_SERVICES,
});

const getServicesFailed = (error) => ({
	type: SERVICE.GET_SERVICES_FAILED,
	error,
});

const getServicesSuccess = (services) => ({
	type: SERVICE.GET_SERVICES_SUCCESS,
	services,
});

export {
	createService,
	updateService,
	deleteService,
	createOrUpdateFailed,
	createOrUpdateSuccess,
	getServices,
	getServicesFailed,
	getServicesSuccess,
};
