import { SERVICE } from '../Utils/constants';

const initialState = {
	services: null,
	error: null,
	loading: false,
	message: null,
};

export default (state = initialState, action) => {
	switch (action.type) {
		case SERVICE.CREATE_SERVICE:
		case SERVICE.UPDATE_SERVICE:
		case SERVICE.GET_SERVICES:
			return {
				...state,
				loading: true,
				error: null,
				message: null,
			};
		case SERVICE.UPDATE_SERVICE_SUCCESS:
			return {
				...state,
				loading: false,
				message: action.message,
				error: null,
			};
		case SERVICE.UPDATE_SERVICES_FAILED:
			return {
				...state,
				loading: false,
				message: action.message,
				error: action.error,
			};
		case SERVICE.GET_SERVICES_FAILED:
			return {
				...state,
				error: action.error,
				loading: false,
				message: null,
			};
		case SERVICE.GET_SERVICES_SUCCESS:
			return {
				...state,
				services: action.services,
				loading: false,
				error: null,
				message: null,
			};
		default:
			return state;
	}
};
