import axios from 'axios';
import { logout } from '../Actions/Auth';
import store from '../store';
import i18n from '../i18n';

axios.interceptors.response.use(
	function (response) {
		// Any status code that lie within the range of 2xx cause this function to trigger
		// Do something with response data
		return response;
	},
	function (error) {
		if (error.response && error.response.data && error.response.status === 403) {
			store.dispatch(logout(i18n.t("session_expired")));
			
		}
		// Any status codes that falls outside the range of 2xx cause this function to trigger
		// Do something with response error
		return Promise.reject(error.response.data);
	}
);

axios.interceptors.request.use(
	function (config) {
		if (localStorage.getItem('id_token')) {
			config.headers['Authorization'] = 'Bearer ' + localStorage.getItem('id_token');
		}
		return config;
	},
	function (error) {
		return Promise.reject(error);
	}
);

export default axios;
