import React from 'react';
import { connect } from 'react-redux';
import { Route, Redirect } from 'react-router';

const AuthRoute = (props) => {
	const { token } = props;
	if(!token){
		return <Redirect to="/" />;
	}
	else{
		return <Route {...props} />;
	}

};

const mapStateToProps = ({ auth }) => ({
	token: auth.token,
	user: auth.user,
});

export default connect(mapStateToProps)(AuthRoute);
