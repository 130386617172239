import { PASSWORD } from '../Utils/constants';

const setPassword = (data, token) => ({
	type: PASSWORD.CREATE_PASSWORD,
	data,
	token
});

const resetPassword = (data) => ({
	type: PASSWORD.RESET_PASSWORD,
	data
});

const setPasswordFailed = (error) => ({
	type: PASSWORD.CREATE_PASSWORD_FAILED,
	error,
});

const setPasswordSuccess = (message) => ({
	type: PASSWORD.CREATE_PASSWORD_SUCCESS,
	message,
});

export {
	setPassword,
	setPasswordFailed,
	setPasswordSuccess,
	resetPassword
};
