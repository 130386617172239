import React, { Component } from 'react';
import TimeLogsList from './TimeLogsList';
import DatePicker from "react-datepicker";
import { withTranslation } from 'react-i18next';
import i18n from '../i18n';
import { connect } from "react-redux";
import { getUserservices } from '../Actions/Userservice';
import { createTimesheet, updateTimesheet } from '../Actions/Timesheet';


class TimeReportingModal extends Component {
    constructor(props) {
        super(props)
        this.state = {
            defaultServiceID: 0,
            defaultUserID: 0,
            selectedServiceID: 0,
            selectedUserID: 0,

            formValues: {
                timesheetID: 0,
                customerID: props.customerId,
                serviceID: 0,
                userID: 0,
                hourlyRate: '',
                hours: '',
                workAt: new Date(),
                currency: 'SEK',
                comment: ''
            },
            formErrors: {
                serviceID: '',
                userID: '',
                hourlyRate: '',
                hours: ''
            },
            formValidity: {
                serviceID: '',
                userID: '',
                hourlyRate: '',
                hours: ''
            },
            isSubmitting: false,
            services: props.services,
            consultants: props.consultants,
            userServices: props.UserServices,

        };
        this.handleChange = this.handleChange.bind(this);
        this.onDateChange = this.onDateChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
        this.resetForm = this.resetForm.bind(this);
        this.editTimesheet = this.editTimesheet.bind(this);
        this.fetchReportedTimelogs = this.fetchReportedTimelogs.bind(this);

    }



    onDateChange(date) {
        const { formValues } = this.state;
        formValues.workAt = date;
        this.setState({
            formValues
        });
    };



    handleSubmit(event) {
        event.preventDefault();
        this.setState({ isSubmitting: true });
        const { formValues, formValidity } = this.state;
        if (Object.values(formValidity).every(Boolean)) {
            if (formValues.timesheetID > 0) {
                this.props.updateTimesheet(formValues);
            } else {
                this.props.createTimesheet(formValues);
            }
            this.resetForm();
        } else {
            this.setValidation(formValues);
            this.setState({ isSubmitting: false });
        }
    }

    setValidation(formValues) {
        for (let key in formValues) {
            let target = {
                name: key,
                value: formValues[key]
            };
            this.handleValidation(target);
        }
        this.setState({ isSubmitting: false });
    }

    fetchReportedTimelogs() {
        if (this.timeLogsChild && this.timeLogsChild.fetchReportedTimeLogs) {
            this.timeLogsChild.fetchReportedTimeLogs(this.state.formValues.customerID, "", this.state.formValues.userID)
        }
    }

    componentDidMount() {
        let customerId = this.props.customerId;
        if (customerId) {
            this.setState({ customerID: customerId });
            this.props.getUserservices(customerId);
        }

        let user = localStorage.getItem('user');

        let array = this.props.userServices;
            if (array && array.length > 0) {
                array.some(element => {
                    if (element.userID === user.userID) {
                        this.setServiceAndUser(element);
                        return true;
                    }
                });
            }
    }

    componentDidUpdate() {
        if (this.props.message || this.props.error) {
            this.props.getUserservices(this.props.customerId);
            this.setState({
                isError: !!this.props.error,
                message: this.props.message || this.props.error,
            });
            var that = this;
			setTimeout(function () {
				that.setState({ isError: false, message: null });
			}, 3000);
        }
        
    }

    setServiceAndUser(userService) {
        const fieldValidationErrors = this.state.formErrors;
        const validity = this.state.formValidity;
        const { formValues } = this.state;

        let serviceId = userService.serviceID;
        let userId = userService.userID;

        this.setState({ selectedUserID: userService.userID });
        this.setState({ selectedServiceID: serviceId });

        formValues["serviceName"] = userService.serviceName;
        formValues["userID"] = userService.userID;

        validity["serviceID"] = serviceId > 0;
        fieldValidationErrors["serviceID"] = serviceId > 0 ? "" : i18n.t("select_a_service");

        validity["userID"] = userId > 0;
        fieldValidationErrors["userID"] = userId > 0 ? "" : i18n.t("please_select_assignee");

        let values = this.state.formValues;
        values.serviceID = serviceId;
        values.userID = userId;
        values.hourlyRate = userService.hourlyRate;
        validity["hourlyRate"] = values.hourlyRate > 0;
        fieldValidationErrors["hourlyRate"] = values.hourlyRate > 0 ? "" : i18n.t("hourly_rate_is_required");
        this.setState({
            formErrors: fieldValidationErrors,
            formValidity: validity,
            formValues: values
        });
    }

    handleChange(e) {
        const target = e.target;
        if (target.type === "select-one") {
            const value = target.value;
            const name = target.name;
            const { formValues } = this.state;
            formValues[name] = value;
            this.setState({ formValues });

            if (name === "serviceID") {
                formValues["serviceName"] = target[target.selectedIndex].text;
                this.setState({ selectedServiceID: target.value });
                if (this.props.userServices.result[target.selectedIndex - 1]) {
                    this.setServiceAndUser(this.props.userServices.result[target.selectedIndex - 1]);
                }
                else {
                    this.setState({ selectedUserID: 0 });
                }
            }
            if (name === "userID") {
                this.setState({ selectedUserID: target.value });
                if (this.props.userServices.result[target.selectedIndex - 1]) {
                    this.setServiceAndUser(this.props.userServices.result[target.selectedIndex - 1]);
                }
                this.fetchReportedTimelogs();
            }
            this.handleValidation(target);
        }
        else {
            const value = target.type = target.value;
            const name = target.name;
            const { formValues } = this.state;
            formValues[name] = value;
            this.setState({ formValues });
            this.handleValidation(target);
        }
    }

    handleValidation = target => {
        const { name, value } = target;
        const fieldValidationErrors = this.state.formErrors;
        const validity = this.state.formValidity;

        switch (name) {
            case "serviceID":
                validity[name] = value > 0;
                fieldValidationErrors[name] = value > 0 ? "" : i18n.t("select_a_service");
                break;
            case "userID":
                validity[name] = value > 0;
                fieldValidationErrors[name] = value > 0 ? "" : i18n.t("please_select_assignee");
                break;
            case "hourlyRate":
                validity[name] = value > 0;
                fieldValidationErrors[name] = validity[name] ? "" : i18n.t("hourly_rate_is_required");
                break;
            case "hours":
                validity[name] = value > 0;
                fieldValidationErrors[name] = validity[name] ? "" : i18n.t("working_time_is_required");
                break;
            case "workAt":
                fieldValidationErrors[name] = validity[name] ? "" : "Date is required and cannot be empty";
                break;
            default:
                break;
        }

        const numberTest = /^(\d*\.)?\d+$/;
        if (validity[name]) {
            if (name === "hourlyRate" || name === "hours") {
                validity[name] = numberTest.test(value);
                fieldValidationErrors[name] = validity[name]
                    ? ""
                    : `Value should be a valid number`;
            }
        }
        this.setState({
            formErrors: fieldValidationErrors,
            formValidity: validity
        });
    };

    editTimesheet(timesheet) {
        if (timesheet) {
            var newformValues = {
                timesheetID: timesheet.timesheetID,
                comment: timesheet.comment ? timesheet.comment : "",
                hourlyRate: parseFloat(timesheet.hourlyRate),
                hours: parseFloat(timesheet.hours),
                workAt: new Date(timesheet.workAt),
                serviceID: timesheet.serviceID,
                userID: timesheet.userID,

            };
            this.setState({ selectedServiceID: timesheet.serviceID });
            this.setState({ selectedUserID: timesheet.userID });
            this.setState({ formValues: newformValues });
            this.setValidation(newformValues);

            document.getElementsByName("userID")[0].disabled = true;
            document.getElementsByName("serviceID")[0].disabled = true;
            window.scrollTo(0, 0);
        }
    }

    resetForm() {
        this.setState({
            selectedService: 0,
            defaultServiceID: 0,
            defaultUserID: 0,
            selectedServiceID: 0,
            selectedUserID: 0,
            formValues: {
                timesheetID: 0,
                customerID: this.props.customerId,
                serviceID: 0,
                userID: 0,
                hourlyRate: '',
                hours: '',
                workAt: new Date(),
                comment: ''
            },
            formErrors: {
                serviceID: '',
                userID: '',
                hourlyRate: '',
                hours: ''
            },
            formValidity: {
                serviceID: '',
                userID: '',
                hourlyRate: '',
                hours: ''
            },
            isSubmitting: false,

        });
        document.getElementsByName("userID")[0].disabled = false;
        document.getElementsByName("serviceID")[0].disabled = false;
    }

    render() {
        const { formValues, formErrors, isSubmitting } = this.state;
        const { t, userServices } = this.props;
        return (
            <div className="modal-box" >
                <div className="card">
                    <form className="form-horizontal" onSubmit={this.handleSubmit}>
                        <div className="card-header">
                            <i className="fa fa-user-plus" ></i> {t('report_time')} {/* : {formValues.customerID} */}
                        </div>
                        <div className="card-body">
                            <div className="row">
                                <div className="col-lg-4">
                                    <div className="form-group">
                                        <label> {t('service')} </label> <span className="required-star">*</span>
                                        <select value={this.state.selectedServiceID} onChange={this.handleChange}
                                            className={`form-control form-control-lg ${
                                                formErrors.serviceID ? "is-invalid" : ""
                                                }`}
                                            name="serviceID" title={t('select_a_service')}>
                                            <option value="0"  >{t('select_a_service')}</option>
                                            {userServices ? userServices.result.map((team, index) => <option key={index} value={team.serviceID}>{team.serviceName} </option>) : ''}

                                        </select>
                                        <div className="invalid-feedback">{formErrors.serviceID}</div>
                                    </div>
                                </div>
                                <div className="col-lg-4">
                                    <div className="form-group">
                                        <label>{t('consultants')}</label> <span className="required-star">*</span>
                                        <select value={this.state.selectedUserID} onChange={this.handleChange}
                                            className={`form-control form-control-lg ${
                                                formErrors.userID ? "is-invalid" : ""
                                                }`}
                                            name="userID" title={t('select_a_consultants')}>
                                            <option value="0" >{t('select_a_consultants')}</option>
                                            {userServices ? userServices.result.map((team, index) => <option key={index} value={team.user.userID}>{team.user.name} </option>) : ''}

                                        </select>
                                        <div className="invalid-feedback">{formErrors.userID}</div>
                                    </div>
                                </div>
                                <div className="col-lg-4">
                                    <div className="form-group">
                                        <label>{t('hourly_rate')}</label> <span className="required-star">*</span>
                                        <input
                                            type="text" name="hourlyRate"
                                            className={`form-control form-control-lg ${
                                                formErrors.hourlyRate ? "is-invalid" : ""
                                                }`}
                                            value={formValues.hourlyRate}
                                            onChange={this.handleChange} />
                                        <div className="invalid-feedback">{formErrors.hourlyRate}</div>
                                    </div>
                                </div>
                            </div>

                            <div className="row">
                                <div className="col-lg-4">
                                    <div className="form-group">
                                        <label> {t('working_time')} </label> <span className="required-star">*</span>
                                        <input
                                            type="text" name="hours"
                                            className={`form-control form-control-lg ${
                                                formErrors.hours ? "is-invalid" : ""
                                                }`}
                                            value={formValues.hours}
                                            onChange={this.handleChange} />
                                        <div className="invalid-feedback">{formErrors.hours}</div>
                                    </div>
                                </div>
                                <div className="col-lg-4">
                                    <div className="form-group">
                                        <label>{t('date')}</label> <span className="required-star">*</span>
                                        <br></br>
                                        <DatePicker className="r-datapicker" dateFormat="yyyy/MM/dd" name="workAt" onChange={this.onDateChange}
                                            selected={formValues.workAt}></DatePicker>
                                        <div className="invalid-feedback">{formErrors.workAt}</div>
                                    </div>
                                </div>
                                <div className="col-lg-4">
                                    <div className="form-group">
                                        <label>{t('comment')}</label>
                                        <input
                                            type="text" name="comment"
                                            className={`form-control form-control-lg ${
                                                formErrors.comment ? "is-invalid" : ""
                                                }`}
                                            value={formValues.comment}
                                            onChange={this.handleChange} />
                                    </div>
                                </div>
                            </div>

                        </div>
                        <div className="card-footer">
                            {isSubmitting ? (
                                <button type="button" className="btn btn-success loading" disabled>
                                    <i className="fas fa-sync-alt fa-spin"></i>
                                </button>
                            ) : (
                                    <span>
                                        <button type="submit" className="btn btn-success"><i className="far fa-dot-circle"></i>  {isSubmitting ? t('please_wait') : t('save')}</button>
                                        <button type="button" onClick={this.resetForm} className="btn btn-info" ><i className="fa fa-times"></i> {t('clear')}</button>
                                    </span>
                                )}
                        </div>
                    </form>
                </div>
                <div>
                    <h5>{t('previously_logged_time')}</h5>
                    <TimeLogsList customerId={this.props.customerId} childRef2={ref => (this.timeLogsChild = ref)} editTimelog={this.editTimesheet}></TimeLogsList>
                </div>
            </div>
        )
    }
}

function mapStateToProps(globalState) {
    return {
        userServices: globalState.userServices.userservices,
        timesheets: globalState.timesheets.timesheets,
    };
}

function mapDispatchToProps(dispatch) {
    return {
        getUserservices: (id) => dispatch(getUserservices(id)),
        createTimesheet: (formValues) => dispatch(createTimesheet(formValues)),
        updateTimesheet: (formValues) => dispatch(updateTimesheet(formValues)),
    };
}

export default withTranslation('translation', { withRef: true })(connect(mapStateToProps, mapDispatchToProps)(TimeReportingModal));


