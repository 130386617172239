import { USERSERVICE } from '../Utils/constants';

const createUserservice = (data) => ({
	type: USERSERVICE.CREATE_USERSERVICE,
	data,
});
const updateUserservice = (data) => ({
	type: USERSERVICE.UPDATE_USERSERVICE,
	data,
});

const deleteUserservice = (id) => ({
	type: USERSERVICE.DELETE_USERSERVICE,
	id,
});

const createOrUpdateFailed = (error) => ({
	type: USERSERVICE.UPDATE_USERSERVICE_FAILED,
	error,
});

const createOrUpdateSuccess = (message) => ({
	type: USERSERVICE.UPDATE_USERSERVICE_SUCCESS,
	message,
});

const getUserservices = (id) => ({
	type: USERSERVICE.GET_USERSERVICES,
	id
});

const getUserservicesFailed = (error) => ({
	type: USERSERVICE.GET_USERSERVICES_FAILED,
	error,
});

const getUserservicesSuccess = (userservices) => ({
	type: USERSERVICE.GET_USERSERVICES_SUCCESS,
	userservices,
});

export {
	createUserservice,
	updateUserservice,
	deleteUserservice,
	createOrUpdateFailed,
	createOrUpdateSuccess,
	getUserservices,
	getUserservicesFailed,
	getUserservicesSuccess,
};
