import React, { Component, Fragment } from 'react';
import { withTranslation } from 'react-i18next';
import { connect } from "react-redux";
import Moment from 'moment';
import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory from 'react-bootstrap-table2-paginator';
import i18n from '../i18n';
import { getExpenses, deleteExpense } from '../Actions/Expense';
import { DATE_FORMAT } from '../Utils/constants';


class ExpencesList extends Component {
    constructor(props) {
        super(props)
        this.state = {
            customerId: this.props.customerId,
            expenses: props.expenses,
            pageType: "",
            newInvoice: [],
            selectedExpenses: [],
            message: null,
            isError: false
        };
        this.columns = [
            {
                dataField: 'expenseID',
                text: i18n.t('id'),
                hidden: true,
            },
            {
                dataField: 'expenseAt',
                text: i18n.t('date'),
                formatter: (cell) => { return Moment(cell).format(DATE_FORMAT) },
                sort: true,
            },
            {
                dataField: 'description',
                text: i18n.t('description'),
                sort: true,
            },
            {
                dataField: 'amount',
                text: i18n.t('amount'),
                formatter: (cell) => { return cell.toFixed(2) },
                sort: true,
                align: 'right'
            },
            {
                dataField: 'actions',
                text: i18n.t('actions'),
                isDummyField: true,
                csvExport: false,
                formatter: (cell, row) => {
                    return (
                        <Fragment>
                            <button
                                type="button"
                                className="btn btn-primary"
                                onClick={() => this.editExpense(row)}
                            >
                                <i className="fa fa-edit"></i>
                            </button>
                            <button
                                className="btn btn-danger"
                                onClick={() => {
                                    if (window.confirm('Are you sure to delete this Expence ?'))
                                        this.handleDelete(row.expenseID);
                                }}
                            >
                                <i className="fa fa-trash"></i>
                            </button>
                        </Fragment>
                    );
                },
            },
        ];


    }

    handleAllChecked = (event) => {
        let chkbxs = this.props.expenses;
        let value = false;
        chkbxs.forEach(chkbx => {
            if (chkbx.isChecked = event.target.checked) {
                this.setState({ expenses: chkbxs });
            }
            value = event.target.checked;
        });
        this.getSelectedCheckboxes();
    }

    handleCheckChieldElement = (event) => {
        let chkbxs = this.props.expenses;
        chkbxs.forEach(chkbox => {
            if (chkbox.expenseID === parseInt(event.target.value)) {
                chkbox.isChecked = event.target.checked
            }
        })
        this.setState({ expenses: chkbxs });
        this.getSelectedCheckboxes();
    }

    getSelectedCheckboxes() {
        let chkbxs = this.props.expenses;
        this.setState({ selectedExpenses: [] });
        let checkboxs = [];
        for (var i = 0; i < chkbxs.length; i++) {
            if (chkbxs[i].isChecked) {
                checkboxs.push(chkbxs[i]);
            }
        }
        return checkboxs;
    }

    componentDidMount() {
        let customerId = this.props.customerId > 0 ? this.props.customerId : 0;
        this.props.getExpenses('?isInvoice=0&customerID=' + customerId);
    }

    componentDidUpdate() {
        if (this.props.message || this.props.error) {
            let customerId = this.props.customerId > 0 ? this.props.customerId : 0;
            this.props.getExpenses('?isInvoice=0&customerID=' + customerId);
            this.setState({
                isError: !!this.props.error,
                message: this.props.message || this.props.error,
            });
            var that = this;
			setTimeout(function () {
				that.setState({ isError: false, message: null });
			}, 3000);
        }
    }

    componentWillUnmount() {
        const { childRef } = this.props;
        childRef(undefined);
    }

    handleDelete(expenceId) {
        this.props.deleteExpense(expenceId);
    }

    editExpense(expence) {
        this.props.editExpense(expence);

    }

    render() {
        const { expenses } = this.props;

        return (
            <div>

                <BootstrapTable
                    keyField="expenseID"
                    data={expenses ? expenses.result : []}
                    columns={this.columns}
                    pagination={paginationFactory()}
                    striped
                    hover
                    condensed
                />
            </div>
        )
    }
}

function mapStateToProps(globalState) {
    return {
        expenses: globalState.expenses.expenses,
        error: globalState.expenses.error,
        loading: globalState.expenses.loading,
        message: globalState.expenses.message
    };
}

function mapDispatchToProps(dispatch) {
    return {
        getExpenses: (data) => dispatch(getExpenses(data)),
        deleteExpense: (id) => dispatch(deleteExpense(id))
    };
}

export default withTranslation('translation', { withRef: true })(connect(mapStateToProps, mapDispatchToProps)(ExpencesList));




