import axios from './clientInstance';
import { BASE_URL } from '../Utils/constants';

const createTimesheet = async (data) => {
	try {
		const response = await axios({
			method: 'post',
			url: `${BASE_URL}/timesheets`,
			data,
		});

		if (response.status >= 400) {
			throw new Error(response.statusText);
		}
		return response.data;
	} catch (error) {
		return error.message;
	}
};

const updateTimesheet = async (data) => {
	try {
		const response = await axios({
			method: 'put',
			url: `${BASE_URL}/timesheets/${data.data.timesheetID}`,
			data: data.data,
		});

		if (response.status >= 400) {
			throw new Error(response.statusText);
		}
		return response.data;
	} catch (error) {
		return error.message;
	}
};

const deleteTimesheet = async (data) => {
	try {
		const response = await axios({
			method: 'delete',
			url: `${BASE_URL}/timesheets/${data.id}`,
		});

		if (response.status >= 400) {
			throw new Error(response.statusText);
		}
		return response.data;
	} catch (error) {
		return error.message;
	}
};

const getTimesheets = async (data) => {
	try {
		const response = await axios({
			method: 'get',
			url: `${BASE_URL}/timesheets${data}`,
		});

		if (response.status >= 400) {
			throw new Error(response.statusText);
		}
		return response.data;
	} catch (error) {
		return error.message;
	}
};

export default {
	createTimesheet,
	updateTimesheet,
	deleteTimesheet,
	getTimesheets
	
};
