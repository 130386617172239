import React from 'react';
import { connect } from 'react-redux';
import { Route, Redirect } from 'react-router';

const LoggedInRedirectRoute = (props) => {
	const { token, user } = props;
	if (token && user && props.path && (props.path === '/' || props.path === '/login')) {
		return <Redirect to="/pt/customers" />;
	} else {
		return <Route {...props} />;
	}
};

const mapStateToProps = ({ auth }) => {
	return {
		token: auth.token,
		user: auth.user,
	};
};

export default connect(mapStateToProps)(LoggedInRedirectRoute);
