import { all } from 'redux-saga/effects';
import ServiceSaga from './Service.Saga';
import CustomerSaga from './customer.saga';
import ConsultantSaga from './Consultant.Saga';
import UserserviceSaga from './userservice.saga';
import TimesheetSaga from './timesheet.saga';
import ExpenseSaga from './expense.saga';
import InvoiceSaga from './invoice.saga';
import AuthSaga from './Auth.Saga';
import passwordSaga from './Password.Saga';

export default function* rootSaga() {
	yield all([ InvoiceSaga(),TimesheetSaga(),ExpenseSaga(),UserserviceSaga(), ConsultantSaga(), ServiceSaga(), CustomerSaga(), AuthSaga(), passwordSaga()]);
}
