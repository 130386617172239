import React, { Component, Fragment } from 'react';
import { withTranslation } from 'react-i18next';
import { connect } from "react-redux";
import Moment from 'moment';
import { getTimesheets, deleteTimesheet } from '../Actions/Timesheet';
import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory from 'react-bootstrap-table2-paginator';
import i18n from '../i18n';
import Alert from 'react-bootstrap/Alert';
import { DATE_FORMAT } from '../Utils/constants';


class TimeLogsList extends Component {

    constructor(props) {
        super(props)
        this.state = {
            timesheets: props.timesheets,
            timeRecordId: this.props.timeRecordId,
            pageType: props.pageType,
            timeRecords: [],
            chkbox: false,
            customerId: "",
            selectedTimesheets: []

        };

        this.columns = [
            {
                dataField: 'timesheetID',
                text: i18n.t('id'),
                hidden: true
            },
            {
                dataField: 'serviceName',
                text: i18n.t('service'),
                sort: true,
            },
            {
                dataField: 'user.name',
                text: i18n.t('consultants'),
                sort: true,
            },
            {
                dataField: 'hourlyRate',
                text: i18n.t('hourly_rate'),
                formatter: (cell) => { return cell.toFixed(2) },
                sort: true,
                align: 'right'
            },
            {
                dataField: 'hours',
                text: i18n.t('working_time'),
                formatter: (cell) => { return cell.toFixed(2) },
                sort: true,
                align: 'right'
            },
            {
                dataField: 'workAt',
                formatter: (cell) => { return Moment(cell).format(DATE_FORMAT) },
                text: i18n.t('worked_date'),
                sort: true,
            },
            {
                dataField: 'actions',
                text: i18n.t('actions'),
                isDummyField: true,
                csvExport: false,
                sort: false,
                formatter: (cell, row) => {
                    return (
                        <Fragment>
                            <button
                                type="button"
                                className="btn btn-primary"
                                onClick={() => this.handleEdit(row)}
                            >
                                <i className="fa fa-edit"></i>
                            </button>
                            <button
                                className="btn btn-danger"
                                onClick={() => {
                                    if (window.confirm('Are you sure to delete this Time Log ?'))
                                        this.handleDelete(row.timesheetID);
                                }}
                            >
                                <i className="fa fa-trash"></i>
                            </button>
                        </Fragment>
                    );
                },
            },
        ];
    }


    componentDidMount() {
        this.props.getTimesheets('?isInvoice=0&customerID=' + this.props.customerId);
        // this.props.getTimesheets('?isInvoice=0&customerID=' + this.props.customerId + "&userID=" + consultantId );
    }

    componentDidUpdate(props) {
        if (this.props.message || this.props.error) {
            this.props.getTimesheets('?isInvoice=0&customerID=' + this.props.customerId);
            this.setState({
                isError: !!this.props.error,
                message: this.props.message || this.props.error,
            });
            var that = this;
            setTimeout(function () {
                that.setState({ isError: false, message: null });
            }, 3000);

        }
    }

    handleDelete(id) {
        this.props.deleteTimesheet(id);
    }

    componentWillUnmount() {
        const { childRef2 } = this.props;
        childRef2(undefined);
    }

    handleAllChecked = (event) => {
        let chkbxs = this.props.timeSheets;
        let value = false;
        chkbxs.forEach(chkbx => {
            if (chkbx.isChecked = event.target.checked) {
                this.setState({ timeSheets: chkbxs });
            }
            value = event.target.checked;
        });
        this.getSelectedCheckboxes();
    }

    handleCheckChieldElement = (event) => {
        let chkbxs = this.props.timeSheets;
        chkbxs.forEach(chkbox => {
            if (chkbox.timesheetID === parseInt(event.target.value)) {
                chkbox.isChecked = event.target.checked
            }
        })
        this.setState({ timeSheets: chkbxs });
        this.getSelectedCheckboxes();
    }

    getSelectedCheckboxes() {
        let chkbxs = this.props.timeSheets;
        this.setState({ selectedTimesheets: [] });
        let checkboxs = [];
        for (var i = 0; i < chkbxs.length; i++) {
            if (chkbxs[i].isChecked) {
                checkboxs.push(chkbxs[i]);
            }
        }
        return checkboxs;
    }

    handleEdit(timelog) {
        this.props.editTimelog(timelog);
    }


    render() {
        const { message, isError } = this.state;
        return (
            <div>
                {message && (
                    <Alert
                        variant={isError ? 'danger' : 'success'}
                        dismissible
                        onClose={() => this.setState({ isError: false, message: null })}
                    >
                        {message}
                    </Alert>
                )}
                <BootstrapTable
                    keyField="timesheetID"
                    data={this.props.timesheets ? this.props.timesheets.result : []}
                    columns={this.columns}
                    pagination={paginationFactory()}
                    striped
                    hover
                    condensed
                />

            </div>
        )
    }
}

function mapStateToProps(globalState) {
    return {
        timesheets: globalState.timesheets.timesheets,
        error: globalState.timesheets.error,
        loading: globalState.timesheets.loading,
        message: globalState.timesheets.message,

    };
}
function mapDispatchToProps(dispatch) {
    return {
        getTimesheets: (data) => dispatch(getTimesheets(data)),
        deleteTimesheet: (id) => dispatch(deleteTimesheet(id)),
    };
}
export default withTranslation('translation', { withRef: true })(
    connect(mapStateToProps, mapDispatchToProps)(TimeLogsList)
);



