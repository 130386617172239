import React, {Component} from 'react';
import NotFound from '../Components/NotFound';

class NotFoundPage extends Component{
    render(){
        return(
            <div className="notfound-page">
                <NotFound />
            </div>
        )
    }
}

export default NotFoundPage;