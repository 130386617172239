import { CONSULTANT } from '../Utils/constants';

const initialState = {
	consultants: null,
	error: null,
	loading: false,
	message: null,
};

export default (state = initialState, action) => {
	switch (action.type) {
		case CONSULTANT.CREATE_CONSULTANT:
		case CONSULTANT.UPDATE_CONSULTANT:
		case CONSULTANT.GET_CONSULTANTS:
			return {
				...state,
				loading: true,
				error: null,
				message: null,
			};
		case CONSULTANT.UPDATE_CONSULTANT_SUCCESS:
			return {
				...state,
				loading: false,
				message: action.message,
				error: null,
			};
		case CONSULTANT.UPDATE_CONSULTANT_FAILED:
			return {
				...state,
				loading: false,
				message: action.message,
				error: action.error,
			};
		case CONSULTANT.CREATE_CONSULTANT_FAILED:
			return {
				...state,
				loading: false,
				message: action.message,
				error: action.error
			};
		case CONSULTANT.GET_CONSULTANTS_FAILED:
			return {
				...state,
				error: action.error,
				loading: false,
				message: action.message
			};
		case CONSULTANT.GET_CONSULTANTS_SUCCESS:
			return {
				...state,
				consultants: action.consultants,
				loading: false,
				error: null,
				message: null,
			};
		default:
			return state;
	}
};
