import { TIMESHEET } from '../Utils/constants';

const initialState = {
	timesheets: null,
	summerizeTimeSheets: null,
	error: null,
	loading: false,
	message: null,
};

export default (state = initialState, action) => {
	switch (action.type) {
		case TIMESHEET.CREATE_TIMESHEET:
		case TIMESHEET.UPDATE_TIMESHEET:
		case TIMESHEET.GET_TIMESHEETS:
			return {
				...state,
				loading: true,
				error: null,
				message: null,
			};
		case TIMESHEET.UPDATE_TIMESHEET_SUCCESS:
			return {
				...state,
				loading: false,
				message: action.message,
				error: null,
			};
		case TIMESHEET.UPDATE_TIMESHEETS_FAILED:
		case TIMESHEET.GET_TIMESHEETS_FAILED:
			return {
				...state,
				error: action.error,
				loading: false,
				message: null,
			};
		case TIMESHEET.GET_TIMESHEETS_SUCCESS:
			return {
				...state,
				timesheets: action.timesheets,
				loading: false,
				error: null,
				message: null,
			};
		case TIMESHEET.GET_SUMMERIZE_TIMESHEETS:
			return {
				...state,
				loading: true,
				error: null,
				message: null
			};
		case TIMESHEET.GET_SUMMERIZE_TIMESHEETS_SUCCESS:
			return {
				...state,
				summerizeTimeSheets: action.summerizeTimeSheets,
				loading: false,
				error: null,
				message: null,
			};
		case TIMESHEET.GET_SUMMERIZE_TIMESHEETS_FAILED:
			return {
				...state,
				error: action.error,
				loading: false,
				message: null,
			};
		default:
			return state;
	}
};
