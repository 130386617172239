import axios from './clientInstance';
import { BASE_URL } from '../Utils/constants';

const getAuthToken = async (data) => {
	try {
		const response = await axios({
			method: 'post',
			url: `${BASE_URL}/auth/gettoken`,
			data,
		});

		if (response.status >= 400) {
			throw new Error(response.statusText);
		}
		return response.data;
	} catch (error) {
		return error.message;
	}
};

const setPassword = async (data, token) => {
	try {
		const response = await axios({
			method: 'put',
			url: `${BASE_URL}/users/setpassword/${token}`,
			data,
		});

		if (response.status >= 400) {
			throw new Error(response.statusText);
		}
		return response.data;
	} catch (error) {
		return error.message;
	}
};

const resetPassword = async (data, token) => {
	try {
		const response = await axios({
			method: 'put',
			url: `${BASE_URL}/users/reset/password`,
			data,
		});

		if (response.status >= 400) {
			throw new Error(response.statusText);
		}
		return response.data;
	} catch (error) {
		return error.message;
	}
};

const createService = async (data) => {
	try {
		const response = await axios({
			method: 'post',
			url: `${BASE_URL}/services`,
			data,
		});

		if (response.status >= 400) {
			throw new Error(response.statusText);
		}
		return response.data;
	} catch (error) {
		return error.message;
	}
};

const updateService = async (data) => {
	try {
		const response = await axios({
			method: 'put',
			url: `${BASE_URL}/services/${data.serviceID}`,
			data,
		});

		if (response.status >= 400) {
			throw new Error(response.statusText);
		}
		return response.data;
	} catch (error) {
		return error.message;
	}
};

const deleteService = async (id) => {
	try {
		const response = await axios({
			method: 'delete',
			url: `${BASE_URL}/services/${id}`,
		});

		if (response.status >= 400) {
			throw new Error(response.statusText);
		}
		return response.data;
	} catch (error) {
		return error.message;
	}
};

const getServices = async () => {
	try {
		const response = await axios({
			method: 'get',
			url: `${BASE_URL}/services`,
		});

		if (response.status >= 400) {
			throw new Error(response.statusText);
		}
		return response.data;
	} catch (error) {
		return error.message;
	}
};

export default {
	getServices,
	createService,
	updateService,
	deleteService,
	getAuthToken,
	setPassword,
	resetPassword,
};
