import { put, call, takeLatest } from 'redux-saga/effects';
import { PASSWORD } from '../Utils/constants';
import api from '../Api';
import {
	setPasswordFailed,
	setPasswordSuccess
} from '../Actions/Password';

export function* resetPassword(data) {
	const resp = yield call(api.resetPassword, data.data);
	if (resp instanceof Object) {
		yield put(setPasswordSuccess('Successfully updated'));
	} else {
		yield put(setPasswordFailed("Failed to update your password. Please try again"));
		
	}
}

export function* setPassword(data) {
	const resp = yield call(api.setPassword, data.data, data.token);
	if (resp instanceof Object) {
		yield put(setPasswordSuccess('Successfully updated'));
	} else {
		yield put(setPasswordFailed("Failed to update your password. Please try again"));
	}
}


export default function* passwordSagaWatcher() {
	yield takeLatest(PASSWORD.CREATE_PASSWORD, setPassword);
	yield takeLatest(PASSWORD.RESET_PASSWORD, resetPassword);
}
