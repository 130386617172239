import { put, call, takeLatest } from 'redux-saga/effects';
import { TIMESHEET } from '../Utils/constants';
import api from '../Api/timesheet.api';
import {
	createOrUpdateFailed,
	createOrUpdateSuccess,
	getTimesheetsFailed,
	getTimesheetsSuccess,
	getSummerizeTimesheetsSuccess,
	getSummerizeTimesheetsFailed
} from '../Actions/Timesheet';
import i18n from '../i18n';

export function* createTimesheet({ data }) {
	const resp = yield call(api.createTimesheet, data);
	if (resp instanceof Object) {
		yield put(createOrUpdateSuccess(i18n.t('successfuly_created')));
	} else {
		yield put(createOrUpdateFailed(resp));
	}
}

export function* updateTimesheet(d, id) {
	const data = yield call(api.updateTimesheet, d, id);
	if (data instanceof Object) {
		yield put(createOrUpdateSuccess(i18n.t('successfuly_updated')));
	} else {
		yield put(createOrUpdateFailed(data));
	}
}

export function* deleteTimesheet(id) {
	const data = yield call(api.deleteTimesheet, id);
	if (data instanceof Object) {
		yield put(createOrUpdateSuccess(i18n.t('successfuly_deleted')));
	} else {
		yield put(createOrUpdateFailed(data));
	}
}

export function* getTimesheets(d) {
	const data = yield call(api.getTimesheets, d.data);
	if (data instanceof Object) {
		yield put(getTimesheetsSuccess(data));
	} else {
		yield put(getTimesheetsFailed(data));
	}
}

export function* getSummerizeTimesheets(d) {
	const data = yield call(api.getTimesheets, d.data);
	if (data instanceof Object) {
		yield put(getSummerizeTimesheetsSuccess(data));
	} else {
		yield put(getSummerizeTimesheetsFailed(data));
	}
}



export default function* TimesheetSagaWatcher() {
	yield takeLatest(TIMESHEET.CREATE_TIMESHEET, createTimesheet);
	yield takeLatest(TIMESHEET.UPDATE_TIMESHEET, updateTimesheet);
	yield takeLatest(TIMESHEET.GET_TIMESHEETS, getTimesheets);
	yield takeLatest(TIMESHEET.DELETE_TIMESHEET, deleteTimesheet);
	yield takeLatest(TIMESHEET.GET_SUMMERIZE_TIMESHEETS, getSummerizeTimesheets);
	yield takeLatest(TIMESHEET.GET_SUMMERIZE_TIMESHEETS_SUCCESS, getSummerizeTimesheetsSuccess);
	yield takeLatest(TIMESHEET.GET_SUMMERIZE_TIMESHEETS_FAILED, getSummerizeTimesheetsFailed);
}
