import { put, call, takeLatest } from 'redux-saga/effects';
import { AUTH } from '../Utils/constants';
import api from '../Api';
import { authFailed, authSuccess } from '../Actions/Auth';

export function* getToken({ data: { username, password } }) {
	const data = yield call(api.getAuthToken, { name: username, password });
	if (data instanceof Object) {
		localStorage.setItem('id_token', data.token);
		localStorage.setItem('user', JSON.stringify(data.user));
		yield put(authSuccess(data));
	} else {
		yield put(authFailed(data));
	}
}

export default function* authSagaWatcher() {
	yield takeLatest(AUTH.AUTH_LOAD, getToken);
	// console.log({ username, password });
	// const task = yield fork(getToken, username, password);
	// console.log(task);
	// const action = yield take([LOGOUT, AUTH.AUTH_FAILED]);
	// if (action.type === LOGOUT) {
	// 	yield cancel(task);
	// 	yield put({ type: DELETE_TOKEN });
	// }
}
