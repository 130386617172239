import React, { Component, Fragment } from 'react';
import { connect } from "react-redux";
import Serivces from '../Pages/Services';
import Consultants from '../Pages/Consultants';
import { Modal } from 'react-bootstrap';
import { withTranslation } from 'react-i18next';
import i18n from '../i18n';
import { getConsultants } from '../Actions/Consultant';
import { getServices } from '../Actions/Service';
import { getUserservices, createUserservice, updateUserservice, deleteUserservice } from '../Actions/Userservice';
import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory from 'react-bootstrap-table2-paginator';
import Alert from 'react-bootstrap/Alert';

class AssignServicesModal extends Component {
    constructor(props) {
        super(props)
        this.state = {
            defaultServiceID: 0,
            selectedServiceID: 0,
            selectedUserID: 0,
            defaultUserID: 0,
            customerID: 0,
            showServiceModel: false,
            showAssigneeModel: false,
            formValues: {
                ID: 0,
                serviceID: 0,
                serviceName: '',
                userID: 0,
                hourlyRate: '',
                customerID: props.customerId,
            },
            formErrors: {
                serviceID: '',
                userID: '',
                hourlyRate: ''
            },
            formValidity: {
                serviceID: '',
                userID: '',
                hourlyRate: ''
            },
            isSubmitting: false,
            message: null,
            isError: false
        }
        this.handleSubmit = this.handleSubmit.bind(this);
        this.handleChange = this.handleChange.bind(this);
        this.resetForm = this.resetForm.bind(this);
        this.openServicesPopup = this.openServicesPopup.bind(this);
        this.openAssigneePopup = this.openAssigneePopup.bind(this);
        this.close = this.close.bind(this);
        this.columns = [
            {
                dataField: 'ID',
                text: 'ID',
                hidden: true,
                sort: true,
            },
            {
                dataField: 'serviceName',
                text: i18n.t('service'),
                sort: true,
            },
            {
                dataField: 'user.name',
                text: i18n.t('consultant'),
                sort: true,
            },
            {
                dataField: 'hourlyRate',
                text: i18n.t('hourly_rate'),
                sort: true,
            },
            {
                dataField: 'actions',
                text: i18n.t('actions'),
                isDummyField: true,
                csvExport: false,
                sort: false,
                formatter: (cell, row) => {
                    return (
                        <Fragment>
                            <button
                                type="button"
                                className="btn btn-primary"
                                onClick={() => this.editUserService(row)}
                            >
                                <i className="fa fa-edit"></i>
                            </button>
                            <button
                                className="btn btn-danger"
                                onClick={() => {
                                    if (window.confirm('Are you sure to delete this service ?'))
                                        this.handleDelete(row.ID);
                                }}
                            >
                                <i className="fa fa-trash"></i>
                            </button>
                        </Fragment>
                    );
                },
            },
        ];
    }

    openServicesPopup() {
        this.setState({ showServiceModel: true });
    };

    openAssigneePopup() {
        this.setState({ showAssigneeModel: true });
    };

    close() {
        this.resetForm();
        this.setState({ showServiceModel: false });
        this.setState({ showAssigneeModel: false });

    };


    componentDidMount() {
        this.props.getServices();
        this.props.getConsultants();
        let customerId = this.props.customerId;
        if (customerId) {
            this.setState({ customerID: customerId });
            this.props.getUserservices(customerId);

        }
    }

    componentDidUpdate() {
        if (this.props.message || this.props.error) {
            this.props.getUserservices(this.props.customerId);
            this.setState({
                isError: !!this.props.error,
                message: this.props.message || this.props.error,
            });
            var that = this;
            setTimeout(function () {
                that.setState({ isError: false, message: null });
            }, 3000);

        }
    }

    handleSubmit(event) {
        event.preventDefault();
        this.setState({ isSubmitting: true });
        const { formValues, formValidity } = this.state;
        if (Object.values(formValidity).every(Boolean)) {
            if (formValues.ID > 0) {
                this.props.updateUserservice(formValues, formValues.ID);
            } else {
                this.props.createUserservice(formValues);
            }
            this.resetForm();
        } else {
            this.setValidation(formValues);
            this.setState({ isSubmitting: false });
        }
    }

    handleChange(e) {

        const target = e.target;
        if (target.type === "select-one") {

            const value = target.value;
            const name = target.name;
            const { formValues } = this.state;
            formValues[name] = value;

            if (name === 'serviceID') {
                formValues['serviceName'] = target[target.selectedIndex].text;
                this.setState({ selectedServiceID: target.value });
            }

            if (name === 'userID') {
                this.setState({ selectedUserID: target.value });
            }

            this.setState({ formValues });
            this.handleValidation(target);
        }
        else {
            const value = target.type = target.value;
            const name = target.name;
            const { formValues } = this.state;
            formValues[name] = value;
            this.setState({ formValues });
            this.handleValidation(target);
        }
    }

    handleValidation = target => {
        const { name, value } = target;
        const fieldValidationErrors = this.state.formErrors;
        const validity = this.state.formValidity;
        validity[name] = value.length > 0;

        switch (name) {
            case "serviceID":
                fieldValidationErrors[name] = value > 0 ? "" : i18n.t("select_a_service");
                break;
            case "userID":
                fieldValidationErrors[name] = value > 0 ? "" : i18n.t("please_select_assignee");
                break;
            case "hourlyRate":
                fieldValidationErrors[name] = validity[name] ? "" : i18n.t("hourly_rate_is_required");
                break;
            default:
                break;
        }

        const numberTest = /^(\d*\.)?\d+$/;
        if (validity[name]) {
            if (name === "hourlyRate" || name === "workingtime") {
                validity[name] = numberTest.test(value);
                fieldValidationErrors[name] = validity[name]
                    ? ""
                    : i18n.t("value_should_be_a_valid_number");
            }
        }

        this.setState({
            formErrors: fieldValidationErrors,
            formValidity: validity
        });
    };

    handleDelete(id) {
        this.props.deleteUserservice(id);
    }

    editUserService(userService) {
        const newValues = {};

        newValues.ID = userService.ID;
        newValues.serviceName = userService.serviceName;
        newValues.serviceID = userService.serviceID;
        newValues.hourlyRate = userService.hourlyRate;

        this.setState({ selectedServiceID: userService.serviceID });
        this.setState({ selectedUserID: userService.userID });
        this.setState({ formValues: newValues });

        const validity = {};
        validity.serviceID = true;
        validity.userID = true;
        validity.hourlyRate = true;
        this.setState({ formValidity: validity });

        window.scrollTo(0, 0);
    }

    setValidation = formValues => {

        for (let key in formValues) {
            let target = {
                name: key,
                value: formValues[key]
            };
            this.handleValidation(target);
        }

    };

    resetForm() {
        this.setState({
            formValues: {
                ID: 0,
                serviceID: 0,
                serviceName: '',
                userID: 0,
                hourlyRate: '',
                customerID: this.props.customerId,
            },
            formErrors: {
                serviceID: '',
                userID: '',
                hourlyRate: ''
            },
            formValidity: {
                serviceID: '',
                userID: '',
                hourlyRate: ''
            },
            selectedServiceID: 0,
            selectedUserID: 0,
            isSubmitting: false
        });
    }

    render() {
        const { formValues, formErrors, isSubmitting } = this.state;
        const { t, services, consultants } = this.props;
        const { message, isError } = this.state;

        return (
            <div className="modal-box" >
                <div className="card">
                    <form className="form-horizontal" onSubmit={this.handleSubmit}>
                        <div className="card-header">
                            <i className="fa fa-edit"></i> {t('assign_edit_services')}
                        </div>
                        <div className="card-body">
                            <div className="row">
                                <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12">

                                </div>
                            </div>
                            <div className="row">
                                <div className="col-lg-7 col-md-6 col-sm-6 col-xs-6">
                                    <div className="">
                                        <label> {t('service')} </label> <span className="required-star">*</span>
                                    </div>
                                    <div className="row">
                                        <div className="col-lg-8 col-md-12 col-sm-12 col-xs-12">
                                            <div className="form-group">
                                                <select value={this.state.selectedServiceID} onChange={this.handleChange}
                                                    className={`form-control form-control-lg ${
                                                        formErrors.serviceID ? "is-invalid" : ""
                                                        }`}
                                                    name="serviceID" title={t('select_a_service')}>
                                                    <option value="0"   >{t('select_a_service')} </option>
                                                    {services ? services.result.map((team) => <option key={team.serviceID} value={team.serviceID}>{team.name} </option>) : ''}
                                                </select>
                                                <div className="invalid-feedback">{formErrors.serviceID}</div>
                                            </div>
                                        </div>
                                        <div className="col-lg-4 col-md-12 col-sm-12 col-xs-12">
                                            <button type="button" onClick={this.openServicesPopup} className="btn btn-success action-btn"> {t('new_service')}</button>
                                            {this.state.showServiceModel ?
                                                <Modal size="lg" show={this.state.showServiceModel} onHide={this.close} animation={false}>
                                                    <div className="services-popup-close-btn"><h4 type="button" onClick={this.close}><i className="fa fa-window-close"></i></h4></div>
                                                    <Modal.Body>
                                                        <Serivces show={true} ></Serivces>
                                                    </Modal.Body>
                                                </Modal >
                                                : null
                                            }
                                        </div>
                                    </div>
                                </div>
                                <div className="col-lg-5 col-md-6 col-sm-6 col-xs-6">
                                    <div className="form-group">
                                        <label>{t('hourly_rate')}</label> <span className="required-star">*</span>
                                        <input
                                            type="text" name="hourlyRate"
                                            className={`form-control form-control-lg ${
                                                formErrors.hourlyRate ? "is-invalid" : ""
                                                }`}
                                            value={formValues.hourlyRate}
                                            onChange={this.handleChange} />
                                        <div className="invalid-feedback">{formErrors.hourlyRate}</div>
                                    </div>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-lg-7 col-md-6 col-sm-6 col-xs-6">
                                    <div className="">
                                        <label>{t('consultants')}</label> <span className="required-star">*</span>
                                    </div>
                                    <div className="row">
                                        <div className="col-lg-8 col-md-12 col-sm-12 col-xs-12">
                                            <div className="form-group">
                                                <select value={this.state.selectedUserID} onChange={this.handleChange}
                                                    className={`form-control form-control-lg ${
                                                        formErrors.userID ? "is-invalid" : ""
                                                        }`}
                                                    name="userID" title={t('select_a_consultants')}>
                                                    <option value="0" >{t('select_a_consultants')}</option>
                                                    {consultants ? consultants.result.map((team) => <option key={team.userID} value={team.userID}>{team.name}</option>) : ""}
                                                </select>
                                                <div className="invalid-feedback">{formErrors.userID}</div>
                                            </div>
                                        </div>
                                        <div className="col-lg-4 col-md-12 col-sm-12 col-xs-12">
                                            <button type="button" onClick={this.openAssigneePopup} className="btn btn-success action-btn">{t('new_consultants')}</button>
                                            {this.state.showAssigneeModel ?
                                                <Modal size="lg" show={this.state.showAssigneeModel} onHide={this.close} animation={false}>
                                                    <div className="services-popup-close-btn"><h4 type="button" onClick={this.close}><i className="fa fa-window-close"></i></h4></div>
                                                    <Modal.Body>
                                                        <Consultants show={true} ></Consultants>
                                                    </Modal.Body>
                                                </Modal >
                                                : null
                                            }
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="card-footer">
                            {isSubmitting ? (
                                <button type="button" className="btn btn-success loading" disabled>
                                    <i className="fas fa-sync-alt fa-spin"></i>
                                </button>
                            ) : (
                                    <span>
                                        <button type="submit" className="btn btn-success"><i className="far fa-dot-circle"></i>  {isSubmitting ? t('please_wait') : t('save')}</button>
                                        <button type="button" onClick={this.resetForm} className="btn btn-info" ><i className="fa fa-times"></i> {t('clear')}</button>
                                    </span>
                                )}
                        </div>
                    </form>
                </div>
                <div>
                    {message && (
                        <Alert
                            variant={isError ? 'danger' : 'success'}
                            dismissible
                            onClose={() => this.setState({ isError: false, message: null })}
                        >
                            {message}
                        </Alert>
                    )}
                    <h5>{t('assigned_services')} </h5>
                    {
                        <BootstrapTable
                            keyField="ID"
                            data={this.props.userServices ? this.props.userServices.result : []}
                            columns={this.columns}
                            pagination={paginationFactory()}
                            striped
                            hover
                            condensed
                        />
                    }
                </div>
            </div>

        )
    }
}

function mapStateToProps(globalState) {
    return {
        userServices: globalState.userServices.userservices,
        services: globalState.services.services,
        consultants: globalState.consultants.consultants,
        error: globalState.userServices.error,
        loading: globalState.userServices.loading,
        message: globalState.userServices.message,
    };
}

function mapDispatchToProps(dispatch) {
    return {
        getUserservices: (id) => dispatch(getUserservices(id)),
        createUserservice: (formValues) => dispatch(createUserservice(formValues)),
        updateUserservice: (formValues) => dispatch(updateUserservice(formValues)),
        deleteUserservice: (id) => dispatch(deleteUserservice(id)),
        getServices: () => dispatch(getServices()),
        getConsultants: () => dispatch(getConsultants())
    };
}
export default withTranslation('translation', { withRef: true })(connect(mapStateToProps, mapDispatchToProps)(AssignServicesModal));

