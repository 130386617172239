import React, { Component } from 'react';
import { Modal } from 'react-bootstrap';
import './../Styles/main.scss';
import Moment from 'moment';
import { Table } from 'react-bootstrap';
import { withTranslation } from 'react-i18next';
import { connect } from "react-redux";
import { getInvoice, sendInvoice, updateInvoice } from '../Actions/Invoice';
import { getCustomer } from '../Actions/Customer';
import { saveAs } from 'file-saver';
import { BASE_URL, COMPANY_NAME } from '../Utils/constants';
import Alert from 'react-bootstrap/Alert';


class InvoiceModal extends Component {
    constructor(props) {
        super(props);
        this.state = {
            showModal: this.props.show,
            invoice: props.selectedInvoice,
            invoiceDate: new Date(),
            message: null,
            isError: false

        };
        this.downloadWordDoc = this.downloadWordDoc.bind(this);
        this.sendEmailToCustomer = this.sendEmailToCustomer.bind(this);
        this.confirmInvoice = this.confirmInvoice.bind(this);
        this.cancelInvoice = this.cancelInvoice.bind(this);

    }

    getInvoiceDate() {
        var tempDate = new Date();
        let formatTwoDigits = (digit) => ("0" + digit).slice(-2);
        var date = tempDate.getFullYear() + '-' + formatTwoDigits(tempDate.getMonth() + 1) + '-' + formatTwoDigits(tempDate.getDate());
        const currDate = date;
        return (
            <p><b>Date :</b>{currDate}</p>
        );
    }

    componentDidMount() {
        const { childRef3 } = this.props;
        childRef3(this);
        if (this.props.selectedInvoice) {
            let customerId = this.props.selectedInvoice.customerID;
            if (customerId > 0) {
                this.props.getCustomer(customerId);
            }
        }
    }

    componentWillUnmount() {
        const { childRef3 } = this.props;
        childRef3(undefined);
    }


    rendertimeRecords(timeRecords) {
        return timeRecords.map((timeRecord, index) => {
            return (
                <tr key={index}>
                    <td>{timeRecord.serviceName}</td>
                    <td>{Moment(timeRecord.workAt).format('YYYY-MM-DD')}</td>
                    <td>{timeRecord.hourlyRate}</td>
                    <td>{timeRecord.hours}</td>
                    <td>{timeRecord.amount.toFixed(2)}</td>
                </tr>
            );
        })
    }


    renderExpences(invoices) {
        return invoices.map((expence, index) => {
            return (
                <tr key={index}>
                    <td>{Moment(expence.expenseAt).format('YYYY-MM-DD')}</td>
                    <td>{expence.description}</td>
                    <td>{expence.amount.toFixed(2)}</td>
                </tr>
            );
        })
    }

    downloadWordDoc() {
        if (typeof window !== 'undefined') {
            var id = this.props.selectedInvoice.invoiceNo;
            var url = BASE_URL + "/invoices/download/" + id;
            try {
                saveAs(url + "?token=" + localStorage.getItem('id_token'), id + ".pdf");
                
            }
            catch (error) {
debugger
            }
        } else {

        }
    }

    sendEmailToCustomer() {
        var id = this.props.selectedInvoice.invoiceID;
        this.props.sendInvoice(id);
    }

    componentDidUpdate() {
        if (this.props.message || this.props.error) {
            this.setState({
                isError: !!this.props.error,
                message: this.props.message || this.props.error,
            });
            var that = this;
			setTimeout(function () {
				that.setState({ isError: false, message: null });
			}, 3000);
        }
    }

    cancelInvoice() {
        let data = this.props.selectedInvoice;
        data.record_status = 0;
        this.props.updateInvoice(data);
    }

    confirmInvoice() {
        let data = this.props.selectedInvoice;
        data.record_status = 2;
        this.props.updateInvoice(data);
    }

    render() {
        const { t } = this.props;
        const invoice = this.props.selectedInvoice;
        const timeLogList = this.props.selectedInvoice ? this.props.selectedInvoice.invoice_lines.filter(invoiceLine => invoiceLine.objecttype === "timelog") : "";
        const expenses = this.props.selectedInvoice ? this.props.selectedInvoice.invoice_lines.filter(invoiceLine => invoiceLine.objecttype === "expense") : "";
        const customer = this.props.customer;
        const { message, isError } = this.state;

        return (
            <div>
                <Modal size="lg" show={this.state.showModal} onHide={this.props.close} animation={false}>
                    <Modal.Body>
                        <div className="row">
                            <div className="col-lg-1 col-md-1 col-xs-1"></div>
                            <div className="col-lg-10 col-md-10 col-xs-10" style={{ textAlign: "center" }}>
                                {invoice && invoice.record_status === 1 ? <button onClick={this.cancelInvoice} className="btn btn-info col-xs-6" style={{ marginRight: 10 }}><i className="fa fa-ban"></i> {t('cancel')}</button> : ""}
                                {invoice && invoice.record_status === 1 ? <button onClick={this.confirmInvoice} className="btn btn-success col-xs-6" style={{ marginRight: 10 }}><i className="far fa-check-circle"></i> {t('confirm_invoice')}</button> : ""}
                                <button onClick={this.downloadWordDoc} className="btn btn-success col-xs-6" style={{ marginRight: 10 }}><i className="fa fa-download"></i> {t('download')}</button>
                                {invoice && invoice.record_status === 2 ? <button onClick={this.sendEmailToCustomer} className="btn btn-success col-xs-6" style={{ marginRight: 10 }}><i className="fa fa-envelope"></i> {t('send')}</button> : ""}
                            </div>
                            <div className="col-lg-1 col-md-1 col-xs-1" style={{ textAlign: "right" }}>
                                <h4 onClick={this.props.close} style={{ cursor: "pointer" }}><i className="fa fa-window-close"></i></h4>
                            </div>
                            {/* <div className="col-md-4" >
                                <div style={{ textAlign: "right", display: "inline-flex", marginLeft: "auto" }}>
                                    <h4 onClick={this.sendEmailToCustomer} style={{ cursor: "pointer" }} ><i className="fa fa-share"></i></h4>
                                    <h4 onClick={this.downloadWordDoc} style={{ margin: "0px 15px", cursor: "pointer" }}><i className="fas fa-print"></i></h4>
                                    <h4 onClick={this.props.close}><i className="fa fa-window-close"></i></h4>
                                </div>
                            </div> */}
                        </div>
                        <br></br>
                        <div className="row">
                            <div className="col-md-12">
                                {message && (
                                    <Alert
                                        variant={isError ? 'danger' : 'success'}
                                        dismissible
                                        onClose={() => this.setState({ isError: false, message: null })}
                                    >
                                        {message}
                                    </Alert>
                                )}
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-md-7">
                                <h2>{COMPANY_NAME}</h2>
                            </div>
                            <div className="col-md-5">
                                <div className="row">
                                    <div className="col-md-7">
                                        <h2>{t('invoice')} </h2>
                                    </div>
                                    <div className="col-md-5">
                                        {/* <div style={{ textAlign: "right", display: "inline-flex", marginLeft: "auto" }}>
                                            <h4 onClick={this.sendEmailToCustomer} style={{ cursor: "pointer" }} ><i className="fa fa-share"></i></h4>
                                            <h4 onClick={this.downloadWordDoc} style={{ margin: "0px 15px", cursor: "pointer" }}><i className="fas fa-print"></i></h4>
                                            <h4 onClick={this.props.close}><i className="fa fa-window-close"></i></h4>
                                        </div> */}
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-md-7">
                                {this.props.customer ?
                                    <>
                                        <p><b>{t('customer_name')}  :</b> <span style={{ color: "#348ceb" }}>
                                            {customer.name}</span></p>
                                        <p><b>{t('email')} : </b>{customer.email}</p>
                                        <p><b>{t('address')}  : </b>{customer.address} </p>
                                        <p><b>{t('telephone')}  :</b>{customer.telephone} </p></>

                                    : null
                                }
                            </div>
                            <div className="col-md-5">
                                <span style={{ display: "inline-flex" }}><p><b>{t('invoice_no')} :</b> </p><p style={{ color: "#348ceb", fontWeight: "bold" }}>{invoice.invoiceNo}</p></span>
                                <p><b>{t('vat_number')} :</b> {customer?.VATNo}</p>
                                <p><b>{t('date')}  :</b>{Moment(invoice.invioceAt).format('YYYY-MM-DD')} </p>
                            </div>
                            <div className="col-md-12">
                                {timeLogList && timeLogList.length > 0 ?
                                    <div>
                                        <h5>{t('timesheets_details')}</h5>
                                        <Table striped bordered hover className="table table-responsive-lg">
                                            <thead>
                                                <tr>
                                                    <th>{t("service")}</th>
                                                    <th>{t("worked_date")}</th>
                                                    <th>{t("hourly_rate")} (SEK)</th>
                                                    <th>{t("time")} (h)</th>
                                                    <th>{t("total")}</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {timeLogList ? this.rendertimeRecords(timeLogList) : null}
                                            </tbody>
                                        </Table>
                                    </div> : ""
                                }
                            </div>
                            <div className="col-md-12">
                                {expenses && expenses.length > 0 ?
                                    <div>
                                        <h5>{t('expense_details')}</h5>
                                        <Table striped bordered hover className="table table-responsive-lg">
                                            <thead>
                                                <tr>
                                                    <th>{t('date')}</th>
                                                    <th>{t('description')}</th>
                                                    <th>{t('amount')} (SEK)</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {expenses ? this.renderExpences(expenses) : null}
                                            </tbody>
                                        </Table>
                                    </div>
                                    : ""}
                            </div>
                            <div className="col-md-6" style={{ marginTop: "auto" }}></div>
                            <div className="col-md-7 offset-md-4 prices">
                                <p><b>{t('total')}      :</b> <span>{invoice.totalServiceFee?.toFixed(2)}</span></p>
                                <p><b>{t('vat')}        : </b><span> {invoice.vat?.toFixed(2)}</span></p>
                                <p><b>{t('expences')}   : </b> {invoice.totalExpense?.toFixed(2)}</p>
                                <p><b>{t('grand_total')}:</b> {invoice.totalAmount?.toFixed(2)}</p>
                            </div>
                        </div>

                    </Modal.Body>
                </Modal>
            </div>
        );
    }
};

function mapStateToProps(globalState) {
    return {
        consultants: globalState.consultants.consultants,
        customer: globalState.customers.customer,
        invoice: globalState.invoices.data,
        error: globalState.invoices.error,
        loading: globalState.invoices.loading,
        message: globalState.invoices.message,
    };
}

function mapDispatchToProps(dispatch) {
    return {
        updateInvoice: (invoice) => dispatch(updateInvoice(invoice)),
        getInvoice: (id) => dispatch(getInvoice(id)),
        getCustomer: (id) => dispatch(getCustomer(id)),
        sendInvoice: (id) => dispatch(sendInvoice(id))
    };
}

export default withTranslation('translation', { withRef: true })(
    connect(mapStateToProps, mapDispatchToProps)(InvoiceModal)
);

