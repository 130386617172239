import { USERSERVICE } from '../Utils/constants';

const initialState = {
	userservices: null,
	error: null,
	loading: false,
	message: null,
};

export default (state = initialState, action) => {
	switch (action.type) {
		case USERSERVICE.CREATE_USERSERVICE:
		case USERSERVICE.UPDATE_USERSERVICE:
		case USERSERVICE.GET_USERSERVICES:
			return {
				...state,
				loading: true,
				error: null,
				message: null,
			};
		case USERSERVICE.UPDATE_USERSERVICE_SUCCESS:
			return {
				...state,
				loading: false,
				message: action.message,
				error: null,
			};
		case USERSERVICE.UPDATE_USERSERVICES_FAILED:
		case USERSERVICE.GET_USERSERVICES_FAILED:
			return {
				...state,
				error: action.error,
				loading: false,
				message: null,
			};
		case USERSERVICE.GET_USERSERVICES_SUCCESS:
			return {
				...state,
				userservices: action.userservices,
				loading: false,
				error: null,
				message: null,
			};
		default:
			return state;
	}
};
