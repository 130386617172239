import React, { Component } from "react";
import '../Styles/main.scss';
import i18n from '../i18n';
import LanguagePicker from "../Components/LanguagePicker";
import { connect } from "react-redux";
import { setPassword } from '../Actions/Password';
import Alert from 'react-bootstrap/Alert';
import { Link } from "react-router-dom";

class SetPassword extends Component {
	constructor(props) {
		super(props);
		this.state = {
			formValues: {
				password: "",
				confirmPassword: ""
			},
			formErrors: {
				password: "",
				confirmPassword: ""
			},
			formValidity: {
				password: false,
				confirmPassword: false
			},
			login: props.login,
			isSubmitting: false,
			isError: false,
			message: ""

		};
		this.handleSubmit = this.handleSubmit.bind(this);
		this.handleChange = this.handleChange.bind(this);
	}

	handleChange = ({ target }) => {
		const { formValues } = this.state;
		formValues[target.name] = target.value;
		this.setState({ formValues });
		this.handleValidation(target);
	};

	handleValidation = target => {
		const { name, value } = target;
		const fieldValidationErrors = this.state.formErrors;
		const validity = this.state.formValidity;
		const isPassword = name === "password";
		const isConfirmPassword = name === "confirmPassword";
		const { password, confirmPassword } = this.state.formValues;

		validity[name] = value.length > 0;
		if (validity[name]) {

			if (isPassword) {
				validity[name] = value.length >= 6;
				fieldValidationErrors[name] = validity[name]
					? ""
					: i18n.t("minimum_password_characters");
			}
			if (isConfirmPassword) {
				validity[name] = value.length >= 6;
				if (fieldValidationErrors[name] = validity[name]) {
					if (password !== confirmPassword) {
						fieldValidationErrors[name] = i18n.t("password_confirm_password_should_be_matched");
					}
					else {
						fieldValidationErrors[name] = "";
					}
				}
				else {
					fieldValidationErrors[name] = i18n.t("password_confirm_password_should_be_matched");
				}
			}
		}
		this.setState({
			formErrors: fieldValidationErrors,
			formValidity: validity
		});
	};

	componentDidUpdate() {
		if ((this.props.message || this.props.error) && this.state.msgValue === true) {
			this.setState({
				isError: !!this.props.error,
				message: this.props.message || this.props.error,
				msgValue: false
			});

			if (this.props.message === i18n.t('successfuly_updated')) {
				this.setState({
					message: i18n.t('your_password_sucessfully_updated'),
				});
			}
			if (this.props.isError) {
				this.setState({
					message: i18n.t('your_password_not_updated_sucessfully'),
				});
			}
			this.resetForm();
			var that = this;
			setTimeout(function () {
				that.setState({ isError: false, message: null });
			}, 5000);
		}
	}

	handleSubmit(e) {
		e.preventDefault();
		this.setState({ isSubmitting: true });
		const { formValues, formValidity } = this.state;
		if (Object.values(formValidity).every(Boolean)) {
			if (this.props.match.params.token !== "") {
				this.setState({ isSubmitting: false, msgValue: true });
				this.props.setPassword(formValues, this.props.match.params.token);
			}
		} else {
			for (let key in formValues) {
				let target = {
					name: key,
					value: formValues[key]
				};
				this.handleValidation(target);
			}
			this.setState({ isSubmitting: false });
		}
	};

	resetForm() {
		this.setState({
			formValues: {
				password: "",
				confirmPassword: ""
			},
			formErrors: {
				password: "",
				confirmPassword: ""
			},
			formValidity: {
				password: false,
				confirmPassword: false
			},
			isSubmitting: false,
		});
	}


	changeLanguage = lng => {
		i18n.changeLanguage(lng);
	};
	render() {
		const { t } = this.props;
		const { formValues, formErrors, isSubmitting, isError, message } = this.state;
		return (
			<div className="signin-body-dark">
				<div className="container">
					<div className="wr-log" style={{ marginBottom: '50px' }}>
						<div className="form-signin effect form-group">
							<div className="row">
								<div className="col-lg-12 text-center">
									<LanguagePicker ></LanguagePicker>
								</div>
							</div>
							<div className="row">
								<div className="col-lg-12 text-center">
									<h3 className="mt-4">{t('set_password')}</h3>
								</div>
							</div>
							<div className="row">
								<div className="col-lg-12">
									<form onSubmit={this.handleSubmit} >
										<div className="form-group">
											<label>{t('password')}</label>
											<input
												type="password"
												name="password"
												className={`form-control ${
													formErrors.password ? "is-invalid" : ""
													}`}
												onChange={this.handleChange}
												value={formValues.password}
											/>
											<div className="invalid-feedback">{formErrors.password}</div>
										</div>
										<div className="form-group">
											<label>{t('confirm_password')}</label>
											<input
												type="password"
												name="confirmPassword"
												className={`form-control ${
													formErrors.confirmPassword ? "is-invalid" : ""
													}`}
												onChange={this.handleChange}
												value={formValues.confirmPassword}
											/>
											<div className="invalid-feedback">{formErrors.confirmPassword}</div>
										</div>
										<button
											type="submit"
											className="btn btn-primary btn-block"
											disabled={isSubmitting || !formValues.password && !formValues.confirmPassword}
										>
											{isSubmitting ? "Please wait..." : t('submit')}
										</button>
										<br></br>
										<br></br>
										{(isError || message) && (
											<div className="form-group">
												<Alert
													variant={isError ? 'danger' : 'success'}
													dismissible
													onClose={() => this.setState({ isError: false, message: "" })}
												>
													{message}
												</Alert>

											</div>
										)}
										{ !isError ? 
										<div className="form-group text-center">
											<Link to={`/`}> {t('login_from_here')} </Link>
										</div>
										: "" }
									</form>
								</div>
							</div>
						</div>
					</div>
				</div>
				<div>
					<div id="versionBar">
						<div className="copyright">
							©2020 Crowderia. All Rights Reserved - V.0.1</div>
					</div>
				</div>
			</div>
		);

	}
}
function mapStateToProps(globalState) {
	return {
		error: globalState.password.error,
		loading: globalState.password.loading,
		message: globalState.password.message
	};
}

function mapDispatchToProps(dispatch) {
	return {
		setPassword: (data, token) => dispatch(setPassword(data, token)),
	};
}

export default connect(mapStateToProps, mapDispatchToProps)(SetPassword);