import { INVOICE } from '../Utils/constants';

const initialState = {
	invoices: null,
	error: null,
	loading: false,
	message: null,
	data: null,
	outstandingsummary: null
};

export default (state = initialState, action) => {
	switch (action.type) {
		case INVOICE.CREATE_INVOICE:
		case INVOICE.UPDATE_INVOICE:
		case INVOICE.GET_INVOICES:
			return {
				...state,
				loading: true,
				error: null,
				message: null,
			};
		case INVOICE.UPDATE_INVOICE_SUCCESS:
			return {
				...state,
				loading: false,
				message: action.message,
				error: null,
				data: action.data
			};
		case INVOICE.UPDATE_INVOICES_FAILED:
		case INVOICE.GET_INVOICES_FAILED:
			return {
				...state,
				error: action.error,
				loading: false,
				message: null,
			};
		case INVOICE.GET_INVOICES_SUCCESS:
			return {
				...state,
				invoices: action.invoices,
				loading: false,
				error: null,
				message: null,
			};
		case INVOICE.SEND_INVOICE:
		case INVOICE.SEND_INVOICE_SUCCESS:
			return {
				...state,
				loading: false,
				error: null,
				message: action.data,
			};
		case INVOICE.SEND_INVOICE_FAILED:
			return {
				...state,
				loading: false,
				error: action.data.error,
				message: null,
			};
		case INVOICE.GET_OUTSTANDING_SUMMERY:
		return {
			...state,
			loading: true,
			error: null,
			message: null,
		};
		case INVOICE.GET_OUTSTANDING_SUMMERY_FAILED:
			return {
				...state,
				error: action.error,
				loading: false,
				message: null,
			};
		case INVOICE.GET_OUTSTANDING_SUMMERY_SUCCESS:
			return {
				...state,
				outstandingsummary: action.data,
				loading: false,
				error: null,
				message: null,
			};
		default:
			return state;
	}
};
