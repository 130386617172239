import { CUSTOMER } from '../Utils/constants';

const initialState = {
	customers: null,
	error: null,
	loading: false,
	message: null,
};

export default (state = initialState, action) => {
	switch (action.type) {
		case CUSTOMER.CREATE_CUSTOMER:
		case CUSTOMER.UPDATE_CUSTOMER:
		case CUSTOMER.GET_CUSTOMERS:
			return {
				...state,
				loading: true,
				error: null,
				message: null,
			};
		case CUSTOMER.UPDATE_CUSTOMER_SUCCESS:
			return {
				...state,
				loading: false,
				message: action.message,
				error: null,
			};
		case CUSTOMER.UPDATE_CUSTOMERS_FAILED:
		case CUSTOMER.GET_CUSTOMERS_FAILED:
			return {
				...state,
				error: action.error,
				loading: false,
				message: null,
			};
		case CUSTOMER.GET_CUSTOMERS_SUCCESS:
			return {
				...state,
				customers: action.customers,
				loading: false,
				error: null,
				message: null,
			};
		default:
			return state;
	}
};
