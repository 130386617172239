import React, { Component } from 'react';
import { Modal, Button } from 'react-bootstrap';
import './../Styles/main.scss';
import Table from 'react-bootstrap/Table';
import { withTranslation } from 'react-i18next';
import { connect } from "react-redux";
import { getSummerizeExpenses } from '../Actions/Expense';
import { getSummerizeTimesheets } from '../Actions/Timesheet';

class SummaryModal extends Component {
    constructor(props) {
        super(props);
        this.state = {
            showModal: false,
            totalServiceHours: 0,
            customerId: props.customerId,
            timeSheetsSummary: props.timeSheetsSummary,
            expensessummary: props.expensessummary

        }
        this.getInitialState = this.getInitialState.bind(this);
        this.fetchSummaryData = this.fetchSummaryData.bind(this);
        this.close = this.close.bind(this);
        this.open = this.open.bind(this);
    }

    getInitialState() {
        this.showModal = false
    };

    close() {
        this.props.getSummerizeTimesheets('?customerID=' + this.props.customerId);
        this.props.getSummerizeExpenses( "?total=amount&customerID=" + this.props.customerId);
        this.setState({ showModal: false });

    };

    open() {
        this.fetchSummaryData();
    };

    fetchSummaryData() {
        this.props.getSummerizeTimesheets('?customerID=' + this.props.customerId + "&groupby=serviceID");
        this.props.getSummerizeExpenses( "?total=amount&customerID=" + this.props.customerId);
        this.setState({ showModal: true });
    }

    renderTimesheetRows(timesheets) {
        return timesheets.map((summaryData, index) => {
            return (
                <tr key={index}>
                    <td>{summaryData.serviceName}</td>
                    <td className="right-align">{summaryData.hourlyRate}</td>
                    <td className="right-align">{summaryData.totalHours}</td>
                </tr>
            );
        })
    }
    renderTotalHours(timesheets) {
        let totalHours = 0;
        const { t } = this.props;
        timesheets.map((summaryData, index) => {
            totalHours = totalHours + summaryData.totalHours * summaryData.hourlyRate;
        });
        return (
            <tr>
                <th>{t('total')}</th>
                <th className="right-align">{totalHours >= 0 ? totalHours : ""}</th>
            </tr>
        );
    }

    renderTotalExpencesHours(summaryData) {
        const { t } = this.props;
        return (
            <tr key={summaryData.id} >
                <th>{t('total_expences')} </th>
                <th className="right-align">{summaryData.ExpensesTotalAmount ? summaryData.ExpensesTotalAmount.toFixed(2) :0 }</th>
            </tr>
        );
    }

    render() {
        const { t } = this.props;
        const timeSheetsSummary = this.props.timesheets;
        const expensessummary = this.props.expenses;

        return (
            <div>

                <button disabled={this.props.customerId <= 0} onClick={this.open} className="btn btn-secondary" style={{ marginRight: 10 }}><i className="far fa-dot-circle"></i> {t('summary')}</button>

                <Modal size="md" show={this.state.showModal} onHide={this.close} animation={false} >
                    <br></br>
                    <div className="row">
                        <div className="col-lg-12 col-md-12 col-sm-12">
                            <div className="sm-title"><h4>{t('summary')} -  {this.props.customerName}</h4>
                            </div>
                        </div>
                    </div>
                    <Modal.Body>
                        <Table striped bordered hover>
                            <thead>
                                <tr>
                                    <th>{t('service')}</th>
                                    <th>{t('fee')}(SEK)</th>
                                    <th>{t('hours')}(h)</th>
                                </tr>
                            </thead>
                            <tbody>
                                { timeSheetsSummary ? this.renderTimesheetRows(timeSheetsSummary.result): null}
                                { timeSheetsSummary ? this.renderTotalHours(timeSheetsSummary.result): null}
                                { expensessummary ? this.renderTotalExpencesHours(expensessummary.result) : null}
                            </tbody>
                        </Table>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button onClick={this.close}>{t('close')}</Button>
                    </Modal.Footer>
                </Modal>
            </div>

        );
    }
};

function mapStateToProps(globalState) {
    return {
        timesheets  : globalState.timesheets.summerizeTimeSheets,
        expenses: globalState.expenses.summerizeExpenses,
    };
}

function mapDispatchToProps(dispatch) {
	return {
        getSummerizeTimesheets  : (data) => dispatch(getSummerizeTimesheets(data)),
        getSummerizeExpenses: (data) => dispatch(getSummerizeExpenses(data))
	};
}

export default withTranslation('translation', { withRef: true })(connect(mapStateToProps, mapDispatchToProps)(SummaryModal));
