import React, { Component } from "react";
import '../Styles/main.scss';
import i18n from '../i18n';
import { connect } from "react-redux";
import { resetPassword } from '../Actions/Password';
import Alert from 'react-bootstrap/Alert';

class ResetPassword extends Component {
	constructor(props) {
		super(props);
		this.state = {
			formValues: {
				password: "",
				confirmPassword: ""
			},
			formErrors: {
				password: "",
				confirmPassword: ""
			},
			formValidity: {
				password: false,
				confirmPassword: false
			},
			isSubmitting: false,
			isError: false,
			message: "",
			msgValue: false
		};
		this.handleSubmit = this.handleSubmit.bind(this);
		this.handleChange = this.handleChange.bind(this);
		this.resetForm = this.resetForm.bind(this);
	}

	handleChange = ({ target }) => {
		const { formValues } = this.state;
		formValues[target.name] = target.value;
		this.setState({ formValues });
		this.handleValidation(target);
	};

	handleValidation = target => {
		const { name, value } = target;
		const fieldValidationErrors = this.state.formErrors;
		const { password, confirmPassword } = this.state.formValues;
		const validity = this.state.formValidity;
		const isPassword = name === "password";
		const isConfirmPassword = name === "confirmPassword";

		validity[name] = value.length > 0;

		switch (name) {
			case "password":
				fieldValidationErrors[name] = validity[name] ? "" : i18n.t("password_is_required");
				break;
			case "confirmPassword":
				fieldValidationErrors[name] = validity[name] ? "" : i18n.t("confirm_password_is_required");
				break;
			default:
				break;
		}

		if (validity[name]) {
			if (isPassword) {
				validity[name] = value.length >= 6;
				fieldValidationErrors[name] = validity[name]
					? ""
					: i18n.t("minimum_password_characters");
			}
			if (isConfirmPassword) {
				validity[name] = value.length >= 6;
				if (fieldValidationErrors[name] === validity[name]) {
					if (password !== confirmPassword) {
						fieldValidationErrors[name] = i18n.t("password_confirm_password_should_be_matched");
						validity[name] = false;
					}
					else {
						fieldValidationErrors[name] = "";
					}
				}
				else {
					validity[name] = false;
					fieldValidationErrors[name] = i18n.t("password_confirm_password_should_be_matched");
				}
			}
		}

		this.setState({
			formErrors: fieldValidationErrors,
			formValidity: validity
		});
	};


	componentDidUpdate(props, state) {
		if ((this.props.message || this.props.error) && this.state.msgValue === true) {
			this.setState({
				isError: !!this.props.error,
				message: this.props.message || this.props.error,
				msgValue: false
			});
			var that = this;
			setTimeout(function () {
				that.setState({ isError: false, message: null });
			}, 3000);
			this.resetForm();
		}
	}


	handleSubmit(e) {
		e.preventDefault();
		const { formValues, formValidity } = this.state;
		if (Object.values(formValidity).every(Boolean)) {
			this.setState({ isSubmitting: true, msgValue: true });
			this.props.resetPassword(formValues);
			this.setState({ isSubmitting: false });
		} else {
			for (let key in formValues) {
				let target = {
					name: key,
					value: formValues[key]
				};
				if (Object.keys(formValidity).includes(key)) {
					this.handleValidation(target);
				}
			}
			this.setState({ isSubmitting: false });
		}
	};

	resetForm() {
		this.setState({
			formValues: {
				password: "",
				confirmPassword: ""
			},
			formErrors: {
				password: "",
				confirmPassword: ""
			},
			formValidity: {
				password: false,
				confirmPassword: false
			},
			isSubmitting: false,
			msgValue: false
		});
	}

	changeLanguage = lng => {
		i18n.changeLanguage(lng);
	};

	render() {
		const { t, error } = this.props;
		const { formValues, formErrors, isSubmitting, message, isError } = this.state;
		return (
			<div>
				<h1>{t('reset_password')}</h1>
				<div className="card">
					<form className="form-horizontal" onSubmit={this.handleSubmit}>
						<div className="card-header">
							<i className="fa fa-edit"></i>{t('reset_password')}
						</div>

						<div className="card-body">
							<div className="row">
								<div className="col-lg-6">
									{(isError || message) && (
										<Alert
											variant={error ? 'danger' : 'success'}
											dismissible
											onClose={() => this.setState({ isError: false, message: null })}
										>
											{message}
										</Alert>
									)}
								</div>
							</div>
							<div className="row">
								<div className="col-lg-6">
									<div className="form-group">
										<label>{t('password')}</label>
										<input
											type="password"
											name="password"
											className={`form-control ${
												formErrors.password ? "is-invalid" : ""
												}`}
											onChange={this.handleChange}
											value={formValues.password}
										/>
										<div className="invalid-feedback">{formErrors.password}</div>
									</div>
									<div className="form-group">
										<label>{t('confirm_password')}</label>
										<input
											type="password"
											name="confirmPassword"
											className={`form-control ${
												formErrors.confirmPassword ? "is-invalid" : ""
												}`}
											onChange={this.handleChange}
											value={formValues.confirmPassword}
										/>
										<div className="invalid-feedback">{formErrors.confirmPassword}</div>
									</div>
								</div>
							</div>
						</div>
						<div className="card-footer">
							{isSubmitting ? (
								<button type="button" className="btn btn-success loading" disabled>
									<i className="fas fa-sync-alt fa-spin"></i>
								</button>
							) : (
									<span>
										<button type="submit" className="btn btn-success" disabled={isSubmitting}><i className="far fa-dot-circle"></i> {isSubmitting ? t('please_wait') : t('save')}</button>
										<button type="button" onClick={this.resetForm} className="btn btn-info" ><i className="fa fa-times"></i> {t('clear')}</button>
									</span>
								)}
						</div>
					</form>
				</div>
			</div>
		);

	}
}
function mapStateToProps(globalState) {
	return {
		error: globalState.password.error,
		loading: globalState.password.loading,
		message: globalState.password.message
	};
}

function mapDispatchToProps(dispatch) {
	return {
		resetPassword: (data) => dispatch(resetPassword(data)),
	};
}

export default connect(mapStateToProps, mapDispatchToProps)(ResetPassword);