import React, { Component } from 'react';
import { Modal, Button } from 'react-bootstrap';
import './../Styles/main.scss';
import Table from 'react-bootstrap/Table';
import { withTranslation } from 'react-i18next';
import { connect } from "react-redux";
import { getOutstandingTimesheetsExpensesSummary } from '../Actions/Customer';

import Moment from 'moment';

class OverallSummary extends Component {
    constructor(props) {
        super(props);
        this.state = {
            showModal: false,
            totalServiceHours: 0,
            outstandingsummary: props.outstandingsummary,
            selectedPeriod:1,
            totalFee : 0,
            totalExpenses:0
        }
        this.getInitialState = this.getInitialState.bind(this);
        this.fetchSummaryData = this.fetchSummaryData.bind(this);
        this.close = this.close.bind(this);
        this.open = this.open.bind(this);
        this.onChangePeriod = this.onChangePeriod.bind(this);
        this.getToday = this.getToday.bind(this);

    }

    getInitialState() {
        this.showModal = false
    };

    close() {
        this.setState({ showModal: false });
    };

    open() {
        this.fetchSummaryData();
        this.getToday();
    };

    fetchSummaryData() {
        this.props.getOutstandingTimesheetsExpensesSummary('from='+ this.getStartDate(30) +'&to='+this.getToday());
        this.setState({ showModal: true });
    }
 
    getStartDate(noOfDays){
        if(noOfDays === "") {
            noOfDays = 30;
        }
        var startdate = Moment();
        startdate = startdate.subtract(noOfDays, "days");
        startdate = startdate.format("YYYY-MM-DD");
        return startdate;
    }

    onChangePeriod(e) {
        const target = e.target;
        const value = target.value;
        this.selectedPeriod = value;
        var numberOfDays = 30;  
        if(this.selectedPeriod === "1"){
            numberOfDays = 30;
        }
        else if(this.selectedPeriod === "2"){
            numberOfDays = 60;
        }
        else if(this.selectedPeriod === "3") {
            numberOfDays = 90;
        }
        else if(this.selectedPeriod === "4") {
            numberOfDays = 10000;
        }
        this.props.getOutstandingTimesheetsExpensesSummary('from='+ this.getStartDate(numberOfDays) +'&to='+this.getToday());
    }

    getToday(){
        var today = new Date();
        var dd = String(today.getDate()).padStart(2, '0');
        var mm = String(today.getMonth() + 1).padStart(2, '0'); //January is 0!
        var yyyy = today.getFullYear();
        var todate =  yyyy + '-'+ mm + '-' + dd;
        return todate;
    }

    renderCustomerOutstandingRows(customerOutstanding) {
        return customerOutstanding.map((summaryData, index) => {
            return (
                <tr key={index}>
                    <td>{summaryData.customer_name}</td>
                    <td className="right-align">{summaryData.outstanding_summary.find(obj => { return obj.timelog === "expense" })? summaryData.outstanding_summary.find(obj => { return obj.timelog === "expense" }).amount : 0}</td>
                    <td className="right-align">{  summaryData.outstanding_summary.find(obj => { return obj.timelog === "timelog" })? summaryData.outstanding_summary.find(obj => { return obj.timelog === "timelog" }).amount : 0}</td>
                </tr>
            );
        })
    }

    renderTotalOutstanding(customerOutstanding) {
        var totalFee =0;
        var totalExpenses =0;
        const { t } = this.props;

        customerOutstanding.map((summaryData, index) => {
            totalExpenses += summaryData.outstanding_summary.find(obj => { return obj.timelog === "expense" })? summaryData.outstanding_summary.find(obj => { return obj.timelog === "expense" }).amount : 0;
            totalFee += summaryData.outstanding_summary.find(obj => { return obj.timelog === "timelog" })? summaryData.outstanding_summary.find(obj => { return obj.timelog === "timelog" }).amount : 0;
        });
    { return (<>
        <tr>
            <td><b>{t('total_outstanding_expenses')}</b> </td>
            <td className="right-align"><b>{totalExpenses}</b></td>
        </tr>
        <tr>
            <td><b>{t('total_outstanding_fee')}</b> </td>
            <td className="right-align"><b>{totalFee}</b></td>
        </tr>
    </>); 
    }
    }

    render() {
        const { t } = this.props;
        const summary = this.props.outstandingsummary;

        return (
            <div>
                <button disabled={this.props.customerId <= 0} onClick={this.open} className="btn btn-secondary" style={{ marginRight: 10 }}><i className="far fa-dot-circle"></i> {t('outstandingsummary')}</button>

                <Modal size="lg" show={this.state.showModal} onHide={this.close} animation={false} >
                    <br></br>
                    <div className="row">
                        <div className="col-lg-12 col-md-12 col-sm-12">
                            <div className="sm-title"><h4>{t('outstandingsummary')}</h4>
                            </div>
                        </div>
                    </div>
                    <Modal.Body>
                    <div className="row">
                        <div className="col-lg-8 col-md-8 col-sm-8">
                            <select onChange={this.onChangePeriod} defaultValue={ this.state.selectedPeriod} className="form-control" id="timeperiodDropdown" title={t('select_a_timeperiod')}>
                                <option value="1" >{t('one_month_older')}</option>
                                <option value="2" >{t('two_month_older')}</option>
                                <option value="3" >{t('three_month_older')}</option>
                                <option value="4" >{t('older_than_3month')}</option>
                            </select>
                        </div>
                    </div>
                    <br></br>
                        <Table striped bordered hover>
                            <thead>
                                <tr>
                                    <th>{t('customer_name')}</th>
                                    <th>{t('total_expences')}(SEK)</th>
                                    <th>{t('total')}(SEK)</th>
                                </tr>
                            </thead>
                            <tbody>
                                {summary != null ? this.renderCustomerOutstandingRows(summary):<></>}
                            </tbody>
                        </Table>
                        <br></br>
                        <br></br>
                        <div class="row">
                            <div class="col-8">
                                <Table striped bordered hover>
                                    <tbody>
                                        {summary != null ? this.renderTotalOutstanding(summary):<></>}
                                    </tbody>
                                </Table>
                            </div>
                        </div>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button onClick={this.close}>{t('close')}</Button>
                    </Modal.Footer>
                </Modal>
            </div>

        );
    }
};

function mapStateToProps(globalState) {
    return {
        outstandingsummary : globalState.customers.outstandingsummary

    };
}

function mapDispatchToProps(dispatch) {
	return {
        getOutstandingTimesheetsExpensesSummary  : (data) => dispatch(getOutstandingTimesheetsExpensesSummary(data)),
	};
}

export default withTranslation('translation', { withRef: true })(connect(mapStateToProps, mapDispatchToProps)(OverallSummary));
