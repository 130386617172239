import React, { Component, Fragment } from 'react';
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import { withTranslation } from 'react-i18next';
import { getCustomers, deleteCustomer } from '../Actions/Customer';
import i18n from '../i18n';
import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory from 'react-bootstrap-table2-paginator';


class CustomersList extends Component {
    constructor(props) {
        super(props)
        this.state =
        {
            companys: props.companys,
            loading: false,
            msgContent: null,
            msgType: null,
            message: null,
            isError : false
        };

        this.handleDelete = this.handleDelete.bind(this);
        this.editCustomer = this.editCustomer.bind(this);
        this.handlePageChange = this.handlePageChange.bind(this);

        this.columns = [
            {
                dataField: 'customerID',
                text: 'ID',
                sort: true,
                hidden :true
            },
            {
                dataField: 'name',
                text: i18n.t('customer_name'),
                sort: true,
            },
            {
                dataField: 'email',
                text: i18n.t('email'),
                sort: true,
            },
            {
                dataField: 'orgRegNo',
                text: i18n.t('org_number'),
            },
            {
                dataField: 'telephone',
                text: i18n.t('telephone'),
                sort: true,
            },
            {
                dataField: 'actions',
                text: i18n.t('actions'),
                isDummyField: true,
                csvExport: false,
                sort: false,
                formatter: (cell, row) => {
                    return (
                        <Fragment>
                            <button
                                type="button"
                                className="btn btn-primary"
                                onClick={() => this.editCustomer(row)}
                            >
                                <i className="fa fa-edit"></i>
                            </button>
                            <Link to={`/pt/customers/${row.customerID}`}><button type="button" className="btn btn-primary" ><i className="fa fa-clock"></i></button></Link>
                            <button
                                className="btn btn-danger mleft-4"
                                onClick={() => {
                                    if (window.confirm('Are you sure to delete this Customer?'))
                                        this.handleDelete(row.customerID);
                                }}
                            >
                                <i className="fa fa-trash"></i>
                            </button>
                        </Fragment>
                    );
                },
            },
        ];
    }

    editCustomer(customer) {
        this.props.toggleCustomerForm(customer);
    }

    handleDelete(customerId) {
        this.props.deleteCustomer(customerId);
    }

    componentDidMount() {
        const { childRef } = this.props;
        childRef(this);
        this.props.getCustomers();

    }
    componentWillUnmount() {
        const { childRef } = this.props;
        childRef(undefined);
    }

    componentDidUpdate(prevProps) {
        if (prevProps.reload !== this.props.reload) {
            this.props.getCustomers();
        }
        if (this.props.message || this.props.error) {
            this.props.getCustomers();
            this.setState({
                isError: !!this.props.error,
                message: this.props.message || this.props.error,
            });
            var that = this;
			setTimeout(function () {
				that.setState({ isError: false, message: null });
			}, 3000);
        }
    }

    handlePageChange(pageNumber) {
        this.setState({ activePage: pageNumber });
    }

    render() {
        return (
            <>
                <BootstrapTable
                    keyField="customerID"
                    data={this.props.customers ? this.props.customers.allResult : []}
                    columns={this.columns}
                    pagination={paginationFactory()}
                    striped
                    hover
                    condensed
                />
            </>
        )
    }
}

function mapStateToProps(globalState) {
    return {
        customers: globalState.customers.customers,
        error: globalState.customers.error,
        loading: globalState.customers.loading,
        message: globalState.customers.message,
    };
}

function mapDispatchToProps(dispatch) {
    return {
        deleteCustomer: (id) => dispatch(deleteCustomer({ id })),
        getCustomers: () => dispatch(getCustomers())
    };
}

export default withTranslation()(connect(mapStateToProps, mapDispatchToProps)(CustomersList));



