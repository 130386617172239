import { AUTH } from '../Utils/constants';

const authenticate = (data) => ({
	type: AUTH.AUTH_LOAD,
	data,
});

const authFailed = (error) => ({
	type: AUTH.AUTH_FAILED,
	error,
});

const authSuccess = (token) => ({
	type: AUTH.AUTH_SUCCESS,
	token,
});

const logout = (message) => ({
	type: AUTH.LOGOUT,
	message,
});

export { authenticate, authFailed, authSuccess, logout };
