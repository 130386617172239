import React, { Component, Fragment } from 'react';
import '../Styles/main.scss';
import i18n from '../i18n';
import { connect } from "react-redux";
import { withTranslation } from 'react-i18next';
import { getConsultants, createConsultant, deleteConsultant, updateConsultant } from '../Actions/Consultant';
import Alert from 'react-bootstrap/Alert';
import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory from 'react-bootstrap-table2-paginator';
import Moment from 'moment';
import { DATE_FORMAT } from '../Utils/constants';


class Consultants extends Component {
	constructor(props) {
		super(props)
		this.state = {
			message: null,
			isError: false,
			formValues: {
				name: '',
				email: '',
				userID: 0
			},
			formErrors: {
				name: '',
				email: '',
			},
			formValidity: {
				name: false,
				email: false,
				userID: true
			},
			isSubmitting: false,
			consultants: props.consultants,
			loading: false,
			msgContent: null,
			msgType: null,
		};


		this.editConsultant = this.editConsultant.bind(this);
		this.handleDelete = this.handleDelete.bind(this);
		this.resetForm = this.resetForm.bind(this);
		this.handleChange = this.handleChange.bind(this);

		this.columns = [
			{
				dataField: 'userID',
				text: i18n.t('id'),
				sort: true,
				hidden:true
			},
			{
				dataField: 'name',
				text: i18n.t('name'),
				sort: true,
			},
			{
				dataField: 'email',
				text: i18n.t('email'),
				sort: true,
			},
			{
				dataField: 'actions',
				text: i18n.t('actions'),
				isDummyField: true,
				csvExport: false,
				sort: false,
				formatter: (cell, row) => {
					return (
						<Fragment>
							<button
								type="button"
								className="btn btn-primary"
								onClick={() => this.editConsultant(row)}
							>
								<i className="fa fa-edit"></i>
							</button>
							<button
								className="btn btn-danger"
								onClick={() => {
									if (window.confirm('Are you sure to delete this consultants ?'))
										this.handleDelete(row.userID);
								}}
							>
								<i className="fa fa-trash"></i>
							</button>
						</Fragment>
					);
				},
			},
		];
	}

	resetForm() {
		this.setState({
			formValues: {
				name: '',
				email: '',
				userID: 0
			},
			formErrors: {
				name: '',
				email: '',
			},
			formValidity: {
				name: false,
				email: false,
				userID: true
			},
		});
	}

	editConsultant(consultant) {
		if (consultant) {
			const { formValidity } = this.state;
			var newformValues = {
				name: consultant.name,
				email: consultant.email,
				userID: consultant.userID,
			}
			this.setState({ formValues: newformValues });

			for (let key in newformValues) {
				let target = {
					name: key,
					value: newformValues[key]
				};
				if (Object.keys(formValidity).includes(key)) {
					this.handleValidation(target);
				}
			}
			window.scrollTo(0, 0);
		}
	}

	handleDelete(userID) {
		this.props.deleteConsultant(userID);
	}

	handleChange = ({ target }) => {
		const { formValues } = this.state;
		formValues[target.name] = target.value;
		this.setState({ formValues });
		if(target.name !== "email"){
			this.handleValidation(target);
		}
	};

	handleValidation = target => {
		const { name, value } = target.name? target : target.target;
		const fieldValidationErrors = this.state.formErrors;

		const validity = this.state.formValidity;
		validity[name] = value.length > 0;

		switch (name) {
			case "name":
				fieldValidationErrors[name] = validity[name] ? "" : i18n.t("consultant_name_is_required");
				break;
			case "email":
				fieldValidationErrors[name] = validity[name] ? "" : i18n.t("user_name_is_required");
				break;
			default:
				break;
		}

		const isEmail = name === "email";
		const emailTest = /^[^\s@]+@[^\s@]+\.[^\s@]{2,}$/i;

		if (validity[name]) {
			if (isEmail) {
				validity[name] = emailTest.test(value);
				fieldValidationErrors[name] = validity[name]
					? ""
					: i18n.t("username_should_be_a_valid_email_address");
			}
		}
		this.setState({
			formErrors: fieldValidationErrors,
			formValidity: validity
		});
	};

	componentDidMount() {
		this.props.getConsultants();
	}

	componentDidUpdate(props) {
		if (this.props.message || this.props.error) {
			this.props.getConsultants();
			this.setState({
				isError: !!this.props.error,
				message: this.props.message || this.props.error,
			});
			this.resetForm();
			var that = this;
			setTimeout(function () {
				that.setState({ isError: false, message: null });
			}, 3000);
		}
	}

	handleSubmit = (event) => {
		event.preventDefault();
		const { formValues, formValidity } = this.state;
		formValidity.userID = true;
		if (Object.values(formValidity).every(Boolean)) {
			if (formValues.userID > 0) {
				this.props.updateConsultant(formValues);
			} else {
				this.props.createConsultant(formValues);
			}
		} else {
			for (let key in formValues) {
				let target = {
					name: key,
					value: formValues[key],
				};
				this.handleValidation(target);
			}
			this.setState({ isSubmitting: false });
		}
	};

	render() {
		const { t } = this.props;
		const { formValues, formErrors, message, isError } = this.state;
		return (
			<div>
				<h1>{t('consultants')}</h1>
				{message && (
					<Alert
						variant={isError ? 'danger' : 'success'}
						dismissible
						onClose={() => this.setState({ isError: false, message: null })}
					>
						{message}
					</Alert>
				)}
				<div className="card">
					<form className="form-horizontal" onSubmit={this.handleSubmit} noValidate>
						<div className="card-header">
							<i className="fa fa-edit"></i>{t('create_consultant')}
						</div>
						<div className="card-body">
							{this.state.msgContent &&
								<div className={this.state.msgType} role="alert">
									{this.state.msgContent}
								</div>
							}
							<div className="row">
								<div className="col-lg-6">
									<div className="form-group">
										<label> {t('name')} </label> <span className="required-star">*</span>
										<input
											type="text" name="name"
											className={`form-control form-control-lg ${
												formErrors.name ? "is-invalid" : ""
												}`}
											value={formValues.name}
											onChange={this.handleChange}
										/>
										<div className="invalid-feedback">{formErrors.name}</div>
									</div>
								</div>
								<div className="col-lg-6">
									<div className="form-group">
										<label>{t('username')}</label> <span className="required-star">*</span>
										<input
											type="email" name="email"
											className={`form-control form-control-lg ${
												formErrors.email ? "is-invalid" : ""
												}`}
											value={formValues.email}
											onChange={this.handleChange}
											onBlur = { this.handleValidation}
										/>
										<div className="invalid-feedback">{formErrors.email}</div>
									</div>
								</div>
							</div>
						</div>
						<div className="card-footer">
							{this.props.loading ? (
								<button
									type="button"
									className="btn btn-success loading"
									disabled
								>
									<i className="fas fa-sync-alt fa-spin"></i>
								</button>
							) : (
									<span>
										<button
											type="submit"
											className="btn btn-success"
											disabled={this.props.loading}
										>
											<i className="far fa-dot-circle"></i>{' '}
											{this.props.loading ? t('please_wait') : t('save')}
										</button>
										<button
											type="button"
											onClick={this.resetForm}
											className="btn btn-info"
										>
											<i className="fa fa-times"></i> {t('clear')}
										</button>
									</span>
								)}
						</div>
					</form>
				</div>
				<br></br>
				<BootstrapTable
					keyField="userID"
					data={this.props.consultants ? this.props.consultants.result : []}
					columns={this.columns}
					pagination={paginationFactory()}
					striped
					hover
					condensed
				/>
			</div>
		);
	}
}



function mapStateToProps(globalState) {
	return {
		consultants: globalState.consultants.consultants,
		error: globalState.consultants.error,
		loading: globalState.consultants.loading,
		message: globalState.consultants.message,
	};
}

function mapDispatchToProps(dispatch) {
	return {
		createConsultant: (formValues) => dispatch(createConsultant(formValues)),
		updateConsultant: (formValues) => dispatch(updateConsultant(formValues)),
		deleteConsultant: (id) => dispatch(deleteConsultant({ id })),
		getConsultants: () => dispatch(getConsultants()),
	};
}

export default withTranslation('translation', { withRef: true })(
	connect(mapStateToProps, mapDispatchToProps)(Consultants)
);
