import React, { Component, Fragment } from 'react';
import InvoiceModal from './InvoiceModal';
import { connect } from "react-redux";
import { withTranslation } from 'react-i18next';
import { getInvoices, deleteInvoice } from '../Actions/Invoice';
import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory from 'react-bootstrap-table2-paginator';
import i18n from '../i18n';
import Moment from 'moment';
import { DATE_FORMAT } from '../Utils/constants';
import Alert from 'react-bootstrap/Alert';
import { BASE_URL } from '../Utils/constants';
import { saveAs } from 'file-saver';


class InvoicesHistory extends Component {
    constructor(props) {
        super(props);
        this.state = {
            showModal: false,
            invoices: props.invoices,
            customerID: props.customerID,
            invoiceID: 0,
            selectedInvoice: null,
            message: null,
            isError: false
        }
        this.open = this.open.bind(this);
        this.close = this.close.bind(this);
        this.handleDelete = this.handleDelete.bind(this);
        this.columns = [
            {
                dataField: 'invoiceID',
                text: i18n.t('id'),
                hidden: true,
                sort: true
            },
            {
                dataField: 'invoiceNo',
                text: i18n.t('invoice_no'),
                sort: true,
            },
            {
                dataField: 'invioceAt',
                text: i18n.t('invoice_date'),
                formatter: (cell) => { return Moment(cell).format(DATE_FORMAT) },
                sort: true,
            },
            {
                dataField: 'customerName',
                text: i18n.t('customer_name'),
                sort: true,
            },
            {
                dataField: 'from',
                text: i18n.t('start_date'),
                sort: true,
                hidden: true
            },
            {
                dataField: 'to',
                text: i18n.t('end_date'),
                sort: true,
                hidden: true
            },
            {
                dataField: 'record_status',
                text: i18n.t('status'),
                formatter: (cell) => { if (cell === 2) { return i18n.t('confirmed') } else if (cell === 1) { return i18n.t('drafted') } else { return "" } },
                sort: true,
            },
            {
                dataField: 'totalAmount',
                text: i18n.t('total'),
                formatter: (cell) => { return cell.toFixed(2) },
                sort: true,
                align: 'right'
            },
            {
                dataField: 'actions',
                text: i18n.t('actions'),
                isDummyField: true,
                csvExport: false,
                sort: false,
                formatter: (cell, row) => {
                    return (
                        <Fragment>
                            <button type="button" className="btn btn-primary" onClick={() => this.open(row)}><i className="fa fa-eye"></i></button>
                            {/* {row.record_status === 1 ? <button className="btn btn-info" onClick={() => { }}><i className="fa fa-ban"></i></button> : ""} */}
                            {/* {row.record_status === 1 ? <button className="btn btn-info" onClick={() => { }}><i className="far fa-check-circle"></i></button> : ""} */}
                            {/* {row.record_status === 2 ?<button className="btn btn-info" onClick={() => {  }}><i className="fa fa-envelope"></i></button>:""} */}
                            {/* <button className="btn btn-info" onClick={() => {  }}><i className="fa fa-download"></i></button> */}
                            <button className="btn btn-danger" onClick={() => { if (window.confirm('Are you sure to delete this invoice ?')) this.handleDelete(row.invoiceID) }}><i className="fa fa-trash"></i></button>
                        </Fragment>
                    );
                },
            },
        ];
    }

    open(invoice) {
        this.setState({ selectedInvoice: invoice });
        this.setState({ showModal: true });
    };

    close() {
        this.setState({ showModal: false });
    };

    componentWillUnmount() {
        const { invoiceHistoryChild } = this.props;
        invoiceHistoryChild(undefined);
    }

    componentDidMount() {
        let customerId = this.props.customerId ? this.props.customerId : 0
        this.props.getInvoices(customerId);
    }

    componentDidUpdate(prevProps) {
        if (this.props.customerId !== prevProps.customerId) {
            this.props.getInvoices(this.props.customerId);
        }
        if (this.props.message || this.props.error) {
            this.props.getInvoices(this.props.customerId);
            if (this.props.message !== i18n.t('successfuly_created')) {
                this.setState({
                    isError: !!this.props.error,
                    message: this.props.message || this.props.error,
                });
            }
            var that = this;
            setTimeout(function () {
                that.setState({ isError: false, message: null });
            }, 3000);
        }
    }

    handleDelete(id) {
        this.props.deleteInvoice(id);
    }

    cancelInvoice(data) {
        data.record_status = 0;
        this.props.updateInvoice(data);
    }

    confirmInvoice(data) {
        data.record_status = 2;
        this.props.updateInvoice(data);
    }

    downloadWordDoc(data) {
        if (typeof window !== 'undefined') {
            var id = data.invoiceID;
            var url = BASE_URL + "/invoices/download/" + id;
            try {
                saveAs(url + "?token=" + localStorage.getItem('id_token'), id + ".docx");
            }
            catch (error) {

            }
        } else {

        }
    }

    sendEmailToCustomer() {
        var id = this.props.selectedInvoice.invoiceID;
        this.props.sendInvoice(id);
    }

    render() {
        const { message, isError } = this.state;

        return (
            <div>
                <br></br>
                {message && (
                    <Alert
                        variant={isError ? 'danger' : 'success'}
                        dismissible
                        onClose={() => this.setState({ isError: false, message: null })}
                    >
                        {message}
                    </Alert>
                )}
                <BootstrapTable
                    keyField="invoiceID"
                    data={this.props.invoices ? this.props.invoices.allResult : []}
                    columns={this.columns}
                    pagination={paginationFactory()}
                    striped
                    hover
                    condensed
                />
                {this.state.showModal && this.state.selectedInvoice ?
                    <InvoiceModal childRef3={ref => (this.childInvoiceModel = ref)} show={this.state.showModal} close={this.close} selectedInvoice={this.state.selectedInvoice} ></InvoiceModal>
                    : null
                }
            </div>
        )
    }
}

function mapStateToProps(globalState) {
    return {
        consultants: globalState.consultants.consultants,
        invoices: globalState.invoices.invoices,
        error: globalState.invoices.error,
        loading: globalState.invoices.loading,
        message: globalState.invoices.message,

    };
}

function mapDispatchToProps(dispatch) {
    return {
        getInvoices: (data) => dispatch(getInvoices(data)),
        deleteInvoice: (id) => dispatch(deleteInvoice(id)),
    };
}

export default withTranslation('translation', { withRef: true })(
    connect(mapStateToProps, mapDispatchToProps)(InvoicesHistory)
);


