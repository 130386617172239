import { put, call, takeLatest } from 'redux-saga/effects';
import { SERVICE } from '../Utils/constants';
import api from '../Api';
import {
	createOrUpdateFailed,
	createOrUpdateSuccess,
	getServicesFailed,
	getServicesSuccess,
} from '../Actions/Service';
import i18n from '../i18n';

export function* createService({ data }) {
	const resp = yield call(api.createService, { name: data.serviceName });
	if (resp instanceof Object) {
		yield put(createOrUpdateSuccess(i18n.t('successfuly_created')));
	} else {
		yield put(createOrUpdateFailed(resp));
	}
}

export function* updateService({ data: { id, serviceName } }) {
	const data = yield call(api.updateService, { name: serviceName, serviceID: id });
	if (data instanceof Object) {
		yield put(createOrUpdateSuccess(i18n.t('successfuly_updated')));
	} else {
		yield put(createOrUpdateFailed(data));
	}
}

export function* deleteService({ data: { id } }) {
	const data = yield call(api.deleteService, id);
	if (data instanceof Object) {
		yield put(createOrUpdateSuccess(i18n.t('successfuly_deleted')));
	} else {
		yield put(createOrUpdateFailed(data));
	}
}

export function* getServices() {
	const data = yield call(api.getServices);
	if (data instanceof Object) {
		yield put(getServicesSuccess(data));
	} else {
		yield put(getServicesFailed(data));
	}
}

export default function* ServiceSagaWatcher() {
	yield takeLatest(SERVICE.CREATE_SERVICE, createService);
	yield takeLatest(SERVICE.UPDATE_SERVICE, updateService);
	yield takeLatest(SERVICE.GET_SERVICES, getServices);
	yield takeLatest(SERVICE.DELETE_SERVICE, deleteService);
}
