import { EXPENSE } from '../Utils/constants';

const initialState = {
	expenses: null,
	summerizeExpenses: null,
	data: null,
	error: null,
	loading: false,
	message: null,
};

export default (state = initialState, action) => {
	switch (action.type) {
		case EXPENSE.CREATE_EXPENSE:
		case EXPENSE.UPDATE_EXPENSE:
		case EXPENSE.GET_EXPENSES:
			return {
				...state,
				loading: true,
				error: null,
				message: null,
			};
		case EXPENSE.UPDATE_EXPENSE_SUCCESS:
			return {
				...state,
				loading: false,
				message: action.message,
				data: action.data,
				error: null,
			};
		case EXPENSE.UPDATE_EXPENSES_FAILED:
		case EXPENSE.GET_EXPENSES_FAILED:
			return {
				...state,
				error: action.error,
				loading: false,
				message: null,
			};
		case EXPENSE.GET_EXPENSES_SUCCESS:
			return {
				...state,
				expenses: action.expenses,
				loading: false,
				error: null,
				message: null,
			};
		case EXPENSE.GET_SUMMERIZE_EXPENSES:
			return {
				...state,
				loading: true,
				error: null,
				message: null,
			};
		case EXPENSE.GET_SUMMERIZE_EXPENSES_FAILED:
			return {
				...state,
				error: action.error,
				loading: false,
				message: null,
			};
		case EXPENSE.GET_SUMMERIZE_EXPENSES_SUCCESS:
			return {
				...state,
				summerizeExpenses: action.summerizeExpenses,
				loading: false,
				error: null,
				message: null,
			};
		default:
			return state;
	}
};
