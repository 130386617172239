import { createStore, applyMiddleware, compose } from 'redux';
import createSagaMiddleware from 'redux-saga';
import logger from 'redux-logger';
import rootReducer from '../Reducers';
import rootSaga from '../State/Root.Saga';
import { AUTH } from '../Utils/constants';

const logoutMiddleware = (store) => (next) => (action) => {
	if (action.type === AUTH.LOGOUT) {
		localStorage.removeItem('id_token');
		localStorage.removeItem('user');
	}
	next(action);
};

const configureStore = () => {
	const sagaMiddleware = createSagaMiddleware();
	const store = createStore(
		rootReducer,
		compose(applyMiddleware(logger, sagaMiddleware, logoutMiddleware))
	);
	sagaMiddleware.run(rootSaga);
	return store;
};

const store = configureStore();
export default store;
