import { combineReducers } from 'redux';
import companys from './Companys';
import services from './Services';
import consultants from './Consultants';
import invoices from './Invoices';
import customers from './Customers';
import userServices from './Userservice';
import expenses from './Expenses';
import password from './Password';
import expensessummary from './ExpensesSummary';
import timeSheetsSummary from './TimeLogsSummary';
import timesheets from './TimeSheets';
import auth from './Auth';

export default combineReducers({
	auth,
	companys,
	services,
	consultants,
	invoices,
	customers,
	expenses,
	timesheets,
	expensessummary,
	timeSheetsSummary,
	userServices,
	password
});
