import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Switch, Route } from 'react-router';
import { Layout } from 'antd';
import AppHeader from '../Components/AppHeader';
import { withTranslation } from 'react-i18next';
import Left from '../Components/Left';
import Customers from './Customers';
import CustomerDetails from './CustomerDetails';
import Invoices from './Invoices';
import Services from './Services';
import Consultants from './Consultants';
import ResetPassword from './ResetPassword';
import NotFoundPage from './NotFound';
import AuthRoute from '../Utils/authRoute';
import { APP_VERSION } from '../Utils/constants';

const { Content, Footer } = Layout;

class Home extends Component {
	render() {
		const { match } = this.props;
		return (
			<Layout style={{ minHeight: '100vh' }}>
				<Left />
				<Layout>
					<AppHeader history={this.props.history} />
					<Content className={'hw-100'}>
						<div style={{ padding: 24, background: '#fff' }}>
							<Switch>
								<AuthRoute
									sensitive
									exact
									path={`${match.path}/customers`}
									component={withTranslation()(Customers)}
								/>
								<AuthRoute
									sensitive
									exact
									path={`${match.path}/customers/:customerid`}
									component={withTranslation()(CustomerDetails)}
								/>
								<AuthRoute
									sensitive
									child
									path={`${match.path}/invoices`}
									component={withTranslation()(Invoices)}
								/>
								<AuthRoute
									path={`${match.path}/services`}
									component={withTranslation()(Services)}
								/>
								<AuthRoute
									path={`${match.path}/consultants`}
									component={withTranslation()(Consultants)}
								/>
								<AuthRoute
									sensitive
									exact
									path={`${match.path}/resetpassword`}
									component={withTranslation()(ResetPassword)}
								/>
								<Route component={NotFoundPage} />
							</Switch>
						</div>
					</Content>
					<Footer style={{ textAlign: 'center' }}> ©2020 Created by {APP_VERSION}</Footer>
				</Layout>
			</Layout>
		);
	}
}

function mapStateToProps(globalState) {
	return {};
}

export default connect(mapStateToProps)(Home);