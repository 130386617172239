import { put, call, takeLatest } from 'redux-saga/effects';
import { EXPENSE } from '../Utils/constants';
import api from '../Api/expense.api';
import {
	createOrUpdateFailed,
	createOrUpdateSuccess,
	getExpensesFailed,
	getExpensesSuccess,
	getSummerizeExpensesFailed,
	getSummerizeExpensesSuccess
} from '../Actions/Expense';
import i18n from '../i18n';

export function* createExpense( data ) {
	const resp = yield call(api.createExpense, data.data);
	if (resp instanceof Object) {
		yield put(createOrUpdateSuccess(i18n.t('successfuly_created'), resp));
	} else {
		yield put(createOrUpdateFailed(resp));
	}
}

export function* updateExpense(d, id) {
	const data = yield call(api.updateExpense, d, id);
	if (data instanceof Object) {
		yield put(createOrUpdateSuccess(i18n.t('successfuly_updated'), data));
	} else {
		yield put(createOrUpdateFailed(data));
	}
}

export function* deleteExpense(data) {
	const res = yield call(api.deleteExpense, data.id);
	if (res instanceof Object) {
		yield put(createOrUpdateSuccess(i18n.t('successfuly_deleted')));
	} else {
		yield put(createOrUpdateFailed(res));
	}
}

export function* getExpenses(d) {
	const data = yield call(api.getExpenses, d.data);
	if (data instanceof Object) {
		yield put(getExpensesSuccess(data));
	} else {
		yield put(getExpensesFailed(data));
	}
}

export function* getSummerizeExpenses(d) {
	const data = yield call(api.getExpenses, d.data);
	if (data instanceof Object) {
		yield put(getSummerizeExpensesSuccess(data));
	} else {
		yield put(getSummerizeExpensesFailed(data));
	}
}

export default function* ExpenseSagaWatcher() {
	yield takeLatest(EXPENSE.CREATE_EXPENSE, createExpense);
	yield takeLatest(EXPENSE.UPDATE_EXPENSE, updateExpense);
	yield takeLatest(EXPENSE.GET_EXPENSES, getExpenses);
	yield takeLatest(EXPENSE.GET_SUMMERIZE_EXPENSES, getSummerizeExpenses);
	yield takeLatest(EXPENSE.DELETE_EXPENSE, deleteExpense);
}
