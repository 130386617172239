import { CUSTOMER } from '../Utils/constants';

const createCustomer = (data) => ({
	type: CUSTOMER.CREATE_CUSTOMER,
	data,
});

const updateCustomer = (data) => ({
	type: CUSTOMER.UPDATE_CUSTOMER,
	data,
});

const deleteCustomer = (data) => ({
	type: CUSTOMER.DELETE_CUSTOMER,
	data,
});

const createOrUpdateFailed = (error) => ({
	type: CUSTOMER.UPDATE_CUSTOMER_FAILED,
	error,
});

const createOrUpdateSuccess = (message) => ({
	type: CUSTOMER.UPDATE_CUSTOMER_SUCCESS,
	message,
});

const getCustomers = () => ({
	type: CUSTOMER.GET_CUSTOMERS,
});

const getCustomersFailed = (error) => ({
	type: CUSTOMER.GET_CUSTOMERS_FAILED,
	error,
});

const getCustomersSuccess = (customers) => ({
	type: CUSTOMER.GET_CUSTOMERS_SUCCESS,
	customers,
});
const getCustomer = (id) => ({
	type: CUSTOMER.GET_CUSTOMER,
	id
});

const getCustomerFailed = (error) => ({
	type: CUSTOMER.GET_CUSTOMERS_FAILED,
	error,
});

const getCustomerSuccess = (customer) => ({
	type: CUSTOMER.GET_CUSTOMER_SUCCESS,
	customer,
});

const getOutstandingTimesheetsExpensesSummary = (data) => ({
	type: CUSTOMER.GET_OUTSTANDING_TIMESHEETS_EXPENSES_SUMMERY,
	data
});

const getOutstandingTimesheetsExpensesSummaryFailed = (error) => ({
	type: CUSTOMER.GET_OUTSTANDING_TIMESHEETS_EXPENSES_SUMMERY_FAILED,
	error,
});

const getOutstandingTimesheetsExpensesSummarySuccess = (data) => ({
	type: CUSTOMER.GET_OUTSTANDING_TIMESHEETS_EXPENSES_SUMMERY_SUCCESS,
	data,
});

export {
	createCustomer,
	updateCustomer,
	deleteCustomer,
	createOrUpdateFailed,
	createOrUpdateSuccess,
	getCustomers,
	getCustomersFailed,
	getCustomersSuccess,
	getCustomer,
	getCustomerFailed,
	getCustomerSuccess,
	getOutstandingTimesheetsExpensesSummary,
	getOutstandingTimesheetsExpensesSummaryFailed,
	getOutstandingTimesheetsExpensesSummarySuccess
};
