import axios from './clientInstance';
import { BASE_URL } from '../Utils/constants';

const createExpense = async (data) => {
	try {
		const response = await axios({
			method: 'post',
			url: `${BASE_URL}/expenses`,
			data,
		});

		if (response.status >= 400) {
			throw new Error(response.statusText);
		}
		return response.data;
	} catch (error) {
		return error.message;
	}
};

const updateExpense = async (data) => {
	try {
		const response = await axios({
			method: 'put',
			url: `${BASE_URL}/expenses/${data.data.expenseID}`,
			data: data.data,
		});

		if (response.status >= 400) {
			throw new Error(response.statusText);
		}
		return response.data;
	} catch (error) {
		return error.message;
	}
};

const deleteExpense = async (id) => {
	try {
		const response = await axios({
			method: 'delete',
			url: `${BASE_URL}/expenses/${id}`,
		});

		if (response.status >= 400) {
			throw new Error(response.statusText);
		}
		return response.data;
	} catch (error) {
		return error.message;
	}
};

const getExpenses = async (data) => {

	try {
		const response = await axios({
			method: 'get',
			url: `${BASE_URL}/expenses${data}`,
		});

		if (response.status >= 400) {
			throw new Error(response.statusText);
		}
		return response.data;
	} catch (error) {
		return error.message;
	}
};

export default {
	createExpense,
	updateExpense,
	deleteExpense,
	getExpenses,
};
