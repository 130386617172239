import { AUTH } from '../Utils/constants';

const initialState = {
	token: localStorage.getItem('id_token'),
	user: localStorage.getItem('user') ? JSON.parse(localStorage.getItem('user')) : null,
	error: null,
	loading: false,
};

export default (state = initialState, action) => {
	switch (action.type) {
		case AUTH.AUTH_LOAD:
			return {
				...state,
				loading: true,
			};
		case AUTH.AUTH_SUCCESS:
			return {
				...state,
				token: action.token.token,
				user: action.token.user,
				loading: false,
			};
		case AUTH.AUTH_FAILED:
			return {
				...state,
				error: action.error,
				loading: false,
			};
		case AUTH.LOGOUT:
			return {
				...state,
				error: action.message,
				token: null,
				user: null,
				loading: false,
			};
		default:
			return state;
	}
};
