import axios from './clientInstance';
import { BASE_URL } from '../Utils/constants';

const createUserservice = async (data) => {
	try {
		const response = await axios({
			method: 'post',
			url: `${BASE_URL}/user_services`,
			data,
		});

		if (response.status >= 400) {
			throw new Error(response.statusText);
		}
		return response.data;
	} catch (error) {
		return error.message;
	}
};

const updateUserservice = async (data) => {
	try {
		const response = await axios({
			method: 'put',
			url: `${BASE_URL}/user_services/${data.data.ID}`,
			data: data.data,
		});

		if (response.status >= 400) {
			throw new Error(response.statusText);
		}
		return response.data;
	} catch (error) {
		return error.message;
	}
};

const deleteUserservice = async (data) => {
	try {
		const response = await axios({
			method: 'delete',
			url: `${BASE_URL}/user_services/${data.id}`,
		});

		if (response.status >= 400) {
			throw new Error(response.statusText);
		}
		return response.data;
	} catch (error) {
		return error.message;
	}
};

const getUserservices = async (id) => {
	try {
		const response = await axios({
			method: 'get',
			url: `${BASE_URL}/user_services/?customerID=${id}`,
		});

		if (response.status >= 400) {
			throw new Error(response.statusText);
		}
		return response.data;
	} catch (error) {
		return error.message;
	}
};

export default {
	createUserservice,
	updateUserservice,
	deleteUserservice,
	getUserservices,
};
