import { put, call, takeLatest } from 'redux-saga/effects';
import { USERSERVICE } from '../Utils/constants';
import api from '../Api/userservice.api';
import {
	createOrUpdateFailed,
	createOrUpdateSuccess,
	getUserservicesFailed,
	getUserservicesSuccess
} from '../Actions/Userservice';
import i18n from '../i18n';


export function* createUserservice({ data }) {
	const resp = yield call(api.createUserservice, data);
	if (resp instanceof Object) {
		yield put(createOrUpdateSuccess(i18n.t('successfuly_created')));
	} else {
		yield put(createOrUpdateFailed(resp));
	}
}

export function* updateUserservice(data) {
	const resp = yield call(api.updateUserservice, data);
	if (resp instanceof Object) {
		yield put(createOrUpdateSuccess(i18n.t('successfuly_updated')));
	} else {
		yield put(createOrUpdateFailed(resp));
	}
}

export function* deleteUserservice(id) {
	const data = yield call(api.deleteUserservice, id);
	if (data instanceof Object) {
		yield put(createOrUpdateSuccess(i18n.t('successfuly_deleted')));
	} else {
		yield put(createOrUpdateFailed(data));
	}
}

export function* getUserservices(res) {
	const data = yield call(api.getUserservices, res.id);
	if (data instanceof Object) {
		yield put(getUserservicesSuccess(data));
	} else {
		yield put(getUserservicesFailed(data));
	}
}

export default function* UserserviceSagaWatcher() {
	yield takeLatest(USERSERVICE.CREATE_USERSERVICE, createUserservice);
	yield takeLatest(USERSERVICE.UPDATE_USERSERVICE, updateUserservice);
	yield takeLatest(USERSERVICE.GET_USERSERVICES, getUserservices);
	yield takeLatest(USERSERVICE.DELETE_USERSERVICE, deleteUserservice);
}
