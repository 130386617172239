import { put, call, takeLatest } from 'redux-saga/effects';
import { CONSULTANT } from '../Utils/constants';
import api from '../Api/consultant.api';
import {
	createOrUpdateFailed,
	createOrUpdateSuccess,
	getConsultantsSuccess,
	getConsultantsFailed
	
} from '../Actions/Consultant';
import i18n from '../i18n';

export function* createConsultant({ data }) {
	const resp = yield call(api.createConsultant, data);
	if (resp instanceof Object) {
		yield put(createOrUpdateSuccess(i18n.t('successfuly_created')));
	} else {
		yield put(createOrUpdateFailed(resp));
	}
}

export function* updateConsultant(data) {
	const resp = yield call(api.updateConsultant, data);
	if (resp instanceof Object) {
		yield put(createOrUpdateSuccess(i18n.t('successfuly_updated')));
	} else {
		yield put(createOrUpdateFailed(resp));
	}
}


export function* deleteConsultant({ data: { id } }) {
	const data = yield call(api.deleteConsultant, id);
	if (data instanceof Object) {
		yield put(createOrUpdateSuccess(i18n.t('successfuly_deleted')));
	} else {
		yield put(createOrUpdateFailed(data));
	}
}

export function* getConsultants() {
	const data = yield call(api.getConsultants);
	if (data instanceof Object) {
		yield put(getConsultantsSuccess(data));
	} else {
		yield put(getConsultantsFailed(data));
	}
}

export default function* ConsultantSagaWatcher() {
	yield takeLatest(CONSULTANT.CREATE_CONSULTANT, createConsultant);
	yield takeLatest(CONSULTANT.UPDATE_CONSULTANT, updateConsultant);
	yield takeLatest(CONSULTANT.GET_CONSULTANTS, getConsultants);
	yield takeLatest(CONSULTANT.DELETE_CONSULTANT, deleteConsultant);
}
