import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';
import i18n from '../i18n';
import { connect } from "react-redux";
import { createCustomer, updateCustomer } from '../Actions/Customer';

class CustomerModal extends Component {
    constructor(props) {
        super(props)
        this.state = {
            customer: props.customerData,
            validity: props.validity,
            formValues: {
                customerID: props.customerData?.customerID,
                name: '',
                email: '',
                orgRegNo: '',
                VATNo: '',
                address: '',
                telephone: ''
            },
            formErrors: {
                name: '',
                email: '',
                orgRegNo: '',
                VATNo: '',
                address: '',
                telephone: ''
            },
            formValidity: {
                name: false,
                email: false,
                orgRegNo: false,
                VATNo: true,
                address: false,
                telephone: true,
            },
            editMode: props.validity,
            isSubmitting: false
        }
        this.handleChange = this.handleChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
        this.editUser = this.editUser.bind(this);
        this.resetForm = this.resetForm.bind(this);
    }

    handleChange(e) {
        const target = e.target;
        const value = target.type = target.value;
        const name = target.name;

        const { formValues } = this.state;
        formValues[name] = value;
        this.setState({ formValues: formValues });
        if(target.name !== "email"){
			this.handleValidation(e);
		}
    }

    handleValidation = target => {
		const { name, value } = target.name? target : target.target;
        const fieldValidationErrors = this.state.formErrors;
        const validity = this.state.formValidity;

        validity[name] = value != null && value.length > 0;

        switch (name) {
            case "name":
                fieldValidationErrors[name] = validity[name] ? "" : i18n.t("customer_name_is_required");
                break;
            case "email":
                fieldValidationErrors[name] = validity[name] ? "" : i18n.t("email_is_required");
                break;
            case "orgRegNo":
                fieldValidationErrors[name] = validity[name] ? "" : i18n.t("orgno_is_required");
                break;
            case "address":
                fieldValidationErrors[name] = validity[name] ? "" : i18n.t("address_is_required");
                break;
            default:
                validity[name] = true;
                break;
        }
        const isEmail = name === "email";
        const emailTest = /^[^\s@]+@[^\s@]+\.[^\s@]{2,}$/i;

        const isPhone = name === "phone"
        const phoneTest = /^[0][1-9]\d{8}$|^[1-9]\d{9}$/g;
        if (validity[name]) {
            if (isEmail) {
                validity[name] = emailTest.test(value);
                fieldValidationErrors[name] = validity[name]
                    ? ""
                    : `Email should be a valid email address`;
            }
            if (isPhone) {
                if (value && value.length > 0) {
                    validity[name] = phoneTest.test(value);
                    fieldValidationErrors[name] = validity[name]
                        ? ""
                        : `Telephone should be a 10 digit number`;
                }
                else {
                    validity[name] = true;
                    fieldValidationErrors[name] = ""
                }

            }

        }
        this.setState({
            formErrors: fieldValidationErrors,
            formValidity: validity
        });
    };

    componentDidUpdate(prevProps) {
        if (((prevProps.customerData && prevProps.customerData.customerID) !== (this.props.customerData && this.props.customerData.customerID))) {
            if(!this.props.message){
                this.setState({
                    customer: this.props.customerData,
                });
            }
        }
        if (this.props.message || this.props.error) {
            this.setState({
                isError: !!this.props.error,
                message: this.props.message || this.props.error,
            });
            var that = this;
			setTimeout(function () {
				that.setState({ isError: false, message: null });
			}, 3000);
        }
        
    }

    componentDidMount() {
        const { childRef } = this.props;
        childRef(this);
    }
    componentWillUnmount() {
        const { childRef } = this.props;
        childRef(undefined);
    }

    handleSubmit(event) {
        event.preventDefault();
        this.setState({ isSubmitting: true });
        const { formValues, formValidity } = this.state;
        if (Object.values(formValidity).every(Boolean)) {
            if (formValues.customerID > 0) {
                this.props.updateCustomer(formValues, formValues.customerID);
            } else {
                this.props.createCustomer(formValues);
            }
            this.resetForm();
        } else {
            for (let key in formValues) {
                let target = {
                    name: key,
                    value: formValues[key]
                };
                if (Object.keys(formValidity).includes(key)) {
                    this.handleValidation(target);
                }
            }
            this.setState({ isSubmitting: false });
        }
    }

    resetForm() {
        this.setState({
            customer: null,
            formValues: {
                customerID: 0,
                name: '',
                email: '',
                orgRegNo: '',
                VATNo: '',
                address: '',
                telephone: ''
            },
            formErrors: {
                name: '',
                email: '',
                orgRegNo: '',
                VATNo: '',
                address: '',
                telephone: ''
            },
            formValidity: {
                name: false,
                email: false,
                orgRegNo: false,
                VATNo: true,
                address: false,
                telephone: true,
            },
            isSubmitting: false
        });
    }

    editUser(customer) {
        this.setState({ formValues: customer });
        const { formValidity } = this.state;

        for (let key in customer) {
            let target = {
                name: key,
                value: customer[key]
            };
            if (Object.keys(formValidity).includes(key)) {
                this.handleValidation(target);
            }
        }
    }

    render() {
        const { formValues, formErrors, isSubmitting } = this.state;
        const { t } = this.props;

        return (
            <div className="modal-box" >
                <div className="card">
                    <form className="form-horizontal" onSubmit={this.handleSubmit} noValidate>
                        <div className="card-header">
                            <i className="fa fa-user-plus" ></i> {t('create_or_edit_customer')}
                        </div>
                        <div className="card-body">
                            <div className="row">
                                <div className="col-lg-6">
                                    <div className="form-group">
                                        <label> {t('customer_name')} </label> <span className="required-star">*</span>
                                        <input
                                            type="text" name="name"
                                            className={`form-control form-control-lg ${
                                                formErrors.name ? "is-invalid" : ""
                                                }`}
                                            value={formValues.name || ''}
                                            onChange={this.handleChange}
                                        />
                                        <div className="invalid-feedback">{formErrors.name}</div>
                                    </div>
                                </div>
                                <div className="col-lg-6">
                                    <div className="form-group">
                                        <label>{t('email')}</label> <span className="required-star">*</span>
                                        <input
                                            type="email" name="email"
                                            className={`form-control form-control-lg ${
                                                formErrors.email ? "is-invalid" : ""
                                                }`}
                                            value={formValues.email || ''}
                                            onBlur = { this.handleValidation}
                                            onChange={this.handleChange} />
                                        <div className="invalid-feedback">{formErrors.email}</div>
                                    </div>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-lg-6">
                                    <div className="form-group">
                                        <label>{t('org_number')} </label> <span className="required-star">*</span>
                                        <input
                                            type="text" name="orgRegNo"
                                            className={`form-control form-control-lg ${
                                                formErrors.orgRegNo ? "is-invalid" : ""
                                                }`}
                                            value={formValues.orgRegNo || ''}
                                            onChange={this.handleChange}
                                        />
                                        <div className="invalid-feedback">{formErrors.orgRegNo}</div>
                                    </div>
                                </div>
                                <div className="col-lg-6">
                                    <div className="form-group">
                                        <label>{t("vat_number")}</label>
                                        <input
                                            type="text" name="VATNo"
                                            className={`form-control form-control-lg ${
                                                formErrors.VATNo ? "is-invalid" : ""
                                                }`}
                                            value={formValues.VATNo || ''}
                                            onChange={this.handleChange} />
                                        <div className="invalid-feedback">{formErrors.VATNo}</div>
                                    </div>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-lg-6">
                                    <div className="form-group">
                                        <label>{t("telephone")}</label>
                                        <input
                                            type="text" name="telephone"
                                            className={`form-control form-control-lg ${
                                                formErrors.telephone ? "is-invalid" : ""
                                                }`}
                                            value={formValues.telephone || ''}
                                            onChange={this.handleChange} />
                                        <div className="invalid-feedback">{formErrors.telephone}</div>
                                    </div>
                                </div>
                                <div className="col-lg-6">
                                    <div className="form-group">
                                        <label> {t("address")}</label> <span className="required-star">*</span>
                                        <input
                                            type="text" name="address"
                                            className={`form-control form-control-lg ${
                                                formErrors.address ? "is-invalid" : ""
                                                }`}
                                            value={formValues.address || ''}
                                            onChange={this.handleChange}
                                        />
                                        <div className="invalid-feedback">{formErrors.address}</div>
                                    </div>
                                </div>

                            </div>

                        </div>
                        <div className="card-footer">
                            {isSubmitting ? (
                                <button type="button" className="btn btn-success loading" disabled>
                                    <i className="fas fa-sync-alt fa-spin"></i>
                                </button>
                            ) : (
                                    <span>
                                        <button type="submit" className="btn btn-success" disabled={isSubmitting}><i className="far fa-dot-circle"></i> {isSubmitting ? t('please_wait') : t('save')}</button>
                                        <button type="button" onClick={this.resetForm} className="btn btn-danger" ><i className="fa fa-times"></i> {t('clear')}</button>
                                        <button type="button" onClick={this.props.toggleCustomerClose} className="btn btn-secondary" ><i className="fa fa-times"></i> {t('close')}</button>
                                    </span>
                                )}

                        </div>
                    </form>
                </div>
            </div>

        )
    }
}

function mapStateToProps(globalState) {
    return {
        customers: globalState.customers.customer,
        error: globalState.customers.error,
        loading: globalState.customers.loading,
        message: globalState.customers.message,
    };
}

function mapDispatchToProps(dispatch) {
    return {
        createCustomer: (formValues) => dispatch(createCustomer(formValues)),
        updateCustomer: (formValues) => dispatch(updateCustomer(formValues)),
    };
}

export default withTranslation()(connect(mapStateToProps, mapDispatchToProps)(CustomerModal));
