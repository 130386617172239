//export const BASE_URL = 'http://localhost:4559/v1';
export const BASE_URL = 'https://dev.invoiceapi.crowderia.com/v1';
export const LOGOUT = 'logout';
export const DELETE_TOKEN = 'delete-token';
export const DATE_FORMAT = 'YYYY-MM-DD';
export const COMPANY_NAME = 'Ericssone&Lejonhird';
export const APP_VERSION = 'Copyright Crowderia © 2021 All rights reserved | v1.05.2 | 2021-05-30';



export const AUTH = {
	AUTH_LOAD: 'auth-load',
	AUTH_FAILED: 'auth-failed',
	AUTH_SUCCESS: 'auth-success',
	LOGOUT: 'logout',
};

export const SERVICE = {
	CREATE_SERVICE: 'create-service',
	UPDATE_SERVICE: 'update-service',
	DELETE_SERVICE: 'delete-service',
	UPDATE_SERVICE_FAILED: 'update-service-failed',
	UPDATE_SERVICE_SUCCESS: 'update-service-success',
	GET_SERVICES: 'get-services',
	GET_SERVICES_SUCCESS: 'get-services-success',
	GET_SERVICES_FAILED: 'get-services-failed',
};

export const CONSULTANT = {
	CREATE_CONSULTANT: 'create-consultant',
	UPDATE_CONSULTANT: 'update-consultant',
	DELETE_CONSULTANT: 'delete-consultant',
	UPDATE_CONSULTANT_FAILED: 'update-consultant-failed',
	UPDATE_CONSULTANT_SUCCESS: 'update-consultant-success',
	GET_CONSULTANTS: 'get-consultants',
	GET_CONSULTANTS_SUCCESS: 'get-consultants-success',
	GET_CONSULTANTS_FAILED: 'get-consultants-failed',
};

export const CUSTOMER = {
	CREATE_CUSTOMER: 'create-customer',
	UPDATE_CUSTOMER: 'update-customer',
	DELETE_CUSTOMER: 'delete-customer',
	UPDATE_CUSTOMER_FAILED: 'update-customer-failed',
	UPDATE_CUSTOMER_SUCCESS: 'update-customer-success',
	GET_CUSTOMERS: 'get-customers',
	GET_CUSTOMERS_SUCCESS: 'get-customers-success',
	GET_CUSTOMERS_FAILED: 'get-customers-failed',
	GET_CUSTOMER: 'get-customer',
	GET_CUSTOMER_SUCCESS: 'get-customer-success',
	GET_CUSTOMER_FAILED: 'get-customer-failed',
	GET_OUTSTANDING_TIMESHEETS_EXPENSES_SUMMERY: 'get_outstanding_timesheets_expenses_summery',
	GET_OUTSTANDING_TIMESHEETS_EXPENSES_SUMMERY_SUCCESS: 'get_outstanding_timesheets_expenses_summery-success',
	GET_OUTSTANDING_TIMESHEETS_EXPENSES_SUMMERY_FAILED: 'get_outstanding_timesheets_expenses_summery-failed',
};

export const INVOICE = {
	CREATE_INVOICE: 'create-invoice',
	UPDATE_INVOICE: 'update-invoice',
	DELETE_INVOICE: 'delete-invoice',
	UPDATE_INVOICE_FAILED: 'update-invoice-failed',
	UPDATE_INVOICE_SUCCESS: 'update-invoice-success',
	GET_INVOICES: 'get-invoices',
	GET_INVOICES_SUCCESS: 'get-invoices-success',
	GET_INVOICES_FAILED: 'get-invoices-failed',
	GET_INVOICE: 'get-invoice',
	GET_INVOICE_SUCCESS: 'get-invoice-success',
	GET_INVOICE_FAILED: 'get-invoice-failed',
	SEND_INVOICE: 'send-invoice',
	SEND_INVOICE_SUCCESS: 'send-invoice-success',
	SEND_INVOICE_FAILED: 'send-invoice-failed',
	GET_OUTSTANDING_SUMMERY: 'get-outstanding-summary',
	GET_OUTSTANDING_SUMMERY_SUCCESS: 'get-outstanding-summary-success',
	GET_OUTSTANDING_SUMMERY_FAILED: 'get-outstanding-summary-failed',
};

export const EXPENSE = {
	CREATE_EXPENSE: 'create-expense',
	UPDATE_EXPENSE: 'update-expense',
	DELETE_EXPENSE: 'delete-expense',
	UPDATE_EXPENSE_FAILED: 'update-expense-failed',
	UPDATE_EXPENSE_SUCCESS: 'update-expense-success',
	GET_EXPENSES: 'get-expenses',
	GET_EXPENSES_SUCCESS: 'get-expenses-success',
	GET_EXPENSES_FAILED: 'get-expenses-failed',
	GET_SUMMERIZE_EXPENSES: 'get-summerize-expenses',
	GET_SUMMERIZE_EXPENSES_SUCCESS: 'get-summerize-expenses-success',
	GET_SUMMERIZE_EXPENSES_FAILED: 'get-summerize-expenses-failed',
};

export const TIMESHEET = {
	CREATE_TIMESHEET: 'create-timesheet',
	UPDATE_TIMESHEET: 'update-timesheet',
	DELETE_TIMESHEET: 'delete-timesheet',
	UPDATE_TIMESHEET_FAILED: 'update-timesheet-failed',
	UPDATE_TIMESHEET_SUCCESS: 'update-timesheet-success',
	GET_TIMESHEETS: 'get-timesheets',
	GET_TIMESHEETS_SUCCESS: 'get-timesheets-success',
	GET_TIMESHEETS_FAILED: 'get-timesheets-failed',
	GET_SUMMERIZE_TIMESHEETS: 'get-summerize-timesheets',
	GET_SUMMERIZE_TIMESHEETS_SUCCESS : 'get-summerize-timesheets-success',
	GET_SUMMERIZE_TIMESHEETS_FAILED: 'get-summerize-timesheets-failed',

};

export const USERSERVICE = {
	CREATE_USERSERVICE: 'create-userservice',
	UPDATE_USERSERVICE: 'update-userservice',
	DELETE_USERSERVICE: 'delete-userservice',
	UPDATE_USERSERVICE_FAILED: 'update-userservice-failed',
	UPDATE_USERSERVICE_SUCCESS: 'update-userservice-success',
	GET_USERSERVICES: 'get-userservices',
	GET_USERSERVICES_SUCCESS: 'get-userservices-success',
	GET_USERSERVICES_FAILED: 'get-userservices-failed',
};

export const PASSWORD = {
	CREATE_PASSWORD: 'create-password',
	RESET_PASSWORD: 'rest-password',
	CREATE_PASSWORD_SUCCESS: 'create-password-success',
	CREATE_PASSWORD_FAILED: 'create-password-failed',
};
