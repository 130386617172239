import React, { Component } from 'react';
import ExpencesList from './ExpencesList';
import DatePicker from "react-datepicker";
import { withTranslation } from 'react-i18next';
import i18n from '../i18n';
import { connect } from "react-redux";
import { createExpense, updateExpense } from '../Actions/Expense';
import Alert from 'react-bootstrap/Alert';

class ExpencesModal extends Component {
    constructor(props) {
        super(props)
        this.state = {
            formValues: {
                expenseID: '',
                customerID: props.customerId,
                description: '',
                amount: '',
                expenseAt: new Date(),
            },
            formErrors: {
                description: '',
                amount: ''
            },
            formValidity: {
                description: '',
                amount: ''
            },
            isSubmitting: false,
            expence: props.expence,
            message: "",
            isError: false
        }
        this.handleChange = this.handleChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
        this.editExpense = this.editExpense.bind(this);
        // this.fetchReportedExpences = this.fetchReportedExpences.bind(this);
        this.onDateChange = this.onDateChange.bind(this);
        this.resetForm = this.resetForm.bind(this);

    }

    componentDidUpdate() {
        if (this.props.message || this.props.error) {
            if (this.props.expense?.expenseID > 0) {
                this.resetForm();
                this.setState({
                    isError: false,
                    message: this.props.message || this.props.error,
                });
                var that = this;
                setTimeout(function () {
                    that.setState({ isError: false, message: null });
                }, 3000);
            }
            if (this.props.error) {
                this.setState({
                    isError: true,
                    message: this.props.message || this.props.error,
                });
                var that = this;
                setTimeout(function () {
                    that.setState({ isError: false, message: null });
                }, 3000);
            }
        }
    }

    editExpense(expense) {
        if (expense) {
            const { formValidity } = this.state;
            var newformValues = {
                expenseID: expense.expenseID,
                customerID: expense.customerID,
                description: expense.description,
                amount: parseFloat(expense.amount),
                expenseAt: new Date(expense.expenseAt)
            }
            this.setState({ formValues: newformValues });

            for (let key in newformValues) {
                let target = {
                    name: key,
                    value: newformValues[key]
                };
                if (Object.keys(formValidity).includes(key)) {
                    this.handleValidation(target);
                }
            }
            window.scrollTo(0, 0);
        }
    }

    // fetchReportedExpences() {
    //     if (this.expensesChild && this.expensesChild.fetchReportedExpences) {
    //         this.expensesChild.fetchReportedExpences(this.state.formValues.customerID, "")
    //     }

    // }

    onDateChange(date) {
        const { formValues } = this.state;
        formValues.expenseAt = date;
        this.setState({
            formValues
        });
    };

    handleSubmit(event) {

        event.preventDefault();
        this.setState({ isSubmitting: true });
        const { formValues, formValidity } = this.state;

        if (Object.values(formValidity).every(Boolean)) {
            this.setState({ isSubmitting: false });
            if (formValues.expenseID > 0) {
                this.props.updateExpense(formValues);

            } else {
                this.props.createExpense(formValues);
            }
        } else {
            for (let key in formValues) {
                let target = {
                    name: key,
                    value: formValues[key]
                };
                this.handleValidation(target);
            }
            this.setState({ isSubmitting: false });
        }
    }

    handleChange(e) {
        const target = e.target;
        const value = target.value;
        const name = target.name;
        const { formValues } = this.state;
        formValues[name] = value;
        this.setState({ formValues });
        this.handleValidation(target);
    }

    handleValidation = target => {
        const { name, value } = target;
        const fieldValidationErrors = this.state.formErrors;
        const validity = this.state.formValidity;
        switch (name) {
            case "description":
                validity[name] = value.length > 0;
                fieldValidationErrors[name] = validity[name] ? "" : i18n.t("description_is_required");
                break;
            case "amount":
                validity[name] = value > 0;
                fieldValidationErrors[name] = validity[name] ? "" : i18n.t("amount_is_required");
                break;
            case "expenseAt":
                fieldValidationErrors[name] = validity[name] ? "" : "Date is required and cannot be empty";
                break;
            default:
                break;
        }

        const numberTest = /^(\d*\.)?\d+$/;
        if (validity[name]) {
            if (name === "amount") {
                validity[name] = numberTest.test(value);
                fieldValidationErrors[name] = validity[name]
                    ? ""
                    : `Value should be a valid number`;
            }
        }

        this.setState({
            formErrors: fieldValidationErrors,
            formValidity: validity
        });
    };
    resetForm() {
        this.setState({
            formValues: {
                expenseID: '',
                customerID: this.props.customerId,
                description: '',
                amount: '',
                expenseAt: new Date(),
            },
            formErrors: {
                description: '',
                amount: '',
            },
            formValidity: {
                description: '',
                amount: '',
            },
            isSubmitting: false
        });
    }

    render() {
        const { formValues, formErrors, isSubmitting, message, isError } = this.state;
        const { t } = this.props;

        return (
            <div className="modal-box" >
                <div className="card">
                    <form className="form-horizontal" onSubmit={this.handleSubmit}>
                        <div className="card-header">
                            <i className="fa fa-edit"></i> {t('add_or_edit_expence')}
                        </div>
                        <div className="card-body">

                            <div className="row">
                                <div className="col-lg-4">
                                    <div className="form-group">
                                        <label> {t('description')} </label> <span className="required-star">*</span>
                                        <input
                                            type="text" name="description"
                                            className={`form-control form-control-lg ${
                                                formErrors.description ? "is-invalid" : ""
                                                }`}
                                            value={formValues.description}
                                            onChange={this.handleChange} />
                                        <div className="invalid-feedback">{formErrors.description}</div>
                                    </div>
                                </div>
                                <div className="col-lg-4">
                                    <div className="form-group">
                                        <label>{t('date')}</label>
                                        <span className="required-star">*</span>
                                        <br></br>
                                        <DatePicker className="r-datapicker" name="expenseAt" dateFormat="yyyy/MM/dd" onChange={this.onDateChange}
                                            selected={formValues.expenseAt}></DatePicker>
                                        {/* <div className="invalid-feedback">{formErrors.expenseAt}</div> */}
                                    </div>
                                </div>
                                <div className="col-lg-4">
                                    <div className="form-group">
                                        <label>{t('amount')} </label><span className="required-star"> *</span>
                                        <input
                                            type="text" name="amount"
                                            className={`form-control form-control-lg ${
                                                formErrors.amount ? "is-invalid" : ""
                                                }`}
                                            value={formValues.amount}
                                            onChange={this.handleChange} />
                                        <div className="invalid-feedback">{formErrors.amount}</div>
                                    </div>
                                </div>
                            </div>

                        </div>
                        <div className="card-footer">
                            {isSubmitting ? (
                                <button type="button" className="btn btn-success loading" disabled>
                                    <i className="fas fa-sync-alt fa-spin"></i>
                                </button>
                            ) : (
                                    <span>
                                        <button type="submit" className="btn btn-success"><i className="far fa-dot-circle"></i>  {isSubmitting ? t('please_wait') : t('save')}</button>
                                        <button type="button" onClick={this.resetForm} className="btn btn-info" ><i className="fa fa-times"></i> {t('clear')}</button>
                                    </span>
                                )}
                        </div>
                    </form>
                </div>
                <div>
                    <h5>{t('previously_logged_expences')}</h5>
                    {message && (
                        <Alert
                            variant={isError ? 'danger' : 'success'}
                            dismissible
                            onClose={() => this.setState({ isError: false, message: null })}
                        >
                            {message}
                        </Alert>
                    )}

                    <ExpencesList customerId={this.props.customerId} childRef={ref => (this.expensesChild = ref)} editExpense={this.editExpense}></ExpencesList>
                </div>
            </div>
        )
    }
}

function mapStateToProps(globalState) {
    return {
        expenses: globalState.expenses.expenses,
        expense: globalState.expenses.data,
        error: globalState.expenses.error,
        loading: globalState.expenses.loading,
        message: globalState.expenses.message,
    };
}

function mapDispatchToProps(dispatch) {
    return {
        createExpense: (formValues) => dispatch(createExpense(formValues)),
        updateExpense: (formValues) => dispatch(updateExpense(formValues)),
        // getExpenses: (id) => dispatch(getExpenses(id)),
    };
}

export default withTranslation('translation', { withRef: true })(connect(mapStateToProps, mapDispatchToProps)(ExpencesModal));

