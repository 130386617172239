import React, { Component } from 'react';
import InvoicesHistory from '../Components/InvoicesHistory';
import TimeLogsToInoive from '../Components/TimeLogsToInvoice';
import Tabs from 'react-bootstrap/Tabs';
import Tab from 'react-bootstrap/Tab';
import ExpencesToInvoice from '../Components/ExpencesToInvoice';
import Collapsible from 'react-collapsible';
import SummaryModal from '../Components/SummaryModal';
import OverallSummary from '../Components/OverallSummary';
import InvoiceModal from '../Components/InvoiceModal';
import { connect } from "react-redux";
import { getInvoices, createInvoice, deleteInvoice, updateInvoice } from '../Actions/Invoice';
import { getCustomers } from '../Actions/Customer';
import { withTranslation } from 'react-i18next';
import Alert from 'react-bootstrap/Alert';
import i18n from '../i18n';

class Invoices extends Component {
    constructor(props) {
        super(props);
        this.state = {
            selectedCustomer: 0,
            selectedCustomerName: "",
            showModal: this.props.show,
            companies: props.companies,
            timeLogsList: [],
            expensesList: [],
            invoice: props.invoice,
            selectedTab: "newInvoice",
            message: null,
            isError: false

        };
        this.onChangeCustomer = this.onChangeCustomer.bind(this);
        this.open = this.open.bind(this);
        this.close = this.close.bind(this);
        this.fetchDataToInvoice = this.fetchDataToInvoice.bind(this);

    }

    onChangeCustomer(e) {
        const target = e.target;
        const value = target.value;
        this.selectedCustomer = value;
        this.setState({ selectedCustomer: value, selectedCustomerName: target[target.selectedIndex].label });
        if (this.state.selectedTab === "newInvoice") {
            this.invoicsTimeLogsChild.fetchTimeLogs(this.selectedCustomer);
            this.invoicsExpensesChild.fetchReportedExpences(this.selectedCustomer);
        }
        else if (this.state.selectedTab === "invoiceHistory") {
            // console.log("-------", this.selectedCustomer);
            //this.historyChild.fetchInvoices(this.selectedCustomer, "Invoicing");
        }
    }

    componentDidMount() {
        this.props.getInvoices();
        this.props.getCustomers();
        this.invoicsTimeLogsChild.fetchTimeLogs(this.selectedCustomer);
        this.invoicsExpensesChild.fetchReportedExpences(this.selectedCustomer);
    }

    componentDidUpdate(prevProps) {
        if (this.props.message || this.props.error) {
            if (this.props.message === i18n.t('successfuly_created')) {
                if (this.props.invoice?.invoiceID !== prevProps.invoice?.invoiceID) {
                    this.setState({
                        isError: !!this.props.error,
                        message: this.props.message || this.props.error,
                    });
                    this.invoicsTimeLogsChild.fetchTimeLogs(this.state.selectedCustomer);
                    this.invoicsExpensesChild.fetchReportedExpences(this.selectedCustomer);
                    if (this.props.invoice) {
                        this.setState({ showModal: true });
                    }
                    
                    
                }
            }
            var that = this;
            setTimeout(function () {
                that.setState({ isError: false, message: null });
            }, 3000);
        }
    }

    fetchDataToInvoice(key) {
        this.setState({ selectedTab: key });
    }

    open() {
        let selectedTimelogs = this.invoicsTimeLogsChild.getSelectedCheckboxes();
        let selectedExpenses = this.invoicsExpensesChild.getSelectedCheckboxes();
        if (selectedTimelogs.length > 0 || selectedExpenses.length > 0) {
            this.createInvoice(selectedTimelogs, selectedExpenses);
        }
        else {
            this.setState({ isError: true, message: i18n.t("please_select_timelogs_to_invoice") });
        }
    };

    createInvoiceLines(timeLogsList, expensesList) {
        let invoice_lines = [];
        if (expensesList && expensesList.length > 0) {
            expensesList.forEach(ex => {
                ex.objecttype = 'expense';
                ex.objectID = ex.expenseID;
                invoice_lines.push(ex);
            })
        }
        if (timeLogsList && timeLogsList.length > 0) {
            timeLogsList.forEach(ti => {
                ti.objecttype = 'timelog';
                ti.objectID = ti.timesheetID;
                ti.user = null;
                invoice_lines.push(ti);
            })
        }
        return invoice_lines;
    }

    createInvoice(selectedTimelogs, selectedExpenses) {
        let invoiceLines = this.createInvoiceLines(selectedTimelogs, selectedExpenses);
        const { selectedCustomer, selectedCustomerName } = this.state;
        if (selectedCustomer > 0 && invoiceLines && invoiceLines.length > 0) {
            let invoice = {
                customerID: selectedCustomer,
                customerName: selectedCustomerName,
                invoice_lines: invoiceLines,
                currency: "SEK"
            }
            this.props.createInvoice(invoice);
        }
    };

    close() {
        this.invoicsTimeLogsChild.fetchTimeLogs(this.state.selectedCustomer);
        this.invoicsExpensesChild.fetchReportedExpences(this.selectedCustomer);
        this.setState({ showModal: false });
    };

    renderCustomerDropdown() {
        const { t } = this.props;
        const listCustomers = this.props.customers ? this.props.customers.result : [];
        return (
            <>
                {/* <div className="col-md-3" style={{ marginLeft: 0 }}>
                    <p>{t('select_a_customer_to_view_invoice')}</p>
                </div> */}
                <div className="col-md-6">
                    <select onChange={this.onChangeCustomer} defaultValue={this.state.selectedCustomer} className="form-control" id="customerDropdown" title={t('select_a_customer')}>
                        <option value="0" >{t('select_a_customer')}</option>
                        {listCustomers.map((team) => <option key={team.customerID} value={team.customerID}>{team.name}</option>)}
                    </select>
                </div>
            </>
        );
    }

    render() {
        const { t } = this.props;
        const { message, isError, selectedCustomerName, selectedCustomer } = this.state;
        return (
            <div>
                <h1>{t('invoices')}</h1>
                <Tabs defaultActiveKey="newInvoice" id="uncontrolled-tab-example" onSelect={(k) => this.fetchDataToInvoice(k)}>
                    <Tab eventKey="newInvoice" title={t('new_invoice')}  >
                        <div className="container" style={{ marginLeft: -13 }}>
                            <br></br>
                            <div className="row">
                                {this.renderCustomerDropdown()}
                                <div className="col-md-5" style={{ textAlign: "right" }}>
                                    <span style={{ display: "inline-flex" }}>
                                        <SummaryModal customerId={selectedCustomer} customerName={selectedCustomerName} ></SummaryModal>
                                        <OverallSummary ></OverallSummary>
                                        <button onClick={this.open} disabled={selectedCustomer <= 0} className="btn btn-success" style={{ marginRight: 10 }}><i className="far fa-dot-circle"></i> {t('create_invoice')}</button>
                                        {this.state.showModal ?
                                            <InvoiceModal childRef3={ref => (this.childInvoiceModel = ref)} show={this.state.showModal} close={this.close} customerId={this.selectedCustomer} selectedInvoice={this.props.invoice}></InvoiceModal>
                                            : null
                                        }
                                    </span>
                                </div>
                            </div>
                            <br></br>
                            {message && (
                                <Alert
                                    variant={isError ? 'danger' : 'success'}
                                    dismissible
                                    onClose={() => this.setState({ isError: false, message: null })}
                                >
                                    {message}
                                </Alert>
                            )}
                            <Collapsible open={true} trigger={t('time_logs')} style={{ backgroundColor: "blue" }}>
                                <TimeLogsToInoive childRef3={ref => (this.invoicsTimeLogsChild = ref)} customerId={this.selectedCustomer} ></TimeLogsToInoive>
                            </Collapsible>
                            <Collapsible open={true} trigger={t('expences')}>
                                <ExpencesToInvoice childRef4={ref => (this.invoicsExpensesChild = ref)} customerId={this.selectedCustomer}></ExpencesToInvoice>
                            </Collapsible>
                            <br></br>
                        </div>
                    </Tab>
                    <Tab eventKey="invoiceHistory" title={t('invoice_history')}>
                        <br></br>
                        <div className="row">
                            {this.renderCustomerDropdown()}
                        </div>
                        <InvoicesHistory customerId={this.selectedCustomer} invoiceHistoryChild={ref => (this.historyChild = ref)}></InvoicesHistory>
                    </Tab>
                </Tabs>
            </div>
        );
    }
}


function mapStateToProps(globalState) {
    return {
        consultants: globalState.consultants.consultants,
        customers: globalState.customers.customers,
        error: globalState.invoices.error,
        loading: globalState.invoices.loading,
        message: globalState.invoices.message,
        invoice: globalState.invoices.data

    };
}

function mapDispatchToProps(dispatch) {
    return {
        createInvoice: (invoice) => dispatch(createInvoice(invoice)),
        updateInvoice: (name, email, id) => dispatch(updateInvoice({ name, email, id })),
        deleteInvoice: (id) => dispatch(deleteInvoice({ id })),
        getInvoices: () => dispatch(getInvoices()),
        getCustomers: () => dispatch(getCustomers()),
    };
}

export default withTranslation('translation', { withRef: true })(
    connect(mapStateToProps, mapDispatchToProps)(Invoices)
);

